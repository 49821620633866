import React, { useContext, useEffect, useState } from 'react'
import './registration.css'
import { LOGO } from '../../Utils'
import { handleRedirectInternal } from '../../product/common/components'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import UserContext from '../../product/context/user/userContext'
import AuthContext from '../../product/context/auth/authContext'

const InitialPopup = () => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)

    const { user, loadUser } = authContext
    const { updateProfile, responseStatus: responseStatusUser } = userContext
    const [redirectTo, setRedirectTo] = useState(1)
    //const [redirectToSearch, setRedirectToSearch] = useState(false)

    const history = useHistory()

    const browseProducts = (skipReg) => {
        let postData = {
            kyc_request_count: 1,
            reg_step_count: 3,
            reg_steps_skip: '1,2,3,4',
            is_document_later: 1, //user && user.user_type == 0 && user.domesticYN === 'domestic_account' ? 1 : 0,
            skipReg: skipReg,
        }

        setRedirectTo(skipReg)
        //setRedirectToSearch(true)
        updateProfile(postData, true)
    }

    useEffect(() => {
        loadUser()
    }, [])

    /* useEffect(() => {
        if (user && redirectToSearch) {
            setRedirectToSearch(false)
            handleRedirectInternal(history, 'search')
        }
    }, [user, redirectToSearch]) */

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                if (responseStatusUser.status === 'success') {
                    loadUser()

                    setTimeout(() => {
                        handleRedirectInternal(
                            history,
                            redirectTo === 1 || redirectTo == '1' ? 'account/profile' : 'search',
                        )
                    }, 1000)
                }
            }
        }
    }, [responseStatusUser])

    return (
        <div className="instrctWrpr">
            <div className="cntCntnr">
                <div className="lgWrpr">
                    <img src={LOGO} />
                </div>
                <div className="optnCntnr">
                    <Button className="optnBtn" onClick={() => browseProducts(1)}>
                        <img src="/assets/svg/complete_reg.svg" />
                        Complete Registration
                    </Button>
                    <Button className="optnBtn" onClick={() => browseProducts(2)}>
                        <img src="/assets/svg/browse_offering.svg" />
                        Browse Offerings
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default InitialPopup
