import React, { useContext, useEffect, useState } from 'react'
import { handleRedirectInternal, mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ZohoContext from '../../../../custom/context/zoho/zohoContext'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import publicIp from 'public-ip'
import CustomPopover from '../../atoms/Popover'
import CustomDialog from '../../organisms/Dialog'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from '@material-ui/core'

function NorthCapitalBankDetails(props) {
    const authContext = useContext(AuthContext)

    const { user } = authContext

    const {
        addExternalBank,
        deleteExternalAccount,
        updateExternalBank,
        responseStatus: responseStatusNorthCapitalBank,
    } = useContext(NorthCapitalContext)

    const { createTicket, responseStatus: responseStatusZoho } = useContext(ZohoContext)
    const history = useHistory()
    const [disabled, setDisabled] = useState(false)
    const [reload, setReload] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    const validationBank = Yup.object({
        ExtAccountfullname: Yup.string().required('Required!'),
        Extnickname: Yup.string().required('Required!'),
        ExtBankname: Yup.string().when('ExtAccountfullname', {
            is: () => props.data.status !== 'update',
            then: Yup.string().required('Required!'),
        }),
        ExtRoutingnumber: Yup.string().required('Required!'),
        ExtAccountnumber: Yup.string().required('Required!'),
        updatedIpAddress: Yup.string().required('Required!'),
    })

    const bankFormik = useFormik({
        initialValues: {
            types: 'Account',
            ExtAccountfullname: '',
            Extnickname: '',
            ExtBankname: '',
            ExtRoutingnumber: '',
            ExtAccountnumber: '',
            updatedIpAddress: '',
        },
        validationSchema: validationBank,
        onSubmit: (values) => {
            if (props.data.status === 'update') {
                let temp = values
                delete temp.ExtBankname
                updateExternalBank(temp)
            } else {
                addExternalBank(values)
            }
        },
    })

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                deleteExternalAccount()
            } else if (props.data.status === 'update') {
                bankFormik.values.ExtAccountfullname = props.data.data.AccountName
                bankFormik.values.Extnickname = props.data.data.AccountNickName
                bankFormik.values.ExtRoutingnumber = props.data.data.AccountRoutingNumber
                bankFormik.values.ExtAccountnumber = props.data.data.AccountNumber
                bankFormik.setFieldValue('ExtAccountnumber', props.data.data.AccountNumber)
                setReload(!reload)
            }
        }
    }, [props.data.data])

    const banDetails = {
        formik: bankFormik,
        data: [
            {
                label: 'Name',
                type: 'text',
                placeholder: 'Enter Name on Account',
                class: 'col-12',
                name: 'ExtAccountfullname',
                disabled: true,
                upperLabel: true,
                helperText: (
                    <CustomPopover
                        label={
                            <p className="m-0 d-flex align-items-center w-max ml-auto">
                                <Button onClick={() => setChangeStatus(true)}>
                                    Change the account holder name
                                </Button>
                                <span className="material-icons ml-1">help</span>
                            </p>
                        }
                        content={'Click to open a support ticket and we will be in touch'}
                    />
                ),
            },
            {
                label: 'Nickname For This Payment Account',
                type: 'text',
                placeholder: 'Enter Nickname',
                class: 'col-12',
                name: 'Extnickname',
                upperLabel: true,
            },
            {
                label: 'Bank Name',
                type: 'text',
                placeholder: 'Enter Bank Name',
                class: `${props.data.status === 'update' ? 'd-none' : ''} col-12`,
                name: 'ExtBankname',
                upperLabel: true,
            },
            {
                label: 'Routing Number',
                type: 'text',
                placeholder: 'Enter Routing Number',
                class: 'col-12',
                name: 'ExtRoutingnumber',
                upperLabel: true,
                helperText: (
                    <CustomPopover
                        label={
                            <p className="m-0 d-flex align-items-center w-max ml-auto">
                                Need help to find routing number
                                <span className="material-icons ml-1">help</span>
                            </p>
                        }
                        content={<img src="/assets/images/routinginfo.png" className="w-100" />}
                    />
                ),
            },
            {
                label: 'Account Number',
                type: 'text',
                placeholder: 'Enter Account Number',
                class: 'col-12',
                name: 'ExtAccountnumber',
                upperLabel: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusNorthCapitalBank) {
            if (
                responseStatusNorthCapitalBank.from === 'addExternalBank' ||
                responseStatusNorthCapitalBank.from === 'deleteExternalAccount' ||
                responseStatusNorthCapitalBank.from === 'updateExternalBank'
            ) {
                if (responseStatusNorthCapitalBank.status === 'success') {
                    props.function(false, 'new', null)
                }
            }
        }
    }, [responseStatusNorthCapitalBank])

    useEffect(async () => {
        let ip = await publicIp.v4()
        bankFormik.setFieldValue('updatedIpAddress', ip)

        bankFormik.values.Extnickname = ''
        bankFormik.values.ExtRoutingnumber = ''
        bankFormik.values.ExtAccountnumber = ''
        bankFormik.values.ExtBankname = ''
    }, [])

    useEffect(() => {
        if (user) {
            bankFormik.values.ExtAccountfullname =
                user.user_type === 0 ? user.first_name + ' ' + user.last_name : user.companyname
        }
    }, [user])

    const formikSelection = useFormik({
        initialValues: {
            data: '',
            status: '',
        },
        onSubmit: async (values) => {
            createTicket({
                subject: 'Account Name Change!',
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                phone: user.phone,
                description: `Need to change the account name for Manual ACH.`,
            })
            setChangeStatus(false)
        },
    })
    return (
        <>
            <Popup
                open={props.data.popup}
                size="sm"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Bank Details"
            >
                <div className="cardDetails">
                    <form onSubmit={bankFormik.handleSubmit} autoComplete="nofill">
                        <div className="row">{mapData(banDetails)}</div>
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    // disabled={disabled}
                                    btnSize="large"
                                    type="submit"
                                    id="savebank_submit"
                                    label={'Save'}
                                />
                            </div>
                        </div>
                    </form>

                    <CustomDialog
                        title={'Confirmation'}
                        open={changeStatus}
                        function={changeChangeStatus}
                    >
                        <h5>Are you sure you want to raise a ticket</h5>
                        <div className="actionWrapper">
                            <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                            <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                                <PrimaryButton type="submit" label="Confirm" disabled={disabled} />
                            </form>
                        </div>
                    </CustomDialog>
                </div>
            </Popup>
        </>
    )
}

export default NorthCapitalBankDetails
