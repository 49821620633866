import { Button } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
// import CustomInput from '../../atoms/Inputs/CustomInput'
// import PrimaryButton from '../../atoms/PrimaryButton'
import './ProductCard.css'
import { currencyFormat, numberFormatPositive } from '../../../common/components'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import { mapData } from '../../../../product/common/components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import debounce from 'lodash.debounce'

import CustomContext from '../../../context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const CartItem = (props) => {
    const [product, setProduct] = useState()
    const [reload, setReload] = useState(false)
    const { addToCart, addToCartTemp } = useContext(BuynowContext)
    const authContext = useContext(AuthContext)
    const { user, loadUser, isAuthenticated } = authContext
    const [transaction_type, setTransactionType] = useState('')
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        setTransactionType(props.transaction_type)
    }, [props.transaction_type])

    const validationArray = Yup.object({
        quantity: Yup.number()
            .integer('Whole numbers only')
            .min(1, 'Min quantity')
            .max(
                product
                    ? product.isPublic === 0
                        ? product.ttwQty - product.ttwSold
                        : numberFormatPositive(
                              product.projects_qty -
                                  product.projects_sold -
                                  product.projects_booked +
                                  product.qty,
                          )
                    : 0,
                `Max quantity ${
                    product
                        ? product.isPublic === 0
                            ? product.ttwQty - product.ttwSold
                            : numberFormatPositive(
                                  product.projects_qty -
                                      product.projects_sold -
                                      product.projects_booked +
                                      product.qty,
                              )
                        : 0
                }`,
            )
            .required('Enter quantity'),
    })

    const formik = useFormik({
        initialValues: {
            quantity: 0,
        },
        validationSchema: validationArray,
        onSubmit: debounce((values) => {
            if (isAuthenticated) {
                addToCart({
                    cart: [
                        {
                            product_id: product.project_id,
                            qty: values.quantity,
                        },
                    ],
                })
            } else {
                addToCartTemp({
                    temp_user_id: localStorage.temp_user_id,
                    cart: [
                        {
                            product_id: product.project_id,
                            qty: values.quantity,
                        },
                    ],
                })
            }
        }, 1000),
    })

    useEffect(() => {
        if (product) {
            formik.values.quantity = product ? product.qty : 0
            setReload(!reload)
        }
    }, [product])

    // useEffect(() => {
    //     if (product) {
    //         if (formik.values.quantity !== product.qty) {
    //             formik.submitForm()
    //         }
    //     }
    // }, [formik.values.quantity])

    const formikQuantity = [
        {
            label: '',
            name: 'quantity',
            type: 'number',
            placeholder: 'Qty',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    const { allConfigurationVariables } = useContext(CustomContext)

    return (
        <>
            {product ? (
                <>
                    <div className="cartItem d-flex justify-content-between align-items-start">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: '100%',
                            }}
                        >
                            <div className="itemInfo d-flex justify-content-start">
                                <div className="pclImg">
                                    <img
                                        src={
                                            global.site_url +
                                            '/uploads/product/' +
                                            product.projects_avatar
                                        }
                                        alt={product.title}
                                    />
                                </div>
                                <div className="listContent">
                                    <h2 className="listProdTitle">{product.projects_title}</h2>
                                    <div className="cartInfo">
                                        <h6 className="totalShares">
                                            {transaction_type == 'Refund' ? (
                                                <>
                                                    {product.qty_tmp}{' '}
                                                    {product.qty_tmp === 1 ? 'Unit' : 'Units'} /{' '}
                                                </>
                                            ) : (
                                                <>
                                                    {product.qty ? (
                                                        <>
                                                            {product.qty}{' '}
                                                            {product.qty === 1 ? 'Unit' : 'Units'}{' '}
                                                        </>
                                                    ) : product.qty_tmp ? (
                                                        <>
                                                            {product.qty_tmp}{' '}
                                                            {product.qty_tmp === 1
                                                                ? 'Unit'
                                                                : 'Units'}{' '}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    /{' '}
                                                </>
                                            )}
                                            {currencyFormat(product.amount)} Per Unit
                                        </h6>
                                        {props.from == 'buynowcart' ||
                                        props.from == 'buynowcheckout' ||
                                        (props.from == 'invoice' &&
                                            product.buynow_autype === 'buynow') ? (
                                            <>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center">
                                                    <h5>
                                                        <span>Sub total:</span>
                                                        <span>
                                                            {transaction_type == 'Refund'
                                                                ? '-'
                                                                : ''}
                                                            {currencyFormat(product.per_total)}
                                                        </span>
                                                    </h5>
                                                </div>
                                                {/* {!isAuthenticated &&
                                                props.from === 'buynowcheckout' ? (
                                                    <Accordion className="cartAccordion">
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Description</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div
                                                                className="ck-content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        product.projects_desc_proc,
                                                                }}
                                                            ></div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null} */}

                                                {props.from == 'invoice' ? (
                                                    <>
                                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                                            <h5>
                                                                <h5>
                                                                    {product.admin_fee ? (
                                                                        <div>
                                                                            {product.payment_method ==
                                                                            'CREDITCARD'
                                                                                ? `Fee($
                                                        ${allConfigurationVariables.cc_convenient_fee}%+$
                                                        ${allConfigurationVariables.additional_cc_fee}
                                                        ):`
                                                                                : product.payment_method ==
                                                                                  'ACH'
                                                                                ? `Fee($
                                                                ${allConfigurationVariables.bank_convenient_fee}%):`
                                                                                : product.payment_method ==
                                                                                  'TRANSFER'
                                                                                ? `Fee($
                                                        ${allConfigurationVariables.cc_convenient_fee}%+$
                                                        ${allConfigurationVariables.additional_cc_fee}
                                                        ):`
                                                                                : ''}
                                                                        </div>
                                                                    ) : (
                                                                        <span> Fee:</span>
                                                                    )}
                                                                    <span>
                                                                        {currencyFormat(
                                                                            product.admin_fee
                                                                                ? product.admin_fee
                                                                                : product.per_total *
                                                                                      (product.admin_fee_percent /
                                                                                          100),
                                                                        )}
                                                                    </span>
                                                                </h5>
                                                            </h5>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : props.from == 'cart' ||
                                          props.from == 'checkout' ||
                                          (props.from == 'invoice' &&
                                              product.buynow_autype === 'live') ? (
                                            <>
                                                <div className="listLotInfo d-flex justify-content-start align-items-center">
                                                    <h5>
                                                        <span>Amount to pay</span>
                                                        <span>
                                                            {currencyFormat(product.per_total)}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {props.cartLength > 1 &&
                                (!isAuthenticated && props.from === 'buynowcheckout' ? (
                                    <Accordion className="cartAccordion">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Description</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div
                                                className="ck-content"
                                                dangerouslySetInnerHTML={{
                                                    __html: product.projects_desc_proc,
                                                }}
                                            ></div>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : null)}
                        </div>

                        <div className="listActBtn">
                            {/* <div className="listBidInfo d-flex justify-content-between align-items-center">
                                <p className="d-flex justify-content-center align-items-center">
                                    <span>{currencyFormat(product.buynowamount)}</span>
                                    {props.from == 'cart' ? (
                                        <>
                                            <span className="listDivider">|</span>
                                            <span>Unpaid</span>
                                        </>
                                    ) : null}
                                </p>
                            </div> */}
                            {props.from == 'buynowcart' ? (
                                <>
                                    <Button
                                        className="removeCart"
                                        onClick={() =>
                                            props.changeOnCart({
                                                cart_id: product.cart_id,
                                                qty: product.qty,
                                                product_id: product.projects_id,
                                            })
                                        }
                                    >
                                        {'Remove from cart'}
                                    </Button>
                                    <div className="updateCartQuantity">
                                        {/* <Button
                                            disabled={formik.values.quantity <= 1}
                                            onClick={() => {
                                                formik.setFieldValue(
                                                    'quantity',
                                                    parseInt(formik.values.quantity) - 1,
                                                )
                                            }}
                                        >
                                            <span className="material-icons">remove_circle</span>
                                        </Button> */}
                                        {Object.values(mapData(formikQuantity))}
                                        <Button onClick={formik.handleSubmit}>
                                            <span className="material-icons">done</span>
                                        </Button>
                                        {/* <Button
                                            onClick={() => {
                                                formik.setFieldValue(
                                                    'quantity',
                                                    parseInt(formik.values.quantity) + 1,
                                                )
                                            }}
                                        >
                                            <span className="material-icons">add_circle</span>
                                        </Button> */}
                                    </div>
                                </>
                            ) : props.from == 'cart' ? (
                                <>
                                    {product.in_cart === 0 ? (
                                        <Button
                                            className="removeCart"
                                            onClick={() =>
                                                props.changeOnCart({
                                                    cart: 1,
                                                    id: product.projects_id,
                                                })
                                            }
                                        >
                                            {'Add to cart'}
                                        </Button>
                                    ) : // <Button
                                    //     className="removeCart"
                                    //     onClick={() =>
                                    //         props.changeOnCart({
                                    //             cart: 0,
                                    //             id: product.projects_id,
                                    //         })
                                    //     }
                                    // >
                                    //     Remove from cart
                                    // </Button>
                                    null}
                                </>
                            ) : null}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}
export default CartItem
