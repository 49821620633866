import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LOGO_BLACK } from '../../../../Utils'
import { handleRedirectInternal, mapData } from '../../../../product/common/components'
import { Link, useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomCommonContext from './../../../context/common/commonContext'

const Footer = (props) => {
    const autopltContext = useContext(AutopltContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { allFooter } = useContext(CustomCommonContext)
    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext

    const { static_page, getStaticPage, footer_links, getFooterLinks } = productCommonContext
    const history = useHistory()

    let [footerLink1, setFooterLink1] = useState(null)
    let [footerLink2, setFooterLink2] = useState(null)
    let [footerLinks, setFooterLinks] = useState({})

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required!'),
    })

    useEffect(() => {
        getStaticPage({ id: 'footer_link_1' }, 'footer_link_1')
        getStaticPage({ id: 'footer_link_2' }, 'footer_link_2')
        // getFooterLinks({}, 'footer_links')
    }, [])
    useEffect(() => {
        if (allFooter && allFooter.length > 0) {
            let all = {}
            allFooter.map((data) => {
                all[data.name] = data.link
            })
            setFooterLinks(all)
        }
    }, [allFooter])
    useEffect(() => {
        if (static_page) {
            if (static_page.from === 'footer_link_1') {
                setFooterLink1(static_page?.record?.link ? static_page.record.link : null)
            } else if (static_page.from === 'footer_link_2') {
                setFooterLink2(static_page?.record?.link ? static_page.record.link : null)
            }
        }
    }, [static_page])

    useEffect(() => {
        if (footer_links) {
            if (footer_links.record) {
                const f_links = {}
                footer_links?.record?.map((f) => {
                    f_links[f.name] = f.link
                })
                setFooterLinks(f_links)
            }
        }
    }, [footer_links])

    const mobileQuery = useMediaQuery('(max-width:1000px)')

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            addCustomer(values)
        },
    })

    const subscribe = [
        {
            label: 'Subscribe to our mailing list',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <>
            <div className={`mainFooter`}>
                <div className="ftrinrContainer">
                    <div
                        className={`topContainer  d-flex align-items-end justify-content-between ${
                            mobileQuery ? 'customContainer' : ''
                        }`}
                    >
                        <div className="logoContainer">
                            <a href="https://www.songvest.com/" id="footer_songvest_logo">
                                <img className="footerLogo" src="/assets/images/footer_logo.png" />
                            </a>
                            <div className="cprtSection">
                                © {new Date().getFullYear()} by RoyaltyTraders LLC dba SongVest
                            </div>
                        </div>
                        <div className="cntrFtrContent">
                            <ul>
                                <li>
                                    <a
                                        href={footerLinks['music_creators'] || '/'}
                                        target="blank"
                                        id="footer_music_creators"
                                    >
                                        Music Creators/Sell Royalties
                                    </a>
                                </li>
                                <li>-</li>
                                <li>
                                    <a
                                        href={footerLinks['current_auctions'] || '/'}
                                        id="footer_current_auctions"
                                    >
                                        Current Auctions
                                    </a>
                                </li>
                                <li>-</li>
                                <li>
                                    <a
                                        href={footerLinks['about_us'] || '/'}
                                        target="blank"
                                        id="footer_about_us"
                                    >
                                        About Us
                                    </a>
                                </li>
                                <li>-</li>
                                <li>
                                    <a
                                        href={footerLinks['news'] || '/'}
                                        target="blank"
                                        id="footer_news"
                                    >
                                        News
                                    </a>
                                </li>
                                <li>-</li>
                                <li>
                                    <a
                                        href={footerLinks['faqs'] || '/'}
                                        target="blank"
                                        id="footer_faqs"
                                    >
                                        FAQs
                                    </a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a
                                        href={footerLinks['terms_of_use'] || '/'}
                                        id="footer_terms_of_use"
                                    >
                                        Terms of Use
                                    </a>
                                </li>
                                <li>-</li>
                                <li>
                                    <a
                                        href={footerLinks['privacy_policy'] || '/'}
                                        id="footer_privacy_policy"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>-</li>
                                <li>
                                    <a
                                        href={footerLinks['offering_circular'] || '/'}
                                        id="footer_offering_circular"
                                    >
                                        Offering Circular
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="lstContainer">
                            <div className="sclMdLinks text-center">
                                <a
                                    href={footerLinks['instagram'] || '/'}
                                    target="blank"
                                    id="instagram_button"
                                >
                                    <img src="/assets/images/insta.png" />
                                </a>
                                <a
                                    href={footerLinks['facebook'] || '/'}
                                    target="blank"
                                    id="facebook_button"
                                >
                                    <img src="/assets/images/facebook.png" />
                                </a>
                                <a
                                    href={footerLinks['twitter'] || '/'}
                                    target="blank"
                                    id="twitter_button"
                                >
                                    <img src="/assets/images/twitter.png" />
                                </a>
                                <a
                                    href={footerLinks['linkedin'] || '/'}
                                    target="blank"
                                    id="linkedin_button"
                                >
                                    <img src="/assets/images/linkedin.png" />
                                </a>
                            </div>
                            {/* <div>
                                <a href="tel:9192765454">919-276-5454</a>
                            </div> */}
                        </div>
                    </div>
                    <div
                        className={`bottomContainer dscmlr mobileQuery ${
                            mobileQuery ? 'customContainer' : ''
                        }`}
                    >
                        {/* <h6>DISCLAIMERS:</h6> */}
                        {/* <p>
                            All securities-related activity is conducted through Dalmore Group, LLC
                            (&quot;Dalmore&quot;), a registered broker-dealer and member FINRA/SIPC.
                            Dalmore does not make investment recommendations and acts only as the
                            broker/dealer of record for the offering listed. You can review the
                            background of our broker-dealer and our investment professionals on
                            FINRA’s <br /> BrokerCheck
                            <a
                                href="https://brokercheck.finra.org/firm/summary/136352"
                                target="blank"
                            >
                                https://brokercheck.finra.org/firm/summary/136352
                            </a>
                        </p>
                        <p>
                            RoyaltyTraders, LLC dba SongVest is not a financial services company and
                            that any financial assessments provided are informal in nature and for
                            informational purposes only. Site visitors should consult with their own
                            qualified financial and/or tax professionals.
                        </p>
                        <p className="uprCsCntnr smlSizeTxt">
                            ROYALTYTRADERS LLC (DBA SONGVEST) IS TESTING THE WATERS UNDER TIER 2 OF
                            REGULATION A. NO MONEY OR OTHER CONSIDERATION IS BEING SOLICITED, AND IF
                            SENT IN RESPONSE, WILL NOT BE ACCEPTED. NO OFFER TO BUY THE SECURITIES
                            CAN BE ACCEPTED AND NO PART OF THE PURCHASE PRICE CAN BE RECEIVED UNTIL
                            THE OFFERING STATEMENT FILED BY THE COMPANY WITH THE SEC HAS BEEN
                            QUALIFIED BY THE SEC. ANY SUCH OFFER MAY BE WITHDRAWN OR REVOKED,
                            WITHOUT OBLIGATION OR COMMITMENT OF ANY KIND, AT ANY TIME BEFORE NOTICE
                            OF ACCEPTANCE GIVEN AFTER THE DATE OF QUALIFICATION. AN INDICATION OF
                            INTEREST INVOLVES NO OBLIGATION OR COMMITMENT OF ANY KIND. AN OFFERING
                            STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH THE SEC.
                        </p> */}
                        <p>
                            This offering is made in reliance on Regulation A under the Securities
                            Act of 1933. The securities offered are speculative, illiquid, and an
                            investor could lose the entire investment. Investors should read the
                            relevant Offering Circular and consider the risks disclosed therein
                            before investing. SongVest is a startup and is operating at a loss.
                        </p>
                        <p className="uprCsCntnr smlSizeTxt">
                            IN MAKING AN INVESTMENT DECISION, INVESTORS MUST RELY ON THEIR OWN
                            EXAMINATION OF THE ISSUER AND THE TERMS OF THE OFFERING, INCLUDING THE
                            MERITS AND RISKS INVOLVED. REGULATION A OFFERINGS ARE SPECULATIVE,
                            ILLIQUID, AND INVOLVE A HIGH DEGREE OF RISK, INCLUDING THE POSSIBLE LOSS
                            OF YOUR ENTIRE INVESTMENT.
                        </p>
                        <p>
                            SongVest is offering securities through the use of an Offering Statement
                            that the Securities and Exchange Commission (&quot;SEC&quot;) has
                            qualified under Tier II of Regulation A. While the SEC staff reviews
                            certain forms and filings for compliance with disclosure obligations,
                            the SEC does not evaluate the merits of any offering, nor does it
                            determine if any securities offered are “good” investments. 
                        </p>
                        <p>
                            Dalmore Group LLC (“Dalmore”) is registered with the Securities and
                            Exchange Commission (“SEC”) as a broker-dealer, member of Financial
                            Industry Regulatory Authority, Inc. (“FINRA”) and Securities Investor
                            Protection Corporation (“SIPC”) and is the broker-dealer of record on
                            this issuer direct offering.
                        </p>
                        <p>
                            This profile may contain forward-looking statements and information
                            relating to, among other things, the company, its business plan and
                            strategy, and its markets or industry. These statements reflect
                            management’s current views regarding future events based on available
                            information and are subject to risks and uncertainties that could cause
                            the company’s actual results to differ materially. Investors are
                            cautioned not to place undue reliance on these forward-looking
                            statements as they are meant for illustrative purposes, and they do not
                            represent guarantees of future results, levels of activity, performance,
                            or achievements, all of which cannot be made. Moreover, although
                            management believes that the expectations reflected in the
                            forward-looking statements are reasonable, neither RoyaltyTraders LLC
                            dba SongVest nor anyone acting on its behalf can give any assurance that
                            such expectations will prove to have been correct nor do they have a
                            duty to update any such statements to conform them to actual results. By
                            accessing this site and any pages on this site, you agree to be bound by
                            our <a href={footerLinks['terms_of_use'] || '/'}>Terms of Use</a> and{' '}
                            <a href={footerLinks['privacy_policy'] || '/'}>Privacy Policy</a>, as
                            may be amended from time to time without notice or liability.
                        </p>
                        <p className="smlSizeTxt">
                            Read the Offering Circular before you invest.
                            <a
                                href="https://www.songvest.com/offering"
                                target="blank"
                                style={{ textDecoration: 'none' }}
                            >
                                https://www.songvest.com/offering
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
