import React from 'react'
import { Helmet } from 'react-helmet-async'

const metaTags = (props) => {
    const { title, avatar, siteName, id, description } = props
    return (
        <Helmet>
            <>
                <title>{`${siteName} | ${title}`}</title>
                {description && <meta name="description" content={description} />}

                <meta itemProp="name" content={`${siteName} | ${title}`} />
                {description && <meta itemProp="description" content={description} />}
                <meta itemProp="image" content={global.images_url + avatar} />

                {/* <meta name="twitter:card" content="summary_large_image" /> */}
                <meta name="twitter:site" content={siteName} />
                <meta name="twitter:title" content={title} />
                {description && <meta name="twitter:description" content={description} />}
                {/* <meta name="twitter:creator" content="@author_handle" /> */}
                {/* <!-- Twitter summary card with large image must be at least 280x150px --> */}
                <meta name="twitter:image:src" content={global.images_url + avatar} />

                {/* <!-- Open Graph data --> */}
                <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`${global.site_url}${id}/${title}`} />
                <meta property="og:image" content={global.images_url + avatar} />
                {description && <meta property="og:description" content={description} />}
                <meta property="og:site_name" content={siteName} />
                {/* <meta property="article:published_time" content="2013-09-17T05:59:00+01:00" /> */}
                {/* <meta property="article:modified_time" content="2013-09-16T19:08:47+01:00" /> */}
                {/* <meta property="article:section" content="Article Section" /> */}
                {/* <meta property="article:tag" content="Article Tag" /> */}
                {/* <meta property="fb:admins" content="Facebook numberic ID" /> */}
            </>
        </Helmet>
    )
}

export default metaTags
