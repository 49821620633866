import React from 'react'
import { Link } from 'react-router-dom'
import { LOGO, LOGO_WHITE } from '../../../../Utils'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import './RegistrationLayout.css'
const RegistrationLayout = (props) => {
    const location = useLocation()
    return (
        <div className="logRegLayout">
            {!props?.moreStep && (
                <div className="leftImgContainer">
                    <div className="logoContainer">
                        {/* <img className="whtBackDrop" src="/assets/svg/whitevector.svg" /> */}
                        {location.pathname == '/register' ||
                        location.pathname == '/registration' ? (
                            localStorage.ttw_temp_user_id ? (
                                <a onClick={() => props.cancelTemp()}>
                                    <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                                </a>
                            ) : (
                                <a href={`${process.env.REACT_APP_FRONTEND_URL}`}>
                                    <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                                </a>
                            )
                        ) : location.pathname == '/login' ? (
                            <a href={`${process.env.REACT_APP_FRONTEND_URL}`}>
                                <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                            </a>
                        ) : (
                            <a href="https://songvest.com">
                                <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                            </a>
                        )}
                    </div>
                    {/* <img className="vrtclogo" src="/assets/images/vertical_logos.png" /> */}
                    <div className="logRegisterContentCnt">
                        {props?.activeStep === 0 && (
                            <div>
                                <h2> What is SongVest</h2>
                                <p>
                                    SongVest is the first music marketplace to offer fans and
                                    investors the ability to purchase SongShares® from their
                                    favorite songs and artists. SongShares® are the only Regulation
                                    A+ SEC qualified offering that allows fractional ownership of
                                    music royalties, opening the alternative asset class to the
                                    general public.
                                </p>
                            </div>
                        )}

                        <div>
                            <h2> Registering with SongVest</h2>
                            <p>
                                In order to register and be eligible to purchase SongShares, we are
                                required by U.S. regulations and the SEC to collect certain personal
                                information to ensure compliance with identity verification.
                            </p>
                        </div>
                        {!(props?.activeStep === 0) && (
                            <div>
                                <h2>Why We Need Your Personal Information?</h2>
                                <p>
                                    <strong>User Type:</strong>
                                    &nbsp;Will enable us to collect the information required for
                                    your specific user type.
                                </p>
                                <p>
                                    <strong>Domicile:</strong>
                                    &nbsp;To comply with required laws around purchase, which may
                                    vary by jurisdiction.
                                </p>
                                <p>
                                    <strong>Date of Birth:</strong>
                                    &nbsp;To verify you are eligible to invest.
                                </p>
                                <p>
                                    <strong>Address:</strong>
                                    &nbsp;To help verify your identity and eligibility to invest.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {console.log(props, 'props1234')}
            <div className="rtDtlContainer w-100">{props.children}</div>
        </div>
    )
}

export default RegistrationLayout
