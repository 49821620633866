import React, { useContext, useEffect, useState } from 'react'
import {
    currencyFormat,
    currencyFormatInt,
    handleRedirectInternal,
} from '../../../common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import authContext from '../../../../product/context/auth/authContext'
import TertiaryButton from '../../atoms/TertiaryButton'
import CustomDialog from '../../organisms/Dialog'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import { mapData } from '../../../../product/common/components'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import KlaviyoContext from '../../../../custom/context/klaviyo/klaviyoContext'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import PayContext from '../../../../product/context/payment/payContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import Loaders from '../Loaders'

const BuynowFree = (props) => {
    const { isAuthenticated, user } = useContext(authContext)
    const {
        addToCart,
        responseStatus,
        getSingleCartProducts,
        search_singlecart,
        clearResponse,
    } = useContext(BuynowContext)
    const [product, setProduct] = useState()
    const { northCapitalUser } = useContext(NorthCapitalContext)
    const { setAlert } = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const [cartValues, setCartValues] = useState('')
    const payContext = useContext(PayContext)
    const [loading, setLoading] = useState(false)
    const { updateOffers, freeOffers } = customCommonContext

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const [canadianUser, setCanadianUser] = useState(false)

    const { updateProfile } = useContext(KlaviyoContext)

    const {
        payCartNorthCapital,
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (user && user.country == 'CA') {
            setCanadianUser(true)
        }
    }, [user])

    const validationArray = Yup.object({
        product_id: Yup.number().required('Product id required'),
        qty: Yup.number()
            .min(1, `${'Min quantity'} `)
            .max(
                product ? product.pendingQuantity : 0,
                `${'Max quantity'} ${product ? product.pendingQuantity : 0}`,
            )
            .required('Enter quantity'),
    })

    const formik = useFormik({
        initialValues: { product_id: props.data.id, qty: '' },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (!user.mail_verified) {
                setAlert('Please verify your email', 'error')
                handleRedirectInternal(history, 'account/profile')
            } else if (
                northCapitalUser &&
                ((user.domesticYN !== 'domestic_account' && user.user_type == 0) ||
                    user.user_type == 1)
            ) {
                if (northCapitalUser.isDocumentUploaded !== 1) {
                    setAlert(
                        'Your account has not been verified. Please visit your Settings to upload a passport or photo ID before completing this purchase.',
                        'error',
                    )
                    // handleRedirectInternal(history, 'registration/DocumentUpdate/1')
                    return false
                }
            }
            if (toggleDialog) {
                let cart = []
                if (product.auctiontype === 'buynow' && product.isPublicVisible !== true) {
                    values.isNoTimer = true
                }
                cart.push(values)
                setLoading(true)
                addToCart({ cart })
                setToggleDialog(false)
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    const cartProduct = responseStatus.data[0]
                    setCartValues(cartProduct)
                }
            }
            return () => {
                clearResponse()
            }
        }
    }, [responseStatus])

    useEffect(() => {
        console.log('cartValues', cartValues)
        if (Object.keys(cartValues).length > 0) {
            let searchValue = {
                cart_id: cartValues?.cart_group,
            }
            if (formik.values.qty != '') {
                getSingleCartProducts(searchValue)
            }
        }
    }, [cartValues])

    useEffect(() => {
        console.log('cartValues search_singlecart', search_singlecart)
        if (Object.keys(search_singlecart).length > 0) {
            let checkoutValue = {
                invoice_id: 0,
                payment_object: {
                    id: '',
                    appointmentTime: '',
                    invoice_id: 0,
                },
                paymentInfo: {},
                payment_method: 'ACH',
                pay_type: 'buynow',
                amount: search_singlecart?.cartvalues?.per_total,
                admin_fee_percent: search_singlecart?.cartvalues?.processing_percent || 0,
                admin_fee:
                    search_singlecart?.cartvalues?.per_total *
                        (search_singlecart?.cartvalues?.processing_percent / 100) || 0,
                cart_id: cartValues?.cart_group,
                cart_group: search_singlecart?.cartrecords[0]?.cart_id,
                isFreeShare: 1,
            }
            if (
                checkoutValue.cart_group != undefined &&
                checkoutValue.cart_group != null &&
                formik.values.qty != ''
            ) {
                console.log('cartValues checkoutValue', checkoutValue)
                payCartNorthCapital(checkoutValue)
            }
            return () => {
                clearResponse()
            }
        }
    }, [search_singlecart])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    updateOffers({
                        id: product?.freeShare || 0,
                        quantity: formik.values.qty || 0,
                    })
                    setTimeout(() => {
                        handleRedirectInternal(history, 'dashboard')
                    }, 1000)
                } else {
                    if (responseStatusPayment.message.includes('Payment Failed.')) {
                        handleRedirectInternal(history, 'invoices')
                    }
                }
                setLoading(false)
            }
            return () => {
                clearResponsePayment()
            }
        }
    }, [responseStatusPayment])

    const buynowQuantity = [
        {
            name: 'qty',
            type: 'number',
            placeholder: `Enter unit qty`,
            class: '',
            autoFocus: false,
            formik: formik,
        },
    ]

    return (
        <>
            {console.log('product', product)}
            {product ? (
                <>
                    {loading && <Loaders name="name" isLoading={loading} />}
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt buyNnwContainer beforeLogin d-flex justify-content-start align-items-start">
                                {props.editQuantity && Object.values(mapData(buynowQuantity))}

                                {isAuthenticated ? (
                                    <PrimaryButton
                                        label={'Claim'}
                                        type="submit"
                                        btnSize="small"
                                        disabled={
                                            (product &&
                                                !product.isPublicVisible &&
                                                !product.isPrivateVisible) ||
                                            canadianUser ||
                                            false
                                        }
                                    />
                                ) : (
                                    <PrimaryButton
                                        label="Login to claim"
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                )}
                            </div>
                            {/* {formik.values.qty && props.freeShare !== true ? (
                                <div className="ryltsDtlsWrpr">
                                    <div className="rylInrWrpr d-flex align-items-center">
                                        <span>
                                            {currencyFormatInt(formik.values.qty * product.bprice)}
                                        </span>
                                        <p>Total Price</p>
                                    </div>
                                    <div className="rylInrWrpr d-flex align-items-center">
                                        <span>
                                            {currencyFormat(
                                                formik.values.qty *
                                                    parseFloat(
                                                        product.annual_royalties / product.qty,
                                                    ).toFixed(2),
                                            )}
                                        </span>
                                        <p>Total Royalties Paid (Last 12 Months)</p>
                                    </div>
                                </div>
                            ) : null} */}
                        </form>
                    ) : null}
                </>
            ) : null}
            <CustomDialog title="Confirm buy now" open={toggleDialog} function={changeDialogStatus}>
                <h5>You are about to buy this product</h5>
                <h5>Please confirm if you want to continue with this action</h5>
                <div className="actionWrapper">
                    <Button onClick={() => setToggleDialog(false)}>Cancel</Button>
                    <PrimaryButton onClick={formik.handleSubmit} type="submit" label="Submit" />
                </div>
            </CustomDialog>
        </>
    )
}

export default BuynowFree
