import React, { useReducer } from 'react'
import KlaviyoContext from './klaviyoContext'
import KlaviyoReducer from './klaviyoReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { CLEAR_RESPONSE, RESPONSE_STATUS } from './klaviyoTypes'

const KlaviyoState = (props) => {
    const initialState = {
        responseStatus: null,
        klaviyoLists: null,
    }

    const [state, dispatch] = useReducer(KlaviyoReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const subscribeProfileToListAfterRegister = async (formData, noAlert = false) => {
        const from = 'subscribeProfileToListAfterRegister'
        let payload = {}

        console.log('sdfdsfsddsf', formData)

        if (formData.first_name !== undefined && formData.first_name !== null) {
            payload.first_name = payload['First Name'] = formData.first_name
        }

        if (formData.last_name !== undefined && formData.last_name !== null) {
            payload.last_name = payload['Last Name'] = formData.last_name
        }

        if (formData.email !== undefined && formData.email !== null) {
            payload.email = payload['Email'] = formData.email
        }

        if (formData.utm_source !== undefined && formData.utm_source !== null) {
            payload.utm_source = payload['UTM_Source'] = formData.utm_source
        }
        if (formData.utm_medium !== undefined && formData.utm_medium !== null) {
            payload.utm_medium = payload['UTM_Medium'] = formData.utm_medium
        }
        if (formData.utm_campaign !== undefined && formData.utm_campaign !== null) {
            payload.utm_campaign = payload['UTM_Campaign'] = formData.utm_campaign
        }

        formData.phone = formData.phone.split(' ').join('').replace('(', '').replace(')', '')
        formData.phone = formData.phone.replace('-', '')

        if (formData.phone !== undefined && formData.phone !== null && formData.phone.length > 11) {
            payload.phone_number = payload['Phone Number'] = formData.phone
        }

        if (formData.market_opt !== undefined && formData.market_opt !== null) {
            payload['Accept Marketing'] = formData.market_opt ? true : false
        }
        payload['Abandons Registration'] = true

        try {
            const [res] = await Promise.all([
                apiCall('post', 'subscribeProfiles', payload, '', 'custom/index/klaviyo/klaviyo'),
            ])
            console.log('sdffsdfdsdsf', res)
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updateProfileAfterRegistration = async (formData) => {
        const from = 'updateProfileAfterRegistration'
        let payload = {}

        console.log('sdfdsfsddsf', formData)

        if (formData.first_name !== undefined && formData.first_name !== null) {
            payload.first_name = payload['First Name'] = formData.first_name
        }

        if (formData.last_name !== undefined && formData.last_name !== null) {
            payload.last_name = payload['Last Name'] = formData.last_name
        }

        if (formData.email !== undefined && formData.email !== null) {
            payload.email = payload['Email'] = formData.email
        }

        formData.phone = formData.phone.split(' ').join('').replace('(', '').replace(')', '')
        formData.phone = formData.phone.replace('-', '')

        if (formData.phone !== undefined && formData.phone !== null && formData.phone.length > 11) {
            payload.phone_number = payload['Phone Number'] = formData.phone
        }

        if (formData.regType !== undefined && formData.regType !== null) {
            payload['User Type'] = formData.regType
        }

        if (formData.domicile !== undefined && formData.domicile !== null) {
            payload['Domicile'] = formData.domicile
        }

        if (formData.domesticYN !== undefined && formData.domesticYN !== null) {
            payload['Account Type'] =
                formData.domesticYN === 'domestic_account' ? 'Domestic (US)' : 'International'
        }

        if (formData.dob !== undefined && formData.dob !== null) {
            payload['DOB'] = new Date(formData.dob).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })
        }

        if (formData.address1 !== undefined && formData.address1 !== null) {
            payload['Address Line 1'] = formData.address1
        }

        if (formData.address2 !== undefined && formData.address2 !== null) {
            payload['Address Line 2'] = formData.address2
        }

        if (formData.state !== undefined && formData.state !== null) {
            payload['State'] = formData.state
        }

        if (formData.country !== undefined && formData.country !== null) {
            payload['Country'] = formData.country
        }

        if (formData.zip !== undefined && formData.zip !== null) {
            payload['Zip Code'] = formData.zip
        }

        payload['Opted In'] = true
        payload['Created'] = new Date().toLocaleString('en-US')
        payload['Opted In Date'] = new Date().toLocaleString('en-US')
        payload['Consent'] = 'Email'
        payload['$ource'] = 'Sign Up'
        payload['Abandons Registration'] = false

        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    `updateProfile/${payload.email}`,
                    payload,
                    '',
                    'custom/index/klaviyo/klaviyo',
                ),
            ])
            console.log('sdffsdfdsdsf', res)
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateProfileAfterAddPayment = async (formData) => {
        const from = 'updateProfileAfterAddPayment'
        let payload = {}

        console.log('sdfdsfsddsf', formData)

        if (formData.first_name !== undefined && formData.first_name !== null) {
            payload['Payment Card'] = formData.first_name
        }

        if (formData.first_name !== undefined && formData.first_name !== null) {
            payload['Payment Bank'] = formData.first_name
        }

        if (formData.first_name !== undefined && formData.first_name !== null) {
            payload['Payment Plaid'] = formData.first_name
        }

        if (formData.first_name !== undefined && formData.first_name !== null) {
            payload['Deposit Bank'] = formData.first_name
        }

        if (formData.first_name !== undefined && formData.first_name !== null) {
            payload['Deposit Plaid'] = formData.first_name
        }

        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    `updateProfile/${payload.email}/${process.env.REACT_APP_KLAVIYO_LIST_ID}`,
                    payload,
                    '',
                    'custom/index/klaviyo/klaviyo',
                ),
            ])
            console.log('sdffsd', res)
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateProfile = async (formData) => {
        const from = 'updateProfile'

        const { email, ...payload } = formData

        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    `updateProfile/${email}`,
                    payload,
                    '',
                    'custom/index/klaviyo/klaviyo',
                ),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const trackProfileActivity = async (formData) => {
        const from = 'trackProfileActivity'
        const { email, ...payload } = formData

        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    `trackProfileActivity/${email}`,
                    payload,
                    '',
                    'custom/index/klaviyo/klaviyo',
                ),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const subscribeProfileToListAfterRegister2 = async (formData, noAlert = false) => {
        const from = 'subscribeProfileToListAfterRegister2'
        let payload = {}

        if (formData.email !== undefined && formData.email !== null) {
            payload.email = formData.email
        }

        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    `subscribeProfiles/${process.env.REACT_APP_KLAVIYO_LIST_ID}`,
                    payload,
                    '',
                    'custom/index/klaviyo/klaviyo',
                ),
            ])
            console.log('sdffsdfdsdsf', res)
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    return (
        <KlaviyoContext.Provider
            value={{
                responseStatus: state.responseStatus,
                klaviyoLists: state.klaviyoLists,
                subscribeProfileToListAfterRegister,
                updateProfileAfterRegistration,
                updateProfileAfterAddPayment,
                updateProfile,
                trackProfileActivity,
                clearResponse,
                subscribeProfileToListAfterRegister2,
            }}
        >
            {props.children}
        </KlaviyoContext.Provider>
    )
}

export default KlaviyoState
