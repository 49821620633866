import React, { useContext, useState, useEffect } from 'react'
import ProductContext from '../../../../product/context/product/productContext'
import {
    currencyFormat,
    dateTimeFormatFunction,
    numberFormat,
} from '../../../../custom/common/components'
import { Button, IconButton } from '@material-ui/core'
import FullScreenPopup from '../FullScreenPopup'
import moment from 'moment'
import Charts from './Charts'
import Chip from '@material-ui/core/Chip'
import Icon from '@material-ui/core/Icon'

const ProductMoreinfo = ({ open, handleClose, data, itemArray }) => {
    const { getTrackChart, responseStatus: trackResponse } = useContext(ProductContext)
    const [chartInfo, setChart] = useState({})
    useEffect(() => {
        getTrackChart({
            track_id: itemArray.track_id,
            artist_id: itemArray.artist_id,
            otherData: '',
        })
    }, [])

    useEffect(() => {
        if (trackResponse) {
            if (trackResponse.from === 'track_chart') {
                if (trackResponse.status === 'success') {
                    setChart(trackResponse.data)
                }
            }
        }
    }, [trackResponse])

    return (
        <>
            <FullScreenPopup
                open={open}
                handleClose={handleClose}
                modaltitle=""
                className="vwMrPdtInfo"
            >
                <>
                    <div className="productContainer" id="productView">
                        <div className="vwPdtMoreInfo row align-items-start">
                            <div className="mnTpCntnr col-md-6 col-12 d-none align-items-start">
                                <div className="pdtDtlContainer pl-3 w-100">
                                    <div className="pdtImg">
                                        <div className="ttleValue">
                                            <div className="d-flex align-items-end">
                                                <img
                                                    src={
                                                        itemArray.chartmetric &&
                                                        itemArray.chartmetric.obj
                                                            ? itemArray.track_image
                                                            : itemArray.track_image
                                                            ? global.images_url +
                                                              itemArray.track_image
                                                            : '/assets/images/noImg.png'
                                                    }
                                                />
                                                <p className="m-0 ml-3">
                                                    {itemArray.track_name}
                                                    <br />
                                                    <small>Track</small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row px-4">
                                        <div className="col-12 pl-0">
                                            <div className="d-flex adtnlFlds">
                                                <div className="fldVlu d-flex align-items-center">
                                                    {/* <p className="m-0">{itemArray.tags || ''}</p> */}
                                                    {itemArray.tags.split(',').map((data) => (
                                                        <p className="m-0">
                                                            <Chip className="mr-1" label={data} />
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 pl-0">
                                            <div className="d-flex adtnlFlds mt-3">
                                                <label className="mb-0">Released on</label>
                                                <div className="fldVlu d-flex align-items-center">
                                                    <p className="m-0">
                                                        {moment(itemArray.release_date).format(
                                                            'YYYY-MM-DD',
                                                        ) || ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 pl-0 d-none">
                                            <div className="infoCardWrpr d-flex  ">
                                                <label className="m-0">Links</label>
                                                <div className="fldVlu d-flex align-items-center">
                                                    <p className="m-0">
                                                        <IconButton
                                                            onClick={() =>
                                                                window.open(
                                                                    itemArray.youtube_link,
                                                                    '_blank',
                                                                )
                                                            }
                                                        >
                                                            <img src="/assets/images/youtube.png" />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() =>
                                                                window.open(
                                                                    itemArray.song_chartmetric_link,
                                                                    '_blank',
                                                                )
                                                            }
                                                        >
                                                            <img src="/assets/images/chartmetric.png" />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() =>
                                                                window.open(
                                                                    itemArray.insta_link,
                                                                    '_blank',
                                                                )
                                                            }
                                                        >
                                                            <img src="/assets/images/instagram.png" />
                                                        </IconButton>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {chartInfo.artist && chartInfo.artist.cm_statistics ? (
                                <>
                                    <hr></hr>
                                    <div className="mnTpCntnr col-md-12 col-12 d-flex align-items-start">
                                        <div className="pdtDtlContainer pl-3 w-100">
                                            <div className="pdtImg">
                                                <div className="ttleValue d-flex align-items-end">
                                                    <img
                                                        src={
                                                            chartInfo.artist
                                                                ? chartInfo.artist.image_url
                                                                : '/assets/images/noImg.png'
                                                        }
                                                    />
                                                    <p className="m-0 ml-3">
                                                        {chartInfo.artist.name} <br />{' '}
                                                        <small>Artist</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row px-4">
                                                <div className="col-12 pl-0">
                                                    <div className="infoCardWrpr d-flex align-items-start ">
                                                        <label className="m-0 mr-1">
                                                            <img
                                                                src="/assets/images/spotify.png"
                                                                className="mr-2"
                                                            />
                                                            Spotify
                                                        </label>
                                                        <div className="fldVlu">
                                                            <p className="m-0">
                                                                Followers :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .sp_followers,
                                                                ) || '-'}
                                                            </p>
                                                            <p className="m-0">
                                                                Popularity :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .sp_popularity,
                                                                ) || '-'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 pl-0">
                                                    <div className="infoCardWrpr d-flex align-items-start ">
                                                        <label className="m-0 mr-1">
                                                            <img
                                                                src="/assets/images/instagram.png"
                                                                className="mr-2"
                                                            />
                                                            Instagram
                                                        </label>
                                                        <div className="fldVlu">
                                                            <p className="m-0">
                                                                Followers :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .ins_followers,
                                                                ) || '-'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 pl-0">
                                                    <div className="infoCardWrpr d-flex align-items-start ">
                                                        <label className="m-0 mr-1">
                                                            <img
                                                                src="/assets/images/shazam.png"
                                                                className="mr-2"
                                                            />
                                                            Shazam
                                                        </label>
                                                        <div className="fldVlu">
                                                            <p className="m-0 mr-2">
                                                                Total Counts :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .shazam_count,
                                                                ) || '-'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 pl-0">
                                                    <div className="infoCardWrpr d-flex align-items-start ">
                                                        <label className="m-0">
                                                            <Icon
                                                                className="far fa-gem mr-2"
                                                                fontSize="small"
                                                            />
                                                            Genius
                                                        </label>
                                                        <div className="fldVlu">
                                                            <p className="m-0 mr-2">
                                                                Page Views :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .genius_pageviews,
                                                                ) || '-'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 pl-0">
                                                    <div className="infoCardWrpr d-flex align-items-start ">
                                                        <label className="m-0">
                                                            <img
                                                                src="/assets/images/tiktok.png"
                                                                className="mr-2"
                                                            />
                                                            Tiktok
                                                        </label>
                                                        <div className="fldVlu">
                                                            <p className="m-0 mr-2">
                                                                Views :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .tiktok_top_video_views,
                                                                ) || '-'}
                                                            </p>
                                                            <p className="m-0">
                                                                Followers :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .tiktok_followers,
                                                                ) || '-'}
                                                            </p>
                                                            <p className="m-0">
                                                                Likes :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .tiktok_likes,
                                                                ) || '-'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 pl-0">
                                                    <div className="infoCardWrpr d-flex align-items-start ">
                                                        <label className="m-0">
                                                            <img
                                                                src="/assets/images/youtube.png"
                                                                className="mr-2"
                                                            />
                                                            Youtube
                                                        </label>
                                                        <div className="fldVlu">
                                                            <p className="m-0 mr-2">
                                                                Monthly Views :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .youtube_monthly_video_views,
                                                                ) || '-'}
                                                            </p>
                                                            <p className="m-0 mr-2">
                                                                Daily Views :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .youtube_daily_video_views,
                                                                ) || '-'}
                                                            </p>
                                                            <p className="m-0 mr-2">
                                                                Chennel Subscribers :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .ycs_subscribers,
                                                                ) || '-'}
                                                            </p>
                                                            <p className="m-0">
                                                                Chennel Views :{' '}
                                                                {numberFormat(
                                                                    chartInfo.artist.cm_statistics
                                                                        .ycs_views,
                                                                ) || '-'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="row mx-0">
                            {chartInfo &&
                            chartInfo.stats &&
                            chartInfo.stats.spotifyTrack &&
                            chartInfo.stats.spotifyTrack.length ? (
                                <div className="col-md-6 col-12">
                                    <Charts
                                        axis={['Time', 'Spotify Popularity']}
                                        data={chartInfo.stats.spotifyTrack}
                                        pointer={['timestp', 'value']}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                            {chartInfo &&
                            chartInfo.stats &&
                            chartInfo.stats.tiktokTrack &&
                            chartInfo.stats.tiktokTrack.length ? (
                                <div className="col-md-6 col-12">
                                    <Charts
                                        axis={['Time', 'Tiktok Popularity']}
                                        data={chartInfo.stats.tiktokTrack}
                                        pointer={['timestp', 'value']}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                            {chartInfo &&
                            chartInfo.stats &&
                            chartInfo.stats.youtubeTrack &&
                            chartInfo.stats.youtubeTrack.length ? (
                                <div className="col-md-6 col-12">
                                    <Charts
                                        axis={['Time', 'Youtube Popularity']}
                                        data={chartInfo.stats.youtubeTrack}
                                        pointer={['timestp', 'value']}
                                    />
                                </div>
                            ) : (
                                ''
                            )}

                            {chartInfo &&
                            chartInfo.stats &&
                            chartInfo.stats.spotifyArtist &&
                            chartInfo.stats.spotifyArtist.followers &&
                            chartInfo.stats.spotifyArtist.followers.length ? (
                                <div className="col-md-6 col-12">
                                    <Charts
                                        axis={['Time', 'Artist Spotify Followers']}
                                        data={chartInfo.stats.spotifyArtist.followers}
                                        pointer={['timestp', 'value']}
                                    />
                                </div>
                            ) : (
                                ''
                            )}

                            {chartInfo &&
                            chartInfo.stats &&
                            chartInfo.stats.spotifyArtist &&
                            chartInfo.stats.spotifyArtist.popularity &&
                            chartInfo.stats.spotifyArtist.popularity.length ? (
                                <div className="col-md-6 col-12">
                                    <Charts
                                        axis={['Time', 'Artist Spotify Popularity']}
                                        data={chartInfo.stats.spotifyArtist.popularity}
                                        pointer={['timestp', 'value']}
                                    />
                                </div>
                            ) : (
                                ''
                            )}

                            {/* <div className="col-6 pl-0">
                                <Charts axis={[]} data={} pointer={[]} />
                            </div>
                            <div className="col-6 pl-0">
                                <Charts axis={[]} data={} pointer={[]} />
                            </div>
                            <div className="col-6 pl-0">
                                <Charts axis={[]} data={} pointer={[]} />
                            </div> */}
                        </div>
                    </div>
                </>
            </FullScreenPopup>
        </>
    )
}

export default ProductMoreinfo
