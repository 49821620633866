import React, { useReducer } from 'react'
import TipaltiContext from './tipaltiContext'
import TipaltiReducer from './tipaltiReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import {
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    IFRAME_DATA,
    PAYMENT_DATA,
    SINGLE_PAYEE,
} from './tipaltiTypes'

const TipaltiState = (props) => {
    const initialState = {
        responseStatus: null,
        iFrameData: {},
        single_payee: {},
        paymentData: null,
    }

    const [state, dispatch] = useReducer(TipaltiReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const generateIFrameUrl = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'generateIFrameUrl'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'generateIFrameUrl', formData, '', 'custom/tipalti'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: IFRAME_DATA,
                    payload: {
                        records: res.data.data.responseData,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: IFRAME_DATA,
                    payload: {
                        records: [],
                        from: from,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const createPaymentBatch = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'createPaymentBatch'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createPaymentBatch', formData, '', 'custom/tipalti'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getSinglePayee = async (formData, fromVariable) => {
        const from = fromVariable ? fromVariable : 'getSinglePayee'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSinglePayee', formData, '', 'custom/tipalti'),
            ])
            console.log(res.data.data.responseData, 'res.data.data.responseData')
            if (res.data.data.responseData.status === 'success') {
                await dispatch({
                    type: SINGLE_PAYEE,
                    payload: {
                        records: res.data.data.responseData.responseData,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: SINGLE_PAYEE,
                    payload: {
                        records: [],
                        from: from,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    return (
        <TipaltiContext.Provider
            value={{
                responseStatus: state.responseStatus,
                iFrameData: state.iFrameData,
                single_payee: state.single_payee,
                paymentData: state.paymentData,
                clearResponse,
                generateIFrameUrl,
                getSinglePayee,
                createPaymentBatch,
            }}
        >
            {props.children}
        </TipaltiContext.Provider>
    )
}

export default TipaltiState
