import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import './index.css'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CommonContext from '../../../../product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import ProductContext from '../../../../product/context/product/productContext'
import {
    currencyFormat,
    dateTimeFormatFunction,
    currencyFormatInt,
} from '../../../../custom/common/components'
import { Button } from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const BidHistory = (props) => {
    const classes = useStyles()
    const [ttwBidPopUp, setTTWBidPopUp] = React.useState({
        popup: false,
        id: 0,
    })

    let [viewProduct, setViewProduct] = useState([])

    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { ttwBidHistoryValue, clearTTWBidHistoryValue } = commonContext
    const { search_allttwbidhistory, getAllTtwBidHistory } = productContext
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        id: 0,
    })

    useEffect(() => {
        if (ttwBidHistoryValue) {
            setSearch({ ...search, id: ttwBidHistoryValue, page: 1 })
            setTTWBidPopUp({ popup: true, id: ttwBidHistoryValue })
            clearTTWBidHistoryValue()
        }
    }, [ttwBidHistoryValue])

    useEffect(() => {
        if (search.id !== 0) {
            getAllTtwBidHistory(search)
        }
    }, [search])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(
            search_allttwbidhistory.records.length ? search_allttwbidhistory.records : [],
        )
    }, [search_allttwbidhistory])

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={ttwBidPopUp.popup}
            className={`${classes.modal} customModal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={ttwBidPopUp.popup}>
                <div className="bidHistoryModal">
                    <div className={classes.paper}>
                        <div className="modal fade show custom-modal">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Bid history</h4>
                                        <Button
                                            className="close"
                                            onClick={() => setTTWBidPopUp({ popup: false, id: 0 })}
                                        >
                                            <span className="material-icons">close</span>
                                        </Button>
                                    </div>
                                    <div className="modal-body cm-body table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Shares</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewProduct.map((data, index) => (
                                                    <>
                                                        <tr
                                                            className={
                                                                user && data.user_id === user.id
                                                                    ? 'current_user'
                                                                    : ''
                                                            }
                                                        >
                                                            <td>
                                                                {user && data.user_id === user.id
                                                                    ? user.first_name
                                                                    : data.user_id}
                                                            </td>
                                                            <td>
                                                                {currencyFormatInt(
                                                                    data.currentbid_increment,
                                                                )}
                                                            </td>
                                                            <td>{data.init_proposed_amount}</td>
                                                            <td>
                                                                {data.proposal === 'proxy'
                                                                    ? 'Proxy'
                                                                    : 'Normal'}
                                                            </td>
                                                            <td>
                                                                {dateTimeFormatFunction(
                                                                    data.created_at,
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                                            <h6>
                                                {'Showing'} {search_allttwbidhistory.setDisp} {'of'}{' '}
                                                {search_allttwbidhistory.totalRecords}
                                            </h6>
                                            <Pagination
                                                count={Math.ceil(
                                                    search_allttwbidhistory.totalRecords /
                                                        search.limit,
                                                )}
                                                page={search.page}
                                                onChange={onHandlePage}
                                                siblingCount={3}
                                                showFirstButton
                                                showLastButton
                                                boundaryCount={2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default BidHistory
