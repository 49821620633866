import React, { useState, useEffect } from 'react'
import './ArtistView.css'
import AppLayout from '../../custom/components/templates/AppLayout'
import ReadMore from '../../product/components/atoms/ReadMore'
import Chart from 'react-apexcharts'
import { Button, Divider } from '@material-ui/core'
import Slider from 'react-slick'

const ArtistView = () => {
    const option = {
        series: [
            {
                name: 'series1',
                data: [31, 40, 28, 51, 42, 109, 100],
            },
        ],
        chart: {
            height: 80,
            type: 'area',
            toolbar: {
                show: false,
            },
            // toolbar: {
            //     show: true,
            //     tools: {
            //         zoom: true,
            //         zoomin: true,
            //         zoomout: true,
            //         reset: true,
            //     },
            // },
        },

        colors: ['#67BD8C'],
        stroke: {
            width: 3,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        xaxis: {
            // type: 'datetime',
            // categories: [],
            show: false,
            labels: {
                show: false,
            },
            lines: {
                show: false,
            },
        },

        yaxis: {
            show: false,
            labels: {
                show: false,
            },
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm',
            },
        },
    }

    const settings = {
        dots: true,
        arrows: false,
        // lazyLoad: true,
        // swipeToSlide: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true,
        centerMode: false,
        // nextArrow: <NextArrow />,
        // prevArrow: <PrevArrow />,
        // beforeChange: (current, next) => {
        //     setPreviousSlideIndex(current)
        //     setCurrentSlideIndex(next)
        // },
        // afterChange: (current) => setNextSlideIndex(current),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    let dummyMap = [1, 2, 3, 4, 5]

    return (
        <AppLayout>
            <div className="artistCnt">
                <section className="artistTop">
                    <div className="atImage">
                        <img src="" alt="" />
                    </div>
                    <div className="atContent">
                        <h1>Adele</h1>
                        <ReadMore
                            data="Since the release of her debut album '19', in 2008, Adele has become one of the most successful British artists of her generation. Her critically acclaimed debut album garnered over 6.5 million sales worldwide whilst the album has gone 8x Platinum in the UK. At the age of 19, Adele became the first-ever recipient of the BRITS Critics"
                            customLabel={true}
                            readMoreLabel="Read more"
                            readLessLabel="Read less"
                            limit={335}
                            className="atAbout"
                        />
                        <p className="atMisc">
                            <span>Aesthetic:</span> Classic Soul, Confessional Songwriting, Pop
                            Polish
                        </p>
                        <p className="atMisc">
                            <span>Career Stage:</span> Superstar
                        </p>
                    </div>
                </section>

                <section>
                    <h2 className="acTitle">Summary Statistics</h2>
                    <div className="acSummary">
                        <div className="acsCard">
                            <div className="acsType">
                                <img src="/assets/svg/spotify.svg" alt="Spotify icon" />
                                <p>
                                    Monthly listeners
                                    <span>54.91M</span>
                                </p>
                            </div>
                            <Chart
                                options={option}
                                series={option.series}
                                type="area"
                                height="80"
                                width="100%"
                            />
                        </div>
                        <div className="acsCard">
                            <div className="acsType">
                                <img src="/assets/svg/spotify.svg" alt="Spotify icon" />
                                <p>
                                    Followers
                                    <span>54.91M</span>
                                </p>
                            </div>
                            <Chart
                                options={option}
                                series={option.series}
                                type="area"
                                height="80"
                                width="100%"
                            />
                        </div>
                        <div className="acsCard">
                            <div className="acsType">
                                <img src="/assets/svg/instagram-color.svg" alt="Instagram icon" />
                                <p>
                                    Followers
                                    <span>54.91M</span>
                                </p>
                            </div>
                            <Chart
                                options={option}
                                series={option.series}
                                type="area"
                                height="80"
                                width="100%"
                            />
                        </div>
                        <div className="acsCard">
                            <div className="acsType">
                                <img src="/assets/svg/youtube-color.svg" alt="Youtube icon" />
                                <p>
                                    Subscribers
                                    <span>54.91M</span>
                                </p>
                            </div>
                            <Chart
                                options={option}
                                series={option.series}
                                type="area"
                                height="80"
                                width="100%"
                            />
                        </div>
                        <div className="acsCard">
                            <div className="acsType">
                                <img src="/assets/svg/pandora.svg" alt="Pandora icon" />
                                <p>
                                    Monthly listeners
                                    <span>54.91M</span>
                                </p>
                            </div>
                            <Chart
                                options={option}
                                series={option.series}
                                type="area"
                                height="80"
                                width="100%"
                            />
                        </div>
                    </div>
                </section>

                <section>
                    <h2 className="acTitle">Active Tracks on SongVest</h2>
                    <div className="ssCnt">
                        <Slider {...settings}>
                            {dummyMap?.map((d) => (
                                <div className="ssCard">
                                    <div className="ssTop">
                                        <img src="" alt="" />
                                        <div>
                                            <h4>Happier than ever</h4>
                                            <p>Billie Eilish</p>
                                            <span className="auctionType vip">VIP</span>
                                        </div>
                                    </div>
                                    <div className="ssMid">
                                        <div>
                                            <img
                                                src="/assets/svg/spotify_name.svg"
                                                alt="Spotify logo"
                                            />
                                            <h5>
                                                Streams
                                                <span>53.4x</span>
                                            </h5>
                                        </div>
                                        <div>
                                            <img
                                                src="/assets/svg/pandora_name.svg"
                                                alt="Pandora logo"
                                            />
                                            <h5>
                                                Playlist Reach
                                                <span>53.4x</span>
                                            </h5>
                                        </div>
                                        <div>
                                            <img
                                                src="/assets/svg/youtube_name.svg"
                                                alt="Youtube logo"
                                            />
                                            <h5>
                                                Popularity
                                                <span>53.4x</span>
                                            </h5>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className="ssBottom">
                                        <h5 className="ssPrice">
                                            Price per unit: <span>$110</span>
                                        </h5>
                                        <Button>
                                            View Listing{' '}
                                            <span className="material-icons">chevron_right</span>
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </section>

                <section>
                    <h2 className="acTitle">In the News</h2>
                    <div className="anCnt">
                        <Slider {...settings}>
                            {dummyMap?.map((d) => (
                                <div className="anCard">
                                    <div className="anTop">
                                        <img src="" alt="an logo" />
                                        <span>May 6, 2023</span>
                                    </div>
                                    <p>
                                        Forbes Busi - King Charles’ Coronation: How To Watch It In
                                        The U.S. And When It Starts
                                    </p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </section>

                <section>
                    <h2 className="acTitle">Past Tracks on SongVest</h2>
                    <div className="ssCnt">
                        <Slider {...settings}>
                            {dummyMap?.map((d) => (
                                <div className="ssCard">
                                    <div className="ssTop">
                                        <img src="" alt="" />
                                        <div>
                                            <h4>Happier than ever</h4>
                                            <p>Billie Eilish</p>
                                            <span className="auctionType vip">VIP</span>
                                        </div>
                                    </div>
                                    <div className="ssMid">
                                        <div>
                                            <img
                                                src="/assets/svg/spotify_name.svg"
                                                alt="Spotify logo"
                                            />
                                            <h5>
                                                Streams
                                                <span>53.4x</span>
                                            </h5>
                                        </div>
                                        <div>
                                            <img
                                                src="/assets/svg/pandora_name.svg"
                                                alt="Pandora logo"
                                            />
                                            <h5>
                                                Playlist Reach
                                                <span>53.4x</span>
                                            </h5>
                                        </div>
                                        <div>
                                            <img
                                                src="/assets/svg/youtube_name.svg"
                                                alt="Youtube logo"
                                            />
                                            <h5>
                                                Popularity
                                                <span>53.4x</span>
                                            </h5>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className="ssBottom">
                                        <h5 className="ssPrice">
                                            Sold for: <span>$110</span>
                                        </h5>
                                        <Button>
                                            View Listing{' '}
                                            <span className="material-icons">chevron_right</span>
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </section>
            </div>
        </AppLayout>
    )
}

export default ArtistView
