import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import './Loader.css'
import { Fade } from '@material-ui/core'
import GridViewSkeleton from '../../molecules/Loaders/ProductCardSkeletons/GridViewSkeleton'
import ListViewSkeleton from '../../molecules/Loaders/ProductCardSkeletons/ListViewSkeleton'
import CartSkeleton from '../../molecules/Loaders/CartSkeleton'
import CheckoutSkeleton from '../../molecules/Loaders/CheckoutSkeleton'
import ProductViewSkeleton from '../../molecules/Loaders/ProductViewSkeleton'

const returnSwitch = (props) => {
    switch (props.name) {
        case 'product_grid_view':
            return <GridViewSkeleton />
        case 'product_list_view':
            return <ListViewSkeleton />
        case 'cart':
            return <CartSkeleton />
        case 'checkout':
            return <CheckoutSkeleton />
        case 'product_view':
            return <ProductViewSkeleton />
        default:
            return (
                <div className="mnLoaderWrapper">
                    <CircularProgress disableShrink />
                </div>
            )
    }
}

const Loaders = (props) => {
    return (
        <>
            {props.isLoading ? (
                <>
                    <Fade in={props.isLoading} timeout={600}>
                        <div className={`loadingCnt ${props.className}`}>
                            {[...Array(props.loop ? props.loop : 1)].map((data, index) => (
                                <div className={props.name === 'home' ? 'loader' : ''} key={index}>
                                    {returnSwitch(props)}
                                </div>
                            ))}
                        </div>
                    </Fade>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default Loaders
