import React, { useState, useEffect, useContext } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import PlaidContext from '../../../context/payment/plaid/plaidContext'
import AuthContext from '../../../context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FullScreenPopup from '../../organisms/FullScreenPopup'

const PlaidBankDetails = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [plaidToken, setPlaidToken] = useState(null)
    const [puplicToken, setPuplicToken] = useState(null)
    const plaidContext = useContext(PlaidContext)
    const authContext = useContext(AuthContext)

    const { user, isAuthenticated, loadUser } = authContext
    const {
        plaidLinkTokenCreate,
        dwalloConnect,
        dwollaDeleteSourceResponse,
        responseStatus: responseStatusPlaid,
        clearResponse: clearResponsePlaid,
    } = plaidContext

    // const validationPlaid = Yup.object({
    //     customer_id: Yup.string().required,
    //     name: Yup.string().required,
    //     token: Yup.string().required,
    // })

    // const pliadFormik = useFormik({
    //     initialValues: {
    //         customer_id: '',
    //         name: '',
    //         token: '',
    //     },
    //     validationSchema: validationPlaid,
    //     onSubmit: (values) => {
    //         setIsLoading(true)
    //         dwalloConnect(values)
    //     },
    // })

    useEffect(() => {
        console.log('props.data.', props.data)
        if (props.data.popup) {
            if (props.data.status === 'delete') {
                props.deleteSource()
            } else {
                plaidLinkTokenCreate({})
            }
        }
    }, [props.data.popup])

    useEffect(() => {
        console.log('responseStatusPlaid', responseStatusPlaid)
        if (responseStatusPlaid && responseStatusPlaid.from === 'plaidLinkTokenCreate') {
            if (responseStatusPlaid.data) {
                setPlaidToken(responseStatusPlaid.data.records.link_token)
            }
        }
        if (responseStatusPlaid && responseStatusPlaid.from === 'dwalloConnect') {
            props.function(false, 'new', null)
            props.isWallet(true)
        }
        clearResponsePlaid()
    }, [responseStatusPlaid])

    useEffect(() => {
        if (dwollaDeleteSourceResponse && dwollaDeleteSourceResponse.data) {
            props.function(false, 'new', null)
            props.isWallet(true)
        }
    }, [dwollaDeleteSourceResponse])

    useEffect(() => {
        if (puplicToken) {
            dwalloConnect({
                dwolloID: user.dwolloID,
                customer_id: !puplicToken.account_id
                    ? puplicToken.accounts.length
                        ? puplicToken.accounts[0].id
                        : puplicToken.account_id
                    : puplicToken.account_id,
                token: puplicToken.public_token,
                name: user.first_name + ' plaid connection',
            })
        }
    }, [puplicToken])

    const { open, ready, error, exit } = usePlaidLink({
        token: plaidToken,
        onSuccess: (public_token, metadata) => {
            console.log(public_token, metadata)
            setPuplicToken(metadata)
        },
    })

    useEffect(() => {
        console.log(error)
    }, [error, exit])

    useEffect(() => {
        if (plaidToken && ready) open()
    }, [plaidToken, open])

    return (
        <>
            <FullScreenPopup
                open={props.data.popup && props.data.status === 'new'}
                className="ncModal"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Bank Details"
            >
                <div className="cardDetails">
                    {/* {insertContent && (
                        <iframe
                            src={insertContent}
                            style={{ width: '100%', height: 'calc(100vh - 64px)' }}
                        ></iframe>
                    )} */}
                </div>
            </FullScreenPopup>

            <FullScreenPopup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                className="ncModal"
                modaltitle="Update Bank Details"
            >
                <div className="cardDetails">
                    {/* {updateContent && (
                        <iframe
                            src={updateContent}
                            style={{ width: '100%', height: 'calc(100vh - 64px)' }}
                        ></iframe>
                    )} */}
                </div>
            </FullScreenPopup>
        </>
    )
}

export default PlaidBankDetails
