export const ttwMessageHandler = (data, product, user, setAlert, setViewProduct, type) => {
    let user_id = user.id
    const index = product.findIndex((s) => s.id === parseInt(data.id, 10))
    let productToChange = product[index]
    if (index !== -1) {
        if (type === 'realclosedupdates') {
            if (data.usr !== '') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'won',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                    }
                    setViewProduct([...product])
                } else if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'lost',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                }
            } else {
                if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus: 'lost',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'ttwBidAddtime') {
            let date_closed = product[index].date_closed
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                // notification set
                if (user_id === parseInt(data.bpop_cbidder) && !data.is_proxy)
                    setAlert(
                        `${
                            data.bidding_type != 'proxy' && data.ttwBids ? 'Bid' : 'Proxy'
                        } placed successfully`,
                        'success',
                    )

                if (
                    Object.keys(data).length &&
                    data.userBidTotal &&
                    Object.keys(data.userBidTotal).length &&
                    data.userBidTotal[user_id] &&
                    data.userBidTotal[user_id] < product[index].userBidTotal[user_id]
                ) {
                    setAlert(
                        `${
                            product[index].userBidTotal[user_id] - data.userBidTotal[user_id]
                        } is Outbided from  ${product[index].userBidTotal[user_id]}`,
                        'error',
                    )
                } else {
                    if (
                        data.userBidTotal &&
                        user_id &&
                        product[index].userBidTotal &&
                        Object.keys(product[index].userBidTotal).length &&
                        Object.keys(product[index].userBidTotal).indexOf(user_id.toString()) > -1 &&
                        product[index].userBidTotal[user_id] > 0 &&
                        !data.userBidTotal[user_id]
                    ) {
                        setAlert(`You have been completely outbid.`, 'error')
                        data.bpop_bidstatus = 'outbid'
                    }
                }

                if (data.bidding_type != 'proxy' || data.ttwBids) {
                    product[index] = {
                        ...productToChange,
                        ttwBids: data.ttwBids,
                        bidtopstatus: data.bpop_bidstatus,
                        currentbid_increment: data.currentbid_increment,
                        next_currentbid_increment: data.next_currentbid_increment,
                        isUserHaveHardBid: data.isUserHaveHardBid,
                        userBidTotal: data.userBidTotal,
                        masterEntry: data.masterEntry,
                        masterQty: data.masterQty,
                        bid_count: data.ttwBids.length,
                        ttwBidsObject: data.ttwBidsObject,
                        ttwBidsTotalObject: data.ttwBidsTotalObject,
                        date_closed,
                    }
                    if (
                        data.bidExtended &&
                        data.bidExtended.isExtend &&
                        data.bidExtended.date_closed
                    ) {
                        product[index].date_closed = data.bidExtended.date_closed
                    }
                    setViewProduct([...product])
                }

                // }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'featureProxyList') {
            if (data.status !== 'failed' && data.featureProxyList) {
                product[index] = {
                    ...productToChange,
                    proxy_bid_details: data.featureProxyList[user.id]
                        ? data.featureProxyList[user.id]
                        : data.featureProxyList,
                }
                if (data.bidExtended && data.bidExtended.isExtend && data.bidExtended.date_closed) {
                    product[index].date_closed = data.bidExtended.date_closed
                }
                setViewProduct([...product])
            }
        }
    }
}
