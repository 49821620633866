import React, { useState, useEffect, useContext } from 'react'
import { IconButton, Button } from '@material-ui/core'
import moment from 'moment'
import Popup from '../../organisms/Popup'
import ReactPlayer from 'react-player'
import { Chip } from '@material-ui/core'
import ProductContext from '../../../../product/context/product/productContext'
import Icon from '@material-ui/core/Icon'
import { numberFormat } from '../../../common/components'
import Slider from 'react-slick'

const TrackCard = (props) => {
    const [popopen, setPopopen] = useState(false)
    const [urlvalue, setUrl] = useState('')
    const [items, setItems] = useState([])
    const {
        getTrackChart,
        updateChartmetricArtistImage,
        responseStatus: trackResponse,
    } = useContext(ProductContext)
    const [chartInfo, setChart] = useState({})

    const handleYoutube = (value) => {
        setPopopen(true)
        setUrl(value)
    }

    useEffect(() => {
        if (props.items) {
            let tmpData = props.items
            tmpData.map((item) => {
                item.chartmetricData = item.chartmetricData ? JSON.parse(item.chartmetricData) : {}
                let chartmetirc_tmp =
                    item.chartmetricData && item.chartmetricData.obj ? item.chartmetricData.obj : {}
                let chartmetric =
                    item.chartmetric && item.chartmetric.obj
                        ? item.chartmetric.obj
                        : item.chartmetricData && item.chartmetricData.obj
                        ? chartmetirc_tmp
                        : {}

                item.track_image = chartmetric.image_url ? chartmetric.image_url : item.track_image
                item.track_name = chartmetric.name ? chartmetric.name : item.track_name
                item.tags = chartmetric.tags ? chartmetric.tags : 'Music'
                item.artist =
                    chartmetric.artists && chartmetric.artists.length
                        ? chartmetric.artists[0]['name']
                        : item.artist
                item.artist_id =
                    chartmetric.artists && chartmetric.artists.length
                        ? chartmetric.artists[0]['id']
                        : item.artist_id
                item.release_date = chartmetric.release_date
                    ? chartmetric.release_date
                    : item.track_image
                item.track_details = chartmetric.cm_statistics ? chartmetric.cm_statistics : {}

                item.updateChartmetricData =
                    JSON.stringify(chartmetric) != JSON.stringify(item.chartmetricData)
                        ? true
                        : false

                if (chartmetric) {
                    if (JSON.stringify(chartmetric) != JSON.stringify(chartmetirc_tmp)) {
                        updateChartmetricArtistImage(
                            {
                                project_id: item.project_id,
                                track_id: item.track_id,
                                chartmetricData: item.chartmetric,
                            },
                            true,
                        )
                    }

                    if (
                        chartmetric.artists &&
                        !chartmetric.artists[0].image_url &&
                        !item.artist_image
                    ) {
                        getTrackChart({
                            track_id: chartmetric.id,
                            artist_id: chartmetric.artists[0].id,
                            otherData: '',
                        })
                    }
                }

                return item
            })
            //setItems(props.items)
            setItems(tmpData)
        }
    }, [props.items])

    useEffect(() => {
        if (trackResponse) {
            if (trackResponse.from === 'track_chart') {
                if (trackResponse.status === 'success') {
                    let tmpData = items
                    tmpData.map((item, index) => {
                        if (
                            trackResponse.data.info &&
                            trackResponse.data.info.obj &&
                            trackResponse.data.info.obj.id == item.track_id &&
                            trackResponse.data.artist
                        ) {
                            if (
                                item.chartmetric &&
                                item.chartmetric.obj &&
                                item.chartmetric.obj.artists
                            ) {
                                tmpData[index].chartmetric.obj.artists[0].image_url = trackResponse
                                    .data.artist.image_url
                                    ? trackResponse.data.artist.image_url
                                    : item.artist_image
                                    ? item.artist_image
                                    : item.chartmetric.obj.image_url

                                if (
                                    !item.artist_image ||
                                    (!item.chartmetric.obj.artists[0].image_url &&
                                        item.chartmetric.obj.artists[0].image_url !=
                                            item.artist_image)
                                ) {
                                    updateChartmetricArtistImage(
                                        {
                                            project_id: item.project_id,
                                            track_id: item.track_id,
                                            artist_image: item.chartmetric.obj.artists[0].image_url,
                                        },
                                        true,
                                    )

                                    item.artist_image = item.chartmetric.obj.artists[0].image_url
                                }
                            }
                        }
                    })
                }
            }
        }
    }, [trackResponse])

    useEffect(() => {
        if (trackResponse && 1 == 2) {
            if (trackResponse.from === 'track_chart') {
                if (trackResponse.status === 'success') {
                    //setChart(trackResponse.data)
                    // let updatedImageData = []

                    let tmpData = props.items
                    tmpData.map((item, index) => {
                        let updatedChartmetricData = []
                        updatedChartmetricData['track_' + item.track_id] = {
                            project_id: item.project_id,
                            track_id: item.track_id,
                            updateChartmetricData: item.updateChartmetricData,
                        }

                        if (item.chartmetric && item.chartmetric.obj) {
                            updatedChartmetricData['track_' + item.track_id].chartmetricData =
                                item.chartmetric.obj
                        }

                        if (
                            trackResponse.data.info &&
                            trackResponse.data.info.obj &&
                            trackResponse.data.info.obj.id == item.track_id &&
                            trackResponse.data.artist
                        ) {
                            if (
                                item.chartmetric &&
                                item.chartmetric.obj &&
                                item.chartmetric.obj.artists
                            ) {
                                //item.chartmetric.obj.artists[0].image_url = trackResponse.data
                                tmpData[index].chartmetric.obj.artists[0].image_url = trackResponse
                                    .data.artist.image_url
                                    ? trackResponse.data.artist.image_url
                                    : item.artist_image
                                    ? item.artist_image
                                    : item.chartmetric.obj.image_url

                                /* tmpData[index].chartmetric.obj.artists[0].image_url = trackResponse
                                    .data.artist.image_url
                                    ? trackResponse.data.artist.image_url
                                    : item.chartmetric.obj.image_url */

                                if (
                                    !item.artist_image ||
                                    (!item.chartmetric.obj.artists[0].image_url &&
                                        item.chartmetric.obj.artists[0].image_url !=
                                            item.artist_image)
                                ) {
                                    /* updatedImageData.push({
                                        project_id: item.project_id,
                                        artist_image: item.chartmetric.obj.artists[0].image_url,
                                        track_id: item.track_id,
                                    }) */
                                    updatedChartmetricData[
                                        'track_' + item.track_id
                                    ].updateChartmetricData = true
                                    updatedChartmetricData['track_' + item.track_id].artist_image =
                                        item.chartmetric.obj.artists[0].image_url
                                    tmpData[index].artist_image =
                                        item.chartmetric.obj.artists[0].image_url
                                }

                                //return item
                            }
                        }

                        if (
                            updatedChartmetricData.length > 0 ||
                            Object.keys(updatedChartmetricData).length > 0
                        ) {
                            Object.keys(updatedChartmetricData).map((key) => {
                                if (updatedChartmetricData[key].updateChartmetricData) {
                                    updatedChartmetricData[key].updateChartmetricData = true
                                    updateChartmetricArtistImage(updatedChartmetricData[key], true)
                                }
                            })
                        }
                    })

                    setItems(tmpData)
                }
            }
        }
    }, [trackResponse])

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: (
            <IconButton>
                <span className="material-icons">chevron_right</span>
            </IconButton>
        ),
        prevArrow: (
            <IconButton>
                <span className="material-icons">chevron_left</span>
            </IconButton>
        ),
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    function nFormatter(num) {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
        }
        return num
    }

    return (
        <>
            {/* {console.log('items', items)} */}
            <Slider {...settings}>
                {items.length &&
                    items.map((data, index) => (
                        <>
                            <div className="px-2">
                                <div className="trackCard" key={'tri_' + index}>
                                    <div className="reactPlayerWrapper">
                                        {data.youtube_link !== '' ? (
                                            <ReactPlayer
                                                url={`youtube.com/watch?v=${data?.youtube_link}`}
                                                volume
                                            />
                                        ) : data?.youtube_link == '' ? (
                                            <div className="noImgWrpr">
                                                <img
                                                    className
                                                    src="/assets/images/novideo.png"
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                        ) : (
                                            <div className="noImgWrpr">
                                                <img
                                                    className
                                                    src="/assets/images/novideo.png"
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="dtlOtrWrpr mt-3">
                                        <div className="trackArts">
                                            <div className="d-flex align-items-start justify-content-center">
                                                <div className="trackColumn">
                                                    <div
                                                        className="trImgWrpr cursorDecoy"
                                                        onClick={() => props.handleClose(index)}
                                                    >
                                                        {/* <img
                                                            onError={(e) =>
                                                                (e.src = '/assets/images/noImg.png')
                                                            }
                                                            src={
                                                                data.chartmetric &&
                                                                data.chartmetric.obj
                                                                    ? data.track_image
                                                                    : data.track_image
                                                                    ? global.images_url +
                                                                      data.track_image
                                                                    : '/assets/images/noImg.png'
                                                            }
                                                        /> */}
                                                        <img
                                                            onError={(e) =>
                                                                (e.src = '/assets/images/noImg.png')
                                                            }
                                                            src={
                                                                data.track_image
                                                                    ? data.track_image
                                                                    : global.images_url +
                                                                      '/assets/images/noImg.png'
                                                            }
                                                        />
                                                    </div>
                                                    <div className="trckDtls align-items-start justify-content-between">
                                                        <div className="mnDtls">
                                                            <h5>{data.track_name || ''}</h5>
                                                            <p className="m-0 mt-2">
                                                                {data.artist || ''}
                                                            </p>
                                                            <span>
                                                                {moment(data.release_date).format(
                                                                    'YYYY-MM-DD',
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="trackColumn artistColumn">
                                                    <div
                                                        className="trImgWrpr cursorDecoy"
                                                        onClick={() => props.handleClose(index)}
                                                    >
                                                        {/* <img
                                                            onError={(e) =>
                                                                (e.src = '/assets/images/noImg.png')
                                                            }
                                                            src={
                                                                data.chartmetric &&
                                                                data.chartmetric.obj &&
                                                                data.chartmetric.obj.artists[0]
                                                                    .image_url
                                                                    ? data.chartmetric.obj
                                                                          .artists[0].image_url
                                                                    : '/assets/images/noImg.png'
                                                            }
                                                        /> */}
                                                        <img
                                                            onError={(e) =>
                                                                (e.src = '/assets/images/noImg.png')
                                                            }
                                                            src={
                                                                data.artist_image
                                                                    ? data.artist_image
                                                                    : data.chartmetric &&
                                                                      data.chartmetric.obj &&
                                                                      data.chartmetric.obj
                                                                          .artists[0].image_url
                                                                    ? data.chartmetric.obj
                                                                          .artists[0].image_url
                                                                    : '/assets/images/noImg.png'
                                                            }
                                                        />
                                                    </div>
                                                    <div className="trckDtls align-items-start justify-content-between">
                                                        <div className="mnDtls text-left">
                                                            <h5>{data.artist || ''}</h5>
                                                            <p className="m-0 mt-2">Artist</p>
                                                        </div>
                                                        <div className="vwMoreDtls">
                                                            <div className="icnStk">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        props.handleClose(index)
                                                                    }
                                                                >
                                                                    <img src="/assets/images/chartmetric.png" />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        // handleYoutube(data.insta_link)
                                                                        window.open(
                                                                            data.insta_link,
                                                                            '__blank',
                                                                        )
                                                                    }
                                                                >
                                                                    <img src="/assets/images/instagram.png" />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pdtDtlContainer w-100">
                                            <div className="infoCardWrpr">
                                                <div className="icnNwWrpr">
                                                    <label className="m-0">
                                                        <img src="/assets/images/spotify_new.png" />
                                                    </label>
                                                    <p className="m-0 splVwls">
                                                        <span>
                                                            {nFormatter(
                                                                data.track_details.streams ||
                                                                    data.track_details.sp_streams,
                                                            ) || 'N/A'}
                                                        </span>
                                                        <br />
                                                        Streams
                                                    </p>
                                                </div>
                                                {/* <div className="fldVlu">
                                                    <p className="m-0">
                                                        Popularity :{' '}
                                                        {numberFormat(
                                                            data.track_details.sp_popularity,
                                                        ) || 'N/A'}
                                                    </p>
                                                    <p className="m-0">
                                                        Playlist Reach :{' '}
                                                        {numberFormat(
                                                            data.track_details
                                                                .sp_playlist_total_reach,
                                                        ) || 'N/A'}
                                                    </p>
                                                    <p className="m-0">
                                                        Streams :{' '}
                                                        {numberFormat(
                                                            data.track_details.streams ||
                                                                data.track_details.sp_streams,
                                                        ) || 'N/A'}
                                                    </p>
                                                </div> */}
                                            </div>
                                            <div className="infoCardWrpr">
                                                <div className="icnNwWrpr">
                                                    <label className="m-0">
                                                        <img src="/assets/images/youtube_new.png" />
                                                    </label>
                                                    <p className="m-0 splVwls">
                                                        <span>
                                                            {nFormatter(
                                                                data.track_details.youtube_views,
                                                            ) || 'N/A'}
                                                        </span>
                                                        <br />
                                                        Views{' '}
                                                    </p>
                                                </div>
                                                {/* <div className="fldVlu">
                                                    <p className="m-0 mr-2">
                                                        Views :{' '}
                                                        {numberFormat(
                                                            data.track_details.youtube_views,
                                                        ) || 'N/A'}
                                                    </p>
                                                    <p className="m-0 mr-2">
                                                        Total Reach :{' '}
                                                        {numberFormat(
                                                            data.track_details
                                                                .yt_playlist_total_reach,
                                                        ) || 'N/A'}
                                                    </p>
                                                </div> */}
                                            </div>
                                            <div className="infoCardWrpr">
                                                <div className="icnNwWrpr">
                                                    <label className="m-0">
                                                        <img src="/assets/images/pandora_new.png" />
                                                    </label>
                                                    <p className="m-0 splVwls">
                                                        <span>
                                                            {nFormatter(
                                                                data.track_details
                                                                    .pandora_lifetime_streams,
                                                            ) || 'N/A'}
                                                        </span>
                                                        <br />
                                                        Streams{' '}
                                                    </p>
                                                </div>
                                                {/* <div className="fldVlu">
                                                    <p className="m-0">
                                                        Streams :{' '}
                                                        {numberFormat(
                                                            data.track_details
                                                                .pandora_lifetime_streams,
                                                        ) || 'N/A'}
                                                    </p>
                                                    <p className="m-0">
                                                        Track Stations :{' '}
                                                        {numberFormat(
                                                            data.track_details
                                                                .pandora_lifetime_stations_added,
                                                        ) || 'N/A'}
                                                    </p>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
            </Slider>
            <Popup
                cstmModalClass="maxWdPopup"
                modaltitle={'Songvest'}
                open={popopen}
                handleClose={() => setPopopen(false)}
            >
                <iframe src={urlvalue} width="650" height="400"></iframe>
            </Popup>
        </>
    )
}

export default TrackCard
