import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import commonContext from '../../../../custom/context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'

function SendMoney(props) {
    const { user } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)
    const { createTransfer, dwollaTranseResponse, clearTransResponse } = useContext(commonContext)
    const [loading, setLoading] = useState(false)

    const validationArray = [
        Yup.object({
            amount: Yup.number().required('Required!'),
            //.test('test', 'test message', (value) => value > 10),
        }),
    ]
    const ssnFormik = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: validationArray[0],
        onSubmit: (values) => {
            if (values.amount > props.data.data.balance) {
                setAlert('Check your amount.', 'error')
            } else {
                setLoading(true)
                let payload = {
                    amount: { value: values.amount, currency: 'USD' },
                    links: {
                        source: { href: props.fromSource._links.self.href },
                        destination: { href: props.toSource._links.self.href },
                    },
                    type: 'Wallet To Bank',
                }
                createTransfer(payload)
            }
        },
    })

    useEffect(() => {
        if (dwollaTranseResponse && dwollaTranseResponse.status) {
            setAlert('Request sent successfully', 'success')
            props.function(false, 'send_money', null)
            props.handleResponse(false, 'send_money', null)
            clearTransResponse()
            setLoading(false)
        }
    }, [dwollaTranseResponse])

    const ssnField = [
        {
            label: 'Amount',
            name: 'amount',
            placeholder: 'Enter amount',
            class: `col-12`,
            formik: ssnFormik,
            type: 'number',
            upperLabel: true,
        },
    ]

    return (
        <>
            {props.data.popup && (
                <Popup
                    open={props.data.popup && props.data.status === 'send_money'}
                    cstmModalClass="kycAmlModal"
                    size="sm"
                    handleClose={() => props.function(false, 'send_money', null)}
                    modaltitle="Send Money"
                >
                    <>
                        <form onSubmit={ssnFormik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(ssnField))}</div>
                            <div className="row">
                                <div className="col-12">
                                    <PrimaryButton
                                        disabled={loading}
                                        btnSize="large"
                                        type="submit"
                                        label={loading ? '...' : 'Submit'}
                                    />
                                </div>
                            </div>
                        </form>
                    </>
                </Popup>
            )}
        </>
    )
}

export default SendMoney
