import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import RadioBox from '../../product/components/atoms/RadioBox'
import { useFormik, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { mapData, calculateAge, handleRedirectInternal } from '../../product/common/components'
// import PhoneValidation from './phoneValidation'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'
import { FilterFramesOutlined } from '@material-ui/icons'
import { Document } from 'react-pdf'
import { LOGO } from '../../Utils'
import { parse, isDate } from 'date-fns'
import StripeCustomerContext from '../../product/context/stripe/customer/customerContext'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import KlaviyoContext from '../../custom/context/klaviyo/klaviyoContext'
import AlertContext from '../../product/context/alert/alertContext'
import SettingContext from '../../product/context/seller/setting/settingContext'

import Tooltip from '@material-ui/core/Tooltip'
import Loaders from '../../custom/components/molecules/Loaders'
import CustomPopover from '../../custom/components/atoms/Popover'
import { el } from 'date-fns/locale'
import InitialPopup from './InitialPopup'
import Cryptos from '../../product/common/cryptos'

let randomVariable = Math.random()

const Account = (props) => {
    const authContext = useContext(AuthContext)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isSSN, setSSN] = useState(true)
    const [userType, setUserType] = useState(0)
    const [type, setType] = useState('')
    const [registerUser, setRegisteredUser] = useState(null)
    const [businessClassificationsList, setBusinessClassifications] = useState([])
    const [UserId, setUserId] = useState(null)
    const [currentCountry, setCurrentCountry] = useState()
    const [initialPopup, setInititalPopup] = useState(false)
    const { register, responseStatus, clearResponse, loadUser } = authContext

    const commonContext = useContext(CommonContext)
    const settingContext = useContext(SettingContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { updateProfile, responseStatus: responseStatusUser } = useContext(UserContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const {
        configFeatures,
        createCustomer,
        dwollaCustomerResponse,
        businessClassifications,
        dwollabusinessClassifications,
    } = commonContext
    const { searchTable, searchTableAction, table_data } = settingContext
    const { static_page, getStaticPage, allCountries, allStates } = productCommonContext
    const { responseStatus: responseStatusStripeCustomer, createStripeCustomer } = useContext(
        StripeCustomerContext,
    )
    const {
        onBoardEntity,
        onBoardIndividual,
        addCampaignContact,
        responseStatus: responseStatusNorthCapital,
    } = useContext(NorthCapitalContext)
    const { updateProfileAfterRegistration, subscribeProfileToListAfterRegister2 } = useContext(
        KlaviyoContext,
    )
    let [termsLink, setTermsLink] = useState([])
    let [submitCount, setSubmitCount] = useState('')
    let [zohoRecord, setZohoRecord] = useState({
        email: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        zoho_id: null,
        created_at: null,
    })
    const [currentRegistrationType, setCurrentRegistrationType] = useState(false)
    const [inType, setInType] = useState('SSN')

    const newRegistration = true

    // const [phoneVerify, setPhoneVerify] = useState(false)
    // const [disable, setDisable] = useState(false)

    // const changePhoneVerify = () => {
    //     setPhoneVerify(!phoneVerify)
    // }

    // YUP Validation array to ensure all required fields are given values
    const validationArray = [
        Yup.object({
            user_type: Yup.number().required('Required!'),
            domicile: Yup.string().required('Required!'),
            domesticYN: Yup.string().required('Required!'),
            companyname: Yup.string().when('user_type', {
                is: 1,
                then: Yup.string().required('Required!'),
            }),
            entityType: Yup.string().when('user_type', {
                is: 1,
                then: Yup.string().required('Required!'),
            }),
            entityDesc: Yup.string().when('user_type', {
                is: 1,
                then: Yup.string().required('Required!'),
            }),
            first_name: Yup.string().when('user_type', {
                is: 0,
                then: Yup.string()
                    .trim()
                    .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                    .max(250, 'Maximum 250 characters')
                    .required('Required!'),
            }),
            last_name: Yup.string().when('user_type', {
                is: 0,
                then: Yup.string()
                    .trim()
                    .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                    .max(250, 'Maximum 250 characters')
                    .required('Required!'),
            }),
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            dob: Yup.string().when('user_type', {
                is: 0,
                then: Yup.string().required('Required!'),
            }),

            socialsecurity: Yup.string().when(['regType', 'domicile'], {
                is: (regType, domicile) =>
                    regType === 'individual' ||
                    // domesticYN == 'international_account' ||
                    domicile == 'non-resident',
                then: Yup.string(),
                otherwise: Yup.string()
                    .required('Required!')
                    .test(
                        'len',
                        '9 characters required',
                        (value) => value && value.replace(/_/g, '').replace(/-/g, '').length === 9,
                    ),
            }),
            phone: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'The number only')
                .min(2, 'Invalid format')
                .max(20, 'Maximum 20 characters')
                .required('Required!'),
            password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password')], 'Passwords not match')
                .required('Required!'),
            stripe_id: Yup.string(),
        }),
    ]
    function createStripeUser(userID) {
        if (userType === 1) {
            let entityDesc =
                businessClassificationsList.length > 0
                    ? businessClassificationsList.filter(
                          (data) => data.id === formik.values.entityDesc,
                      )[0]['name']
                    : formik.values.entityDesc
            onBoardEntity(
                {
                    domicile: formik.values.domicile,
                    domesticYN: formik.values.domesticYN,
                    entityName: formik.values.companyname,
                    entityType: formik.values.entityType,
                    entityDesc: entityDesc,
                    ein: formik.values.socialsecurity,
                    primCountry: formik.values.country,
                    primAddress1: formik.values.address1,
                    primAddress2: formik.values.address2,
                    primCity: formik.values.city,
                    primState:
                        formik.values.domesticYN === 'international_account' &&
                        formik.values.domicile == 'non-resident'
                            ? 'NOUS'
                            : formik.values.state,
                    primZip: formik.values.zip,
                    emailAddress: formik.values.email,
                    phone: formik.values.phone,
                    user_id: userID,
                    noAmlCheck: newRegistration ? true : false,
                },
                true,
            )
        } else {
            onBoardIndividual(
                {
                    domicile: formik.values.domicile,
                    domesticYN: formik.values.domesticYN,
                    firstName: formik.values.first_name,
                    lastName: formik.values.last_name,
                    socialSecurityNumber: formik.values.socialsecurity,
                    dob: formik.values.dob,
                    primCountry: formik.values.country,
                    primAddress1: formik.values.address1,
                    primAddress2: formik.values.address2,
                    primCity: formik.values.city,
                    primState:
                        formik.values.domesticYN === 'international_account' &&
                        formik.values.domicile == 'non-resident'
                            ? 'NOUS'
                            : formik.values.state,
                    primZip: formik.values.zip,
                    emailAddress: formik.values.email,
                    phone: formik.values.phone,
                    user_id: userID,
                },
                true,
            )
        }

        // setRegisteredUser(userID)
        // createStripeCustomer({
        //     account_id: 'acct_1Iq9B5PG1bvmzFNY',
        //     description: 'TEST CUSTOMER',
        //     email: formik.values.email,
        //     name:
        //         userType === 1
        //             ? formik.values.companyname
        //             : formik.values.first_name + ' ' + formik.values.last_name,
        //     address: {
        //         line1: '637, Cambridge Circle',
        //         line2: 'Richardson',
        //         city: 'Richardson',
        //         postal_code: '75060',
        //         state: 'Texas',
        //         country: 'US',
        //     },
        // })
    }

    useEffect(() => {
        if (responseStatusNorthCapital) {
            if (
                responseStatusNorthCapital.from === 'onBoardIndividual' ||
                responseStatusNorthCapital.from === 'onBoardEntity'
            ) {
                if (responseStatusNorthCapital.status === 'success') {
                    if (window.fbq) {
                        window.fbq('trackCustom', 'Register', {
                            email: formik.values.email,
                            phone: formik.values.phone,
                        })
                    }
                    /*if (newRegistration) {*/
                    //setInititalPopup(true)

                    let postDataSkip = {
                        kyc_request_count: 1,
                        reg_step_count: 3,
                        reg_steps_skip: '1,2,3,4',
                        is_document_later: 1, //user && user.user_type == 0 && user.domesticYN === 'domestic_account' ? 1 : 0,
                        skipReg: 2, //browse products
                    }

                    updateProfile(postDataSkip, true)

                    /*} else {
                    handleRedirectInternal(history, 'registration/Addpayment')
                    /*}*/
                    //handleRedirectInternal(history, 'registration/Addpayment')
                    // handleRedirectInternal(history, 'login')
                } else {
                    setIsLoading(false)
                }
            }
        }
    }, [responseStatusNorthCapital])

    // useEffect(() => {
    //     if (responseStatusStripeCustomer) {
    //         if (responseStatusStripeCustomer.from === 'create') {
    //             if (responseStatusStripeCustomer.status === 'success') {
    //                 updateProfile(
    //                     {
    //                         authenticate: true,
    //                         user_id: registerUser,
    //                         stripe_id: responseStatusStripeCustomer.data.id,
    //                     },
    //                     true,
    //                 )
    //                 handleRedirectInternal(history, 'login')
    //             }
    //         }
    //     }
    // }, [responseStatusStripeCustomer])

    const createDwollaCustomer = (id) => {
        createCustomer({
            userType: userType,
            user_id: id,
        })
    }

    // Check response status is succesuful
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                if (responseStatus.status === 'success') {
                    localStorage.setItem('userID', responseStatus.data.userID)
                    props.handleNext()
                } else {
                    setIsLoading(false)
                }
            } else if (responseStatus.from === 'checkValidation') {
                if (responseStatus.status !== 'success') {
                } else {
                    setActiveStep(1)
                    window.scrollTo(0, 200)
                }
            }
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        if (dwollaCustomerResponse && Object.keys(dwollaCustomerResponse).length > 0 && UserId) {
            loadUser()
            createStripeUser(UserId)
            setIsLoading(false)
        }
        // else {
        //     setIsLoading(false)
        //     createStripeUser(UserId)
        // }
    }, [dwollaCustomerResponse])

    useEffect(() => {
        getStaticPage({ id: 'terms_of_use' })
        businessClassifications()
    }, [])

    useEffect(() => {
        if (dwollabusinessClassifications && dwollabusinessClassifications.length > 0)
            setBusinessClassifications(
                dwollabusinessClassifications[2]['_embedded']['industry-classifications'],
            )
    }, [dwollabusinessClassifications])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    useEffect(() => {
        formik.values.user_type = userType
    }, [userType])

    useEffect(() => {
        if (inType) {
            formik.values.socialSecurityType = inType
            // formik.setFieldValue('socialsecurity', '')
        }
    }, [inType])

    useEffect(() => {
        if (userType === 0) setInType('SSN')
    }, [userType])

    const viewTerms = () =>
        window.open(
            termsLink
                ? `${global.site_url}/uploads/static_files/${termsLink}`
                : '/assets/docs/termsOfService.pdf',
        )

    // Set Iniaital values
    const formik = useFormik({
        initialValues: {
            regType: 'individual',
            domicile: '',
            username_msg: 'Registration Failed! Record Label Name Already Exists.',
            domesticYN: '',
            companyname: '',
            username: '',
            entityType: '',
            entityDesc: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '+1',
            dob: '',
            socialsecurity: '',
            password: '',
            confirm_password: '',
            status: 'active',
            stripe_id: '',
            user_type: userType,
            socialSecurityType: 'SSN',
            is_marketing_opt: 0,
            utm_source: localStorage.getItem('utm_source') || '',
            utm_medium: localStorage.getItem('utm_medium') || '',
            utm_campaign: localStorage.getItem('utm_campaign') || '',
            converted: 0,
            Abandons_Registration: false,
            Type: 'Registration',
            Relationship: 'Registration',
        },
        validationSchema: validationArray[0],
        onSubmit: (data) => {
            let values = data

            if (values.user_type === 0 && values.domesticYN === 'domestic_account') {
                values.is_document_later = 1
            }
            if (zohoRecord.email === values.email) {
                if (zohoRecord.utm_source && !values.utm_source) {
                    values.utm_source = zohoRecord.utm_source
                }
                if (zohoRecord.utm_medium && !values.utm_medium) {
                    values.utm_medium = zohoRecord.utm_medium
                }
                if (zohoRecord.utm_campaign && !values.utm_campaign) {
                    values.utm_campaign = zohoRecord.utm_campaign
                }

                if (zohoRecord.utm_source || zohoRecord.utm_medium || zohoRecord.utm_campaign) {
                    values.converted = 1
                }

                if (zohoRecord.created_at) {
                    const oneDayInMilliseconds = 24 * 60 * 60 * 1000 // Number of milliseconds in a day
                    const date = new Date() // Get current date and time
                    const currentTimeInMilliseconds = date.getTime() // Get current time in milliseconds

                    if (
                        currentTimeInMilliseconds - oneDayInMilliseconds >
                        new Date(zohoRecord.created_at).getTime()
                    ) {
                        // More than one day has passed
                        values.converted = 1
                    } else {
                        // Less than one day has passed
                        values.converted = 0
                    }
                }

                if (zohoRecord.zoho_id) {
                    values.zoho_id = zohoRecord.zoho_id
                }

                /*if (values.country === 'CA') {
                    setAlert('We can not accept registration from Canada at this time.', 'error')
                    return false
                }*/

                if (userType === 1) {
                    setIsLoading(true)
                    if (values.socialsecurity) {
                        values.socialsecurity = Cryptos.encrypt(values.socialsecurity)
                    }
                    if (localStorage.temp_user_id) {
                        values.temp_user_id = localStorage.temp_user_id
                    }
                    if (localStorage.ttw_temp_user_id) {
                        values.ttw_temp_user_id = localStorage.ttw_temp_user_id
                    }
                    props.registerValues(values)
                    props.handleNext()
                } else {
                    if (calculateAge(values.dob) >= 18) {
                        setIsLoading(true)
                        if (values.socialsecurity) {
                            values.socialsecurity = Cryptos.encrypt(values.socialsecurity)
                        }
                        if (localStorage.temp_user_id) {
                            values.temp_user_id = localStorage.temp_user_id
                        }
                        if (localStorage.ttw_temp_user_id) {
                            values.ttw_temp_user_id = localStorage.ttw_temp_user_id
                        }
                        props.registerValues(values)
                        props.handleNext()
                    } else {
                        setAlert('Age must be 18+', 'error')
                    }
                }
            } else {
                randomVariable = Math.random()
                searchTable(
                    {
                        table: 'zoho_users_logs',
                        page: 1,
                        limit: 2,
                        filters: {
                            category: {
                                value: values.email,
                                type: 'in',
                                field: 'zul.email',
                            },
                        },
                    },
                    randomVariable,
                )
            }
        },
    })

    useEffect(() => {
        if (table_data.from === randomVariable) {
            if (table_data.records && table_data.records.length) {
                setZohoRecord({
                    email: table_data.records[0]?.email,
                    utm_source: table_data.records[0]?.utm_source,
                    utm_medium: table_data.records[0]?.utm_medium,
                    utm_campaign: table_data.records[0]?.utm_campaign,
                    zoho_id: table_data.records[0]?.id,
                    created_at: table_data.records[0]?.created_at,
                })
            } else {
                setZohoRecord({
                    email: formik.values.email,
                    utm_source: null,
                    utm_medium: null,
                    utm_campaign: null,
                    zoho_id: null,
                    created_at: null,
                })
            }

            formik.submitForm()
        }
    }, [table_data])

    useEffect(() => {
        if (localStorage.getItem('utm_source')) {
            formik.setFieldValue('utm_source', localStorage.getItem('utm_source'))
        }
    }, [localStorage.getItem('utm_source')])

    useEffect(() => {
        if (localStorage.getItem('utm_medium')) {
            formik.setFieldValue('utm_medium', localStorage.getItem('utm_medium'))
        }
    }, [localStorage.getItem('utm_medium')])

    useEffect(() => {
        if (localStorage.getItem('utm_campaign')) {
            formik.setFieldValue('utm_campaign', localStorage.getItem('utm_campaign'))
        }
    }, [localStorage.getItem('utm_campaign')])

    // const zohoFormik = useFormik({
    //     initialValues: {
    //         first_name: '',
    //         last_name: '',
    //         email: '',
    //         phone: '+1',
    //     },
    //     onSubmit: (values) => {
    //         console.log('zoho valuesssss--->', values)
    //         try {
    //             addCampaignContact(values, 'Subscriber', true)
    //         } catch (err) {
    //             console.log('Zoho Contact Err =>', err)
    //         }
    //     },
    // })

    // useEffect(() => {
    //     if (responseStatusNorthCapital) {
    //         if (responseStatusNorthCapital.from === 'addCampaignContact') {
    //             window.location.href = 'https://www.songvest.com/registered'
    //         }
    //     }
    // }, [responseStatusNorthCapital])

    useEffect(() => {
        if (
            formik.values.dob.length > 0 &&
            formik.values.dob.userType != 1 &&
            calculateAge(formik.values.dob) < 18
        ) {
            setAlert('Age must be 18+', 'error')
        }
    }, [formik.values.dob])
    useEffect(() => {
        if (formik.values.regType === 'individual') setUserType(0)
        else setUserType(1)
    }, [formik.values.regType])

    useEffect(() => {
        if (formik.isSubmitting && Object.keys(formik.errors).length) {
            if (document.getElementsByName(Object.keys(formik.errors)[0]).length) {
                document.getElementsByName(Object.keys(formik.errors)[0]).forEach((element) => {
                    element.offsetParent !== null && element.focus()
                })
            }
        }
    }, [formik.errors, formik.isSubmitting])

    const registrationTypes = [
        {
            name: 'regType',
            label: 'Individual',
            id: 'individual',
            img: '/assets/svg/individual.svg',
        },
        {
            name: 'regType',
            label: 'Business entity',
            id: 'company',
            img: '/assets/svg/entity.svg',
        },
    ]

    // Mapped over for individual registration fields
    const IndividualRegistration = [
        {
            title: [
                <Tooltip title="Are you a US Resident?" arrow={true} placement="top">
                    <span className="cursorDecoy">Domicile</span>
                </Tooltip>,
            ],
            type: 'radio',
            name: 'domicile',
            class: 'col-12 mb-4',
            item: [
                { id: 'U.S. Citizen', description: 'U.S. Citizen' },
                { id: 'U.S. Resident', description: 'U.S. Resident' },
                { id: 'non-resident', description: 'Non-U.S. Resident' },
            ],
            formik: formik,
        },
        // {
        //     title: <span className="cursorDecoy">Account Type</span>,
        //     type: 'radio',
        //     name: 'domesticYN',
        //     class: 'col-12 mb-4',
        //     item:
        //         formik.values.domicile === 'non-resident'
        //             ? [{ id: 'international_account', description: 'International' }]
        //             : [{ id: 'domestic_account', description: 'Domestic (US)' }],
        //     formik: formik,
        // },
        {
            label: 'Entity Name',
            name: 'companyname',
            type: 'text',
            placeholder: 'Enter your entity name',
            class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''}`,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Entity Type',
            placeholder: 'Select entity type',
            class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''}`,
            type: 'select',
            options: [
                { value: 'Revocable Trust', show: 'Revocable trust' },
                { value: 'Irrevocable Trust', show: 'Irrevocable trust' },
                { value: 'Limited Partnership', show: 'Limited partnership' },
                { value: 'LLC', show: 'LLC' },
                { value: 'Corporation', show: 'Corporation' },
            ],
            name: 'entityType',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Entity Description',
            name: 'entityDesc',
            type: 'select',
            placeholder: 'Enter entity description',
            class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''}`,
            formik: formik,
            upperLabel: true,
            options:
                businessClassificationsList && Array.isArray(businessClassificationsList)
                    ? businessClassificationsList
                          .sort((a, b) => (a.name?.toUpperCase() < b.name?.toUpperCase() ? -1 : 1))
                          .map((buscat) => {
                              let busCategoryChanged = {}
                              busCategoryChanged.show = buscat.name
                              busCategoryChanged.value = buscat.id
                              return busCategoryChanged
                          })
                    : [],
        },
        // {
        //     label: 'First Name',
        //     name: 'first_name',
        //     type: 'text',
        //     placeholder: 'Enter your first name',
        //     class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''}`,
        //     // autoFocus: true,
        //     formik: formik,
        //     upperLabel: true,
        // },
        // {
        //     label: 'Last Name',
        //     name: 'last_name',
        //     type: 'text',
        //     placeholder: 'Enter your last name',
        //     class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''}`,
        //     formik: formik,
        //     upperLabel: true,
        // },
        // {
        //     label: 'Email Address',
        //     name: 'email',
        //     type: 'email',
        //     placeholder: 'Enter your email address',
        //     class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''}`,
        //     formik: formik,
        //     upperLabel: true,
        // },
        // {
        //     label: 'Mobile Number',
        //     name: 'phone',
        //     type: 'phone',
        //     placeholder: 'Mobile number',
        //     class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''}`,
        //     formik: formik,
        //     upperLabel: true,
        // },
        {
            label: 'Date of Birth',
            name: 'dob',
            type: 'date',
            placeholder: 'MM-DD-YYYY',
            class: `col-12 col-sm-6 ${userType === 2 ? 'd-none' : ''}`,
            disableFuture: true,
            formik: formik,
            helperText: (
                <CustomPopover
                    label="You must be 18 years old to register"
                    content="Since we will be paying you royalties, minimul age is 18+."
                />
            ),
            upperLabel: true,
        },
        // {
        //     type: 'misc',
        //     class: `col-md-6 sol-12 ${
        //         formik.values.domicile == 'non-resident' ? 'd-block' : 'd-none'
        //     }`,
        //     content: '',
        // },
    ]

    // if (userType !== 0)
    //     IndividualRegistration.push({
    //         label: 'EIN / SSN',
    //         name: 'socialsecurity',
    //         placeholder: 'Enter EIN / SSN',
    //         class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''} ${
    //             formik.values.domicile == 'non-resident' ? 'd-none' : ''
    //         }`,
    //         formik: formik,
    //         type: 'hiddenMask',
    //         maskValue: '**-*******',
    //         upperLabel: true,
    //         helperText: (
    //             <CustomPopover
    //                 label="Why do we need this?"
    //                 content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
    //             />
    //         ),
    //         // tooltiptitle:
    //         //     'Since we are registered with an SEC regulated broker-dealer. Rally is required by federal law to ask for your Social Security Number',
    //     })

    useEffect(() => {
        if (formik.values.domesticYN && formik.values.domesticYN === 'domestic_account') {
            formik.setFieldValue('country', 'US')
        }
        if (
            formik.values.regType === 'individual' &&
            formik.values.domesticYN === 'domestic_account'
        ) {
            setSSN(false)
        } else {
            setSSN(true)
        }
    }, [formik.values.regType, formik.values.domesticYN])

    useEffect(() => {
        if (formik.values.domicile === 'non-resident') {
            formik.setFieldValue('domesticYN', 'international_account')
        } else {
            formik.setFieldValue('domesticYN', 'domestic_account')
        }
    }, [formik.values.domicile])

    if (isSSN) {
        IndividualRegistration.push({
            label:
                userType === 1 ? (
                    <div className="swtchTabs">
                        <p
                            className={`${inType === 'EIN' ? 'activeTab' : ''}`}
                            onClick={() => setInType('EIN')}
                        >
                            <span className="material-icons mr-1">
                                {inType === 'EIN'
                                    ? 'radio_button_checked'
                                    : 'radio_button_unchecked'}
                            </span>{' '}
                            EIN
                        </p>
                        <p
                            className={`${inType === 'SSN' ? 'activeTab' : ''}`}
                            onClick={() => setInType('SSN')}
                        >
                            <span className="material-icons mr-1">
                                {inType === 'SSN'
                                    ? 'radio_button_checked'
                                    : 'radio_button_unchecked'}
                            </span>{' '}
                            SSN
                        </p>
                    </div>
                ) : (
                    'Social Security Number'
                ),
            name: 'socialsecurity',
            placeholder: inType === 'EIN' ? 'Enter EIN' : 'Enter SSN',
            //class: `col-12 col-sm-6  ${formik.values.regType === 'individual' ? 'd-none' : ''}`,
            class: `col-12 col-sm-6  ${
                formik.values.regType === 'individual' ||
                //formik.values.domesticYN === 'international_account' ||
                formik.values.domicile === 'non-resident'
                    ? 'd-none'
                    : ''
            }`,
            formik: formik,
            type: 'hiddenMask',
            maskValue: inType === 'EIN' ? '**-*******' : '***-**-****',
            upperLabel: true,
            helperText: (
                <CustomPopover
                    label="Why do we need this?"
                    content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
                />
            ),
        })
    }

    // if (userType !== 1)
    //     IndividualRegistration.push({
    //         label: 'Social Security Number',
    //         name: 'socialsecurity',
    //         type: 'hiddenMask',
    //         maskValue: '***-**-****',
    //         placeholder: 'Enter Social Security Number',
    //         class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''} ${
    //             formik.values.domicile == 'non-resident' ? 'd-none' : ''
    //         }`,
    //         formik: formik,
    //         upperLabel: true,
    //         helperText: (
    //             <CustomPopover
    //                 label="Why do we need this?"
    //                 content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
    //             />
    //         ),
    //     })
    // {
    //     label: 'EIN / SSN',
    //     name: 'socialsecurity',
    //     type: 'number',
    //     placeholder: 'Enter EIN / SSN',
    //     class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''}`,
    //     formik: formik,
    //     tooltiptitle:
    //         'Since we are registered with an SEC regulated broker-dealer. Rally is required by federal law to ask for your Social Security Number',
    // },
    // {
    //     label: 'Social Security Number',
    //     name: 'socialsecurity',
    //     type: 'number',
    //     placeholder: 'Enter Social Security Number',
    //     class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''}`,
    //     formik: formik,
    //     tooltiptitle:
    //         'Since we are registered with an SEC regulated broker-dealer. Rally is required by federal law to ask for your Social Security Number',
    // },
    IndividualRegistration.push(
        {
            label: 'Create Password',
            name: 'password',
            type: 'password',
            placeholder: 'Enter your password',
            class: 'col-12 col-sm-6',
            formik: formik,
            helperText: 'Password must have minimum 8 characters',
            upperLabel: true,
        },
        {
            label: 'Confirm Password',
            name: 'confirm_password',
            type: 'password',
            placeholder: 'Re enter your password',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
    )

    useEffect(() => {
        console.log(JSON.parse(localStorage.getItem('state')), 'state')
        if (localStorage?.state) {
            let preRegValues = JSON.parse(localStorage.getItem('state'))
            // zohoFormik.setFieldValue(
            //     'first_name',
            //     preRegValues.first_name ? preRegValues.first_name : '',
            // )
            // zohoFormik.setFieldValue(
            //     'last_name',
            //     preRegValues.last_name ? preRegValues.last_name : '',
            // )
            // zohoFormik.setFieldValue('email', preRegValues.email ? preRegValues.email : '')
            // zohoFormik.setFieldValue('phone', preRegValues.phone ? preRegValues.phone : '')
            // zohoFormik.setFieldValue(
            //     'domesticYN',
            //     preRegValues.domesticYN ? preRegValues.domesticYN : '',
            // )
            formik.setFieldValue(
                'first_name',
                preRegValues.first_name ? preRegValues.first_name : '',
            )
            formik.setFieldValue('last_name', preRegValues.last_name ? preRegValues.last_name : '')
            formik.setFieldValue('email', preRegValues.email ? preRegValues.email : '')
            formik.setFieldValue('phone', preRegValues.phone ? preRegValues.phone : '')
            formik.setFieldValue(
                'is_marketing_opt',
                preRegValues.is_marketing_opt ? preRegValues.is_marketing_opt : 0,
            )
            formik.setFieldValue(
                'domesticYN',
                preRegValues.domesticYN ? preRegValues.domesticYN : '',
            )
            localStorage.removeItem('state')
        }
    }, [localStorage?.state])

    useEffect(() => {
        if (localStorage?.username) {
            formik.setFieldValue('username', localStorage.username ? localStorage.username : '')
            localStorage.removeItem('username')
        }
    }, [localStorage?.username])

    return (
        <>
            <div className="loginWrapper regLoginWrapper p-5 h-100">
                <form className="registerForm mb-0 row" onSubmit={formik.handleSubmit}>
                    <div className="row w-100">
                        <div className="RadioBox col-md-12 mt-3">
                            <div>
                                <label className="radioTitle">User Type</label>
                                <div className="d-flex align-items-center">
                                    {registrationTypes.map((data, index) => (
                                        <div className="regType mr-3" key={index}>
                                            <input
                                                type="radio"
                                                name={data.name}
                                                hidden
                                                id={data.id}
                                                onChange={formik.handleChange}
                                                checked={formik.values.regType == data.id}
                                                value={data.id}
                                            />
                                            <label htmlFor={data.id}>
                                                <img src={data.img} alt="" />
                                                <h5>{data.label}</h5>
                                                <div className="radioIcon"></div>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">{Object.values(mapData(IndividualRegistration))}</div>
                    <div className="regActBox mt-2">
                        <PrimaryButton
                            id="register_button"
                            label="NEXT"
                            type="submit"
                            onClick={() => formik.handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default Account
