import React, { useState, useContext, useEffect } from 'react'
import { currencyFormatFloat } from '../../custom/common/components'
import AppLayout from '../../custom/components/templates/AppLayout'
import royaltyContext from '../../custom/context/royalty/royaltyContext'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import ProductSongCard from '../../custom/components/organisms/ProductSongCard'
import moment from 'moment'
import './PublicProfile.css'

const Preview = (props) => {
    const productContext = useContext(ProductContext)
    const { getDashboardData, dashboardData_List } = useContext(royaltyContext)

    const {
        search_alldashboardproducts,
        getAllDashboardProducts,
        responseStatus: productResponseStatus,
    } = productContext

    const [products, setProducts] = useState([])

    const [singleProduct, setSingleProduct] = useState({
        limit: 12,
        page: 1,
        orderby: 'p.date_closed,asc',
        order: '',
        groupby: 'p.id',
        sumOfColumn: 'sum(b.qty) as totalQty',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            condition: {
                value: [],
                type: 'array',
                field: 'it.conditionTypeId',
            },
            location: {
                value: [],
                type: 'array',
                field: 'p.location_id',
            },
            price: {
                value: '',
                type: 'greaterequal',
                field: 'p.wprice',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
            auctionid: {
                value: [],
                type: 'array',
                field: 'p.auctionid',
            },
            auctiontype: {
                value: 'buynow',
                type: 'in',
                field: 'p.auctiontype',
            },
        },
        action: 'buynow_paid',
        username: props.match.params.user_name,
    })

    const onHandlePageAuction = (event, value) => {
        setSingleProduct({ ...singleProduct, page: value })
    }

    let currentDate = new Date()
    let quarter = Math.floor(currentDate.getMonth() / 3)
    let startDate = new Date(currentDate.getFullYear(), quarter * 3 - 3, 1)
    let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0)

    if (startDate && endDate) {
        startDate =
            startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
        endDate = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate()
    }

    useEffect(() => {
        getDashboardData({
            fromDate: '',
            toDate: '',
            song_share: 0,
            royalty_quarter_dropdown: 'life_time',
            lastQuarterEndDate: endDate,
            lastQuarterStartDate: startDate,
            chartStartDate: '',
            chartEndDate: '',
            investment_quarter_dropdown: 'life_time',
            page_opensong: 1,
            page_ownedsong: 1,
            order_opensong: 'desc',
            orderby_opensong: 'id',
            username: props.match.params.user_name,
        })
    }, [props.match.params.user_name])

    useEffect(() => {
        getAllDashboardProducts(singleProduct)
    }, [singleProduct])

    useEffect(() => {
        if (search_alldashboardproducts?.records?.length > 0) {
            setProducts((prevProducts) => search_alldashboardproducts.records)
        } else {
            setProducts([])
        }
    }, [search_alldashboardproducts])

    return (
        <AppLayout>
            <div
                className={`ryltyDashbrdWrapper publicProfile ${
                    dashboardData_List && Object.keys(dashboardData_List)?.length ? '' : 'noRR'
                }`}
            >
                <div className="profileName">
                    <h2>
                        <img src="/assets/svg/user.svg" />
                        {search_alldashboardproducts?.userinfo?.fullName}
                    </h2>
                    <h6>
                        Member since{' '}
                        {moment(search_alldashboardproducts?.userinfo?.created_at).format(
                            'MMM YYYY',
                        )}
                    </h6>
                </div>
                {dashboardData_List && Object.keys(dashboardData_List).length ? (
                    <div className="topActDtls">
                        <div className="dshCardCntnr d-flex align-items-center">
                            <div className="imgContainer">
                                <img src="/assets/svg/invested_amount.svg" />
                            </div>
                            <div className="text-left">
                                <p className="m-0">Total SongShares Owned </p>
                                <h3 className="m-0">
                                    {dashboardData_List ? dashboardData_List?.totalInvestedQty : 0}
                                </h3>
                            </div>
                        </div>
                        <div className="dshCardCntnr d-flex align-items-center">
                            <div className="imgContainer">
                                <img src="/assets/svg/royalties_paid.svg" />
                            </div>
                            <div className="text-left">
                                <p className="m-0">Total Royalties Received</p>
                                <h3 className="m-0">
                                    {dashboardData_List
                                        ? `$ ${parseFloat(
                                              Math.floor(dashboardData_List.totalRoyalties * 100) /
                                                  100,
                                          ).toFixed(2)}`
                                        : 0}
                                </h3>
                            </div>
                        </div>
                        <div className="dshCardCntnr d-flex align-items-center">
                            <div className="imgContainer">
                                <img src="/assets/svg/uproyalty.svg" />
                            </div>
                            <div className="text-left">
                                <p className="m-0">Previous Quarter Royalties Received</p>
                                <h3 className="m-0">
                                    {dashboardData_List
                                        ? `$ ${parseFloat(
                                              Math.floor(
                                                  dashboardData_List.lastQuarterRoyalties * 100,
                                              ) / 100,
                                          ).toFixed(2)}`
                                        : 0}
                                </h3>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div
                    className={`songCardContainer container-lg ${
                        dashboardData_List && Object.keys(dashboardData_List)?.length ? '' : 'noRR'
                    } ${products?.length ? '' : 'empty'}`}
                >
                    {products?.length ? (
                        <>
                            {products?.map((data, index) => (
                                <ProductSongCard
                                    data={data}
                                    dashboardData_List={dashboardData_List}
                                />
                            ))}
                        </>
                    ) : (
                        <div className="previewNoRecords">No records found</div>
                    )}
                </div>
                {search_alldashboardproducts?.totalRecords > 0 ? (
                    <div className="songCardContainer container-lg d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                        <h6 className="m-0">
                            Showing {search_alldashboardproducts.setDisp} of{' '}
                            {search_alldashboardproducts.totalRecords}
                        </h6>
                        <Pagination
                            count={Math.ceil(
                                search_alldashboardproducts.totalRecords / singleProduct.limit,
                            )}
                            page={singleProduct.page}
                            onChange={onHandlePageAuction}
                            siblingCount={3}
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                        />
                    </div>
                ) : null}
            </div>
        </AppLayout>
    )
}

export default Preview
