import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Drawer, ListItem } from '@material-ui/core'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import './ProductCard.css'
import CommonContext from '../../../../product/context/common/commonContext'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { dateTimeFormatFunction } from '../../../../product/common/components'
import Timer from '../../../../product/common/timer'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

function AuctionCard(props) {
    const commonContext = useContext(CommonContext)
    const { setSearchValue, allLocations } = commonContext
    const [auction, setAuction] = useState({})

    const history = useHistory()
    const classes = useStyles()

    const [state, setState] = useState({
        bottom: false,
    })

    useEffect(() => {
        setAuction(props.data)
    }, [props.data])

    const formik = useFormik({
        initialValues: {
            auctionid: '',
        },
    })

    const auctionRedirect = () => {
        history.push({
            pathname: '/liveAuction',
            state: auction,
        })
        window.scrollTo(0, 0)
    }

    const handleRedirectInternal = (path) => {
        history.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    const handleClose = (state) => {
        state(false)
    }

    const handleOpen = (state) => {
        state(true)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    return (
        <div className="auctionCard d-flex justify-content-between align-items-center">
            <div className="auctionCardLt d-flex">
                <div className="acImg">
                    <img
                        src={`${global.site_url}/uploads/auction/${auction.avatar}`}
                        alt="Live Auction"
                    />
                </div>
                <div className="acContent">
                    <h2>{auction.title}</h2>
                    <p className="acAddress">{auction.description}</p>
                    <p className="acStartDate">
                        <span>Start date:</span>
                        {dateTimeFormatFunction(auction.date_added)}
                    </p>
                </div>
            </div>
            <div className="acActBtn">
                <div className="acTimer d-flex justify-content-center align-items-center flex-wrap">
                    <h6>
                        <span className="material-icons">timer</span>
                    </h6>
                    <p>
                        {auction.date_closed ? (
                            <Timer
                                date_added={auction.date_added}
                                date_closed={auction.date_closed}
                                withText={1}
                                endText={'Time left' + ':'}
                                startText={'Starts in' + ':'}
                            ></Timer>
                        ) : null}
                    </p>
                </div>
                <PrimaryButton
                    onClick={() => auctionRedirect(auction.id)}
                    label="Explore auction"
                    btnSize="small"
                />
            </div>
        </div>
    )
}

export default AuctionCard
