import React from 'react'
import './ProductSongCard.css'
import { currencyFormat } from '../../../common/components'
import moment from 'moment'
import Slider from 'react-slick'

const ProductSongCard = (props) => {
    const data = props.data
    const trackDetails = data.itemPropsArray
    const chartmeticData = trackDetails[0]?.chartmetricData
        ? JSON.parse(trackDetails[0]?.chartmetricData)?.obj
        : {}

    function nFormatter(num) {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
        }
        return num
    }

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // nextArrow: (
        //     <IconButton>
        //         <span className="material-icons">chevron_right</span>
        //     </IconButton>
        // ),
        // prevArrow: (
        //     <IconButton>
        //         <span className="material-icons">chevron_left</span>
        //     </IconButton>
        // ),
    }

    return (
        <div className="productSongCard">
            <img src={global.images_url + data.avatar} alt={data.title} className="pscImage" />
            <h3 className="pscTitle">{data.title}</h3>
            {props.dashboardData_List && Object.keys(props.dashboardData_List).length ? (
                <div className="pscPayDetails">
                    <h6>
                        Total Units <span>{data?.totalQty ? data?.totalQty : 'N/A'}</span>
                    </h6>

                    <h6>
                        Total Paid Out{' '}
                        <span>
                            {data?.total_paidout ? currencyFormat(data?.total_paidout) : 'N/A'}
                        </span>
                    </h6>
                    <h6>
                        Last Payment{' '}
                        <span>
                            {data?.buynow_paid_date
                                ? moment(data?.buynow_paid_date).format('MMM YYYY')
                                : 'N/A'}
                        </span>
                    </h6>
                </div>
            ) : null}

            {console.log(trackDetails, 'trackDetails')}
            <Slider {...settings}>
                {trackDetails?.length ? (
                    trackDetails?.map((item, index) => (
                        <div className="pscSongDetails">
                            <div className="sdTop">
                                <img
                                    src={item?.artist_image || '/assets/svg/noArtist.svg'}
                                    alt=""
                                    onError={(e) => (e.target.src = '/assets/svg/noArtist.svg')}
                                />
                                <div>
                                    <h6>{item?.track_name || 'N/A'}</h6>
                                    <p>{item?.artist || 'N/A'}</p>
                                </div>
                            </div>

                            <div className="sdBottom">
                                <div className="sdStream">
                                    <img src="/assets/svg/spotify_name.svg" alt="Spotify logo" />
                                    <h6>
                                        Streams
                                        <span>
                                            {' '}
                                            {nFormatter(
                                                JSON.parse(trackDetails[index]?.chartmetricData)
                                                    ?.obj?.cm_statistics?.sp_streams,
                                            ) || 'N/A'}
                                        </span>
                                    </h6>
                                </div>
                                <div className="sdStream">
                                    <img src="/assets/svg/pandora_name.svg" alt="Pandora logo" />
                                    <h6>
                                        Playlist Reach
                                        <span>
                                            {nFormatter(
                                                JSON.parse(trackDetails[index]?.chartmetricData)
                                                    ?.obj?.cm_statistics?.pandora_lifetime_streams,
                                            ) || 'N/A'}
                                        </span>
                                    </h6>
                                </div>
                                <div className="sdStream">
                                    <img src="/assets/svg/youtube_name.svg" alt="Youtube logo" />
                                    <h6>
                                        Popularity
                                        <span>
                                            {nFormatter(
                                                JSON.parse(trackDetails[index]?.chartmetricData)
                                                    ?.obj?.cm_statistics?.youtube_views,
                                            ) || 'N/A'}
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="pscSongDetails">
                        <div className="sdTop">
                            <img
                                src={'/assets/svg/noArtist.svg'}
                                alt=""
                                onError={(e) => (e.target.src = '/assets/svg/noArtist.svg')}
                            />
                            <div>
                                <h6>{'N/A'}</h6>
                                <p>{'N/A'}</p>
                            </div>
                        </div>

                        <div className="sdBottom">
                            <div className="sdStream">
                                <img src="/assets/svg/spotify_name.svg" alt="Spotify logo" />
                                <h6>
                                    Streams
                                    <span> {'N/A'}</span>
                                </h6>
                            </div>
                            <div className="sdStream">
                                <img src="/assets/svg/pandora_name.svg" alt="Pandora logo" />
                                <h6>
                                    Playlist Reach
                                    <span>{'N/A'}</span>
                                </h6>
                            </div>
                            <div className="sdStream">
                                <img src="/assets/svg/youtube_name.svg" alt="Youtube logo" />
                                <h6>
                                    Popularity
                                    <span>{'N/A'}</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    )
}

export default ProductSongCard
