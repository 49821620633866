import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    PAYMENT_DATA,
    IFRAME_DATA,
    SINGLE_PAYEE,
} from './tipaltiTypes'
export default (state, action) => {
    switch (action.type) {
        case IFRAME_DATA:
            return {
                ...state,
                iFrameData: action.payload,
            }
        case PAYMENT_DATA:
            return {
                ...state,
                paymentData: action.payload,
            }
        case SINGLE_PAYEE:
            return {
                ...state,
                single_payee: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
