import React, { useReducer } from 'react'
import RoyaltyContext from './royaltyContext'
import RoyaltyReducer from './royaltyReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import {
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    ROYALTIES_LIST,
    DASHBOARDDATA_LIST,
    OWNEDSONGSHARES_LIST,
    OPENSONGSHARES_LIST,
    ROYALTY_CHART_DATA,
    SONGSHARES_LIST,
    INVESTMENT_CHART_DATA,
} from './royaltyTypes'

const RoyaltyState = (props) => {
    const initialState = {
        responseStatus: null,
        royalties_list: null,
        ownedSongShares_List: null,
        openSongShares_List: null,
        royalty_chart_data: null,
        songshares_list: null,
        investment_chart_data: null,
    }

    const [state, dispatch] = useReducer(RoyaltyReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const getRoyalties = async (formData) => {
        const from = 'getRoyalties'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/royalty'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: ROYALTIES_LIST,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: ROYALTIES_LIST,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getDashboardData = async (formData) => {
        const from = 'getDashboardData'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/royalty'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: DASHBOARDDATA_LIST,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: DASHBOARDDATA_LIST,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const listOwnedSongShares = async (formData) => {
        const from = 'listOwnedSongShares'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/royalty'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: OWNEDSONGSHARES_LIST,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: OWNEDSONGSHARES_LIST,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const listOpenSongShares = async (formData) => {
        const from = 'listOpenSongShares'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/royalty'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: OPENSONGSHARES_LIST,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: OPENSONGSHARES_LIST,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateAutoPurchase = async (formData, from = 'updateAutoPurchase') => {
        // const from = fromVariable ? fromVariable : 'updateAutoPurchase'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateAutoPurchase', formData, '', 'paymentThird/royalty'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const listSongShares = async (formData) => {
        const from = 'listSongShares'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/royalty'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: SONGSHARES_LIST,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: SONGSHARES_LIST,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getRoyaltyChartData = async (formData) => {
        const from = 'getRoyaltyChartData'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/royalty'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: ROYALTY_CHART_DATA,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: ROYALTY_CHART_DATA,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getInvestementChartData = async (formData) => {
        const from = 'getInvestementChartData'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/royalty'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: INVESTMENT_CHART_DATA,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: INVESTMENT_CHART_DATA,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    return (
        <RoyaltyContext.Provider
            value={{
                responseStatus: state.responseStatus,
                royalties_list: state.royalties_list,
                dashboardData_List: state.dashboardData_List,
                ownedSongShares_List: state.ownedSongShares_List,
                openSongShares_List: state.openSongShares_List,
                royalty_chart_data: state.royalty_chart_data,
                songshares_list: state.songshares_list,
                investment_chart_data: state.investment_chart_data,
                clearResponse,
                getRoyalties,
                getDashboardData,
                listOwnedSongShares,
                listOpenSongShares,
                updateAutoPurchase,
                getRoyaltyChartData,
                listSongShares,
                getInvestementChartData,
            }}
        >
            {props.children}
        </RoyaltyContext.Provider>
    )
}

export default RoyaltyState
