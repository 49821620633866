import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    CREATE_CUSTOMER,
    GET_SOURCE,
    DELETE_SOURCE,
    BUSINESS_CLARIFICATION,
    CREATE_TRANSFER,
    TRANSFERS_RESPONSE,
    NOTIFY_BUY_ALL_SHARES,
    FREE_OFFERS,
    UPDATE_OFFERS,
    CLEAR_OFFERS,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                allCategory: action.payload.allCategory,
                allLocations: action.payload.allLocations,
                allSubCategories: action.payload.allSubCategories,
                allCondition: action.payload.allCondition,
                allBusinessCategory: action.payload.allBusinessCategory,
                allBidIncrements: action.payload.allBidIncrements,
                allConfigurationVariables: action.payload.allConfigurationVariables,
                allNotifications: action.payload.allNotifications,
                bidIncrementDefault: action.payload.bidIncrementDefault,
                configFeatures: action.payload.configFeatures,
                phrases: action.payload.phrases,
                languages: action.payload.languages,
                allIncomeType: action.payload.allIncomeType,
                allPaidBy: action.payload.allPaidBy,
                allAssetType: action.payload.allAssetType,
                allTerm: action.payload.allTerm,
                allFooter: action.payload.allFooter,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CREATE_CUSTOMER:
            return {
                ...state,
                dwollaCustomerResponse: action.payload,
            }
        case CREATE_TRANSFER:
            return {
                ...state,
                dwollaTranseResponse: action.payload,
            }
        case GET_SOURCE:
            return {
                ...state,
                ...action.payload,
            }
        case TRANSFERS_RESPONSE:
            return {
                ...state,
                ...action.payload,
            }
        case DELETE_SOURCE:
            return {
                ...state,
                ...action.payload,
            }
        case BUSINESS_CLARIFICATION:
            return {
                ...state,
                ...action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case NOTIFY_BUY_ALL_SHARES:
            return {
                ...state,
                ...action.payload,
            }
        case FREE_OFFERS:
            return {
                ...state,
                freeOffers: action.payload,
            }
        case UPDATE_OFFERS:
            return {
                ...state,
                updateOffers: action.payload,
            }
        case CLEAR_OFFERS:
            return {
                ...state,
                freeOffers: [],
            }
        default:
            return state
    }
}
