import React, { useContext, useEffect, useState } from 'react'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import RadioBox from '../../product/components/atoms/RadioBox'
import { useFormik, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { mapData, currencyFormat, handleRedirectInternal } from '../../product/common/components'
// import PhoneValidation from './phoneValidation'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'
import { FilterFramesOutlined } from '@material-ui/icons'
import { Document } from 'react-pdf'
import { LOGO } from '../../Utils'
import { parse, isDate } from 'date-fns'
import StripeCustomerContext from '../../product/context/stripe/customer/customerContext'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import Tooltip from '@material-ui/core/Tooltip'
import { Button } from '@material-ui/core'
import AlertContext from '../../product/context/alert/alertContext'

import NorthCapitalCardDetails from '../../custom/components/molecules/NorthCapitalCardDetails'
import NorthCapitalPlaidDetails from '../../custom/components/molecules/NorthCapitalPlaidDetails'
import NorthCapitalBankDetails from '../../custom/components/molecules/NorthCapitalBankDetails'
import Loaders from '../../custom/components/molecules/Loaders'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import PlaidBankDetails from '../../product/components/molecules/Plaid'

const RegSteps = (props) => {
    const authContext = useContext(AuthContext)

    const history = useHistory()

    const [userType, setUserType] = useState(props.match.params.type === 'entity' ? 1 : 0)
    const [isLoading, setLoader] = useState(false)
    const [currentCountry, setCurrentCountry] = useState()
    const { register, responseStatus, clearResponse, user, loadUser } = authContext

    const commonContext = useContext(CommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { updateProfile } = useContext(UserContext)

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const { configFeatures, allConfigurationVariables } = commonContext
    const { static_page, getStaticPage } = productCommonContext
    const { responseStatus: responseStatusStripeCustomer, createStripeCustomer } = useContext(
        StripeCustomerContext,
    )
    const {
        onBoardEntity,
        onBoardIndividual,
        updateIndividual,
        getExternalAccount,
        north_capital_bank_details,
        north_capital_card_details,
        getUserDetails,
        getCreditCard,
        responseStatus: responseStatusNorthCapitals,
    } = useContext(NorthCapitalContext)
    let [termsLink, setTermsLink] = useState([])
    let [skip, setSkip] = useState([])
    let [finalRedirect, setFinalRedirect] = useState(false)
    let [bankLinked, setBankLinked] = useState(false)
    let [isWallet, setIsWallet] = useState(false)
    let [cardAdded, setCardState] = useState(false)
    let [isDirectContactAccepted, setIsDirectContactAccepted] = useState(false)

    const [stepCount, setStepCount] = useState(0)
    const mobileQuery = useMediaQuery('(max-width:800px)')
    const handleChange = (event) => {}
    const updateUser = (count = stepCount) => {
        let extra = isDirectContactAccepted ? { isDirectContactAccepted: 1 } : {}
        updateProfile(
            {
                reg_step_count: count,
                kyc_request_count: 1,
                ...extra,
            },
            true,
        )
    }

    function clickNext() {
        console.log(stepCount)
        if (stepCount < 2) {
            let step = stepCount + 1
            setStepCount(step)
            updateUser(step)
        }
    }
    async function clickSkip() {
        if (stepCount < 3) {
            let step = stepCount + 1
            setStepCount(step)
            setSkip([...skip, step])
            updateProfile(
                {
                    reg_step_count: step,
                    kyc_request_count: 1,
                },
                true,
            )
        } else {
            setLoader(true)
            await updateProfile(
                {
                    reg_steps_skip: skip.length ? skip.join(',') + ',4' : '4',
                },
                true,
            )
            setFinalRedirect(true)
            await getUserDetails()
            await loadUser()
            // handleRedirectInternal(history, 'registration/reupdate')
        }
    }

    useEffect(() => {
        if (user) {
            if (user.reg_step_count < 3) setStepCount(user.reg_step_count)
            else handleRedirectInternal(history, '')
        }
        if (user && finalRedirect) {
            handleRedirectInternal(history, 'search')
            setLoader(false)
        }
        if (user && user.dwollo_source > 0) {
            setIsWallet(true)
        }
    }, [user])

    useEffect(() => {
        if (isWallet === true && stepCount === 0) clickNext()
    }, [isWallet])

    function clickPrev() {
        if (stepCount > 0) setStepCount(stepCount - 1)
    }
    const [manageBank, setManageBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenBankPopup = (popup, status, data) => {
        setManageBank({ popup, status, data })
    }

    const [manageExternalBank, setManageExternalBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenExternalBankPopup = (popup, status, data) => {
        setManageExternalBank({ popup, status, data })
    }

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const [managePlaidBank, setManagePlaidBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenPlaidBankPopup = (popup, status, data) => {
        setManagePlaidBank({ popup, status, data })
    }

    useEffect(() => {
        if (responseStatusNorthCapitals) {
            if (responseStatusNorthCapitals.from === 'addCreditCard') {
                if (responseStatusNorthCapitals.status === 'success') {
                    getCreditCard()
                    clickNext()
                }
            }
            if (responseStatusNorthCapitals.from === 'addExternalBank') {
                if (responseStatusNorthCapitals.status === 'success') {
                    getExternalAccount()
                    clickNext()
                }
            }
        }
    }, [responseStatusNorthCapitals])

    useEffect(() => {
        if (north_capital_card_details) {
            if (north_capital_card_details.data.creditcardDetails) {
                setCardState(true)
            } else {
                setCardState(false)
            }
        }
    }, [north_capital_card_details])

    useEffect(() => {
        if (north_capital_bank_details) {
            if (north_capital_bank_details.data.statusDesc) {
                setBankLinked(true)
                stepFormik.setFieldValue('alreadyCheck', true)
            } else {
                setBankLinked(false)
                stepFormik.setFieldValue('alreadyCheck', false)
            }
        }
    }, [north_capital_bank_details])

    const validationSSN = Yup.object({
        socialSecurityNumber: Yup.string().required('Required!'),
    })

    const stepFormik = useFormik({
        initialValues: {
            alreadyCompleted: '',
            securityNumber: '',
            maxbid_requested: false,
        },
        onSubmit: (values) => {},
    })

    const alreadyCheck = [
        {
            label: 'Already Completed',
            name: 'alreadyCheck',
            type: 'checkbox',
            class: 'chkBoxWrpr alrdyCmpltd',
            formik: stepFormik,
            disabled: bankLinked,
        },
    ]

    const validationMaxBid = Yup.object({
        maxbid_requested: Yup.number().required('Required!'),
        limit_range: Yup.number().when('maxbid_requested', {
            is: 1,
            then: Yup.number()
                .positive('Should be grater than 1')
                .min(
                    allConfigurationVariables.max_bid_limit + 1,
                    'Must be greater than ' + allConfigurationVariables.max_bid_limit,
                )
                .integer("Can't be a decimal")
                .required('Required!'),
        }),
    })

    const maxBidFormik = useFormik({
        initialValues: {
            maxbid_requested: '',
            limit_range: allConfigurationVariables.max_bid_limit,
        },
        validationSchema: validationMaxBid,
        onSubmit: async (values) => {
            await updateProfile(
                {
                    is_maxbid_requested: values.maxbid_requested === '1' ? 1 : 0,
                    limit_range:
                        values.maxbid_requested === '1'
                            ? values.limit_range
                            : allConfigurationVariables.max_bid_limit,
                },
                true,
            )
            await updateUser(stepCount + 1)
            // await getUserDetails()
            // await loadUser()
            // setFinalRedirect(true)
            let step = stepCount + 1
            setStepCount(step)
        },
    })

    const maxBidRequest = [
        {
            title: '',
            type: 'radio',
            name: 'maxbid_requested',
            class: 'col-md-6 col-12 mb-3',
            item: [
                { id: '1', description: 'Yes, Increase My Limit' },
                { id: '0', description: 'No, I Accept the Limit' },
            ],
            formik: maxBidFormik,
            disabled: user && user.is_maxbid_requested,
        },
        {
            label: 'New Limit Amount Request',
            placeholder: 'Enter New Limit Amount',
            type: 'number',
            name: 'limit_range',
            class: maxBidFormik.values.maxbid_requested === '1' ? 'col-6 mb-3' : 'd-none',
            formik: maxBidFormik,
        },
    ]

    const twoFAFormik = useFormik({
        initialValues: {
            is2FAEnabled: false,
        },
        onSubmit: async (values) => {
            await updateProfile(
                {
                    is2FAEnabled: values.is2FAEnabled,
                },
                true,
            )
            await updateUser(stepCount + 1)
            await getUserDetails()
            await loadUser()
            setFinalRedirect(true)
        },
    })

    const twoFaRequest = [
        {
            name: 'is2FAEnabled',
            type: 'switch',
            color: 'primary',
            formik: twoFAFormik,
        },
    ]

    useEffect(() => {
        if (stepCount === 0 || stepCount === 1) {
            getExternalAccount()
            getCreditCard()
        }
    }, [stepCount])

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (
                event.origin === 'https://api-sandboxdash.norcapsecurities.com' ||
                event.origin === 'https://api.norcapsecurities.com'
            ) {
                toggleFullScreenBankPopup(false, 'new', null)
                getExternalAccount()
                clickNext()
            }
        })
    }, [])

    useEffect(() => {
        if (user && user.is_maxbid_requested)
            maxBidFormik.setFieldValue('maxbid_requested', user && user.is_maxbid_requested)
    }, [user])

    const shipFormik = useFormik({
        initialValues: {
            skipDeposit: false,
            internationBank: false,
        },
        onSubmit: (values) => {
            // console.log(values)
        },
    })

    useEffect(() => {
        if (shipFormik.values.internationBank)
            setIsDirectContactAccepted(shipFormik.values.internationBank)
    }, [shipFormik.values.internationBank])

    const depositSkip = [
        {
            label: `Click here to continue registration without adding deposit information. 
            Royalty payments outsite the US may need to have US taxes withheld. 
            Consult your tax advisor on any international  tax ramifications.`,
            name: 'skipDeposit',
            type: 'checkbox',
            class: 'chkBoxWrpr alrdyCmpltd skipCheckBox',
            formik: shipFormik,
            disabled: bankLinked,
        },
    ]

    const internationBankAccount = [
        {
            label:
                'Click here if you only have an international bank account. We will contact you directly for payment information. Royalty payments outside the US may need to have US taxes withheld. Consult your tax advisor on any international tax ramifications.',
            name: 'internationBank',
            type: 'checkbox',
            class: 'chkBoxWrpr alrdyCmpltd skipCheckBox',
            formik: shipFormik,
            disabled: bankLinked,
        },
    ]

    function stepSwitch(stepCount) {
        switch (stepCount) {
            case 0:
                return (
                    <>
                        <p className="text-left">
                            Before you can bid on an auction or purchase shares at SongVest we need
                            to gather additional information
                        </p>
                        <p className="text-left">
                            Just a reminder, you are purchasing a security that pays you royalties
                            each quarter if any are earned, so not only will we need payment
                            information to make the purchase, but we will also need deposit
                            information so we know where to deposit your royalty checks.
                        </p>
                        <h4 className="text-left regHdrTxt mt-5 mb-4">
                            <b>How to Pay for Your Purchase</b>
                        </h4>
                        <p className="text-left">
                            You have two options for paying for items on the SongVest platform.
                        </p>
                        <p className="text-left">
                            Credit Card or ACH are the accepted methods. We can accommodate wire
                            transfers for larger transcations
                        </p>

                        {/* Dwollo integration */}
                        {/* <div className="btnDiv d-flex align-items-start mt-4">
                            <PrimaryButton
                                label="SETUP WALLET"
                                onClick={() => toggleFullScreenPlaidBankPopup(true, 'new', null)}
                                disabled={isWallet}
                            />
                            <p className="text-left">
                                You can directly connect to your bank account by manually entering
                                account and routing numbers.
                            </p>
                        </div> */}

                        <div className="btnDiv d-flex align-items-start mt-5">
                            <PrimaryButton
                                label="SETUP ACH CONNECTION"
                                onClick={() => toggleFullScreenBankPopup(true, 'new', null)}
                                disabled={bankLinked}
                            />
                            <p className="text-left">
                                You can directly connect to your bank through Plaid. It
                                automatically connects to your bank account and allows payment as
                                well as deposits when you get paid royalties.
                            </p>
                        </div>
                        <div className="btnDiv d-flex align-items-start mt-4">
                            <PrimaryButton
                                label="SETUP MANUAL ACH"
                                onClick={() => toggleFullScreenExternalBankPopup(true, 'new', null)}
                                disabled={bankLinked}
                            />
                            <p className="text-left">
                                You can directly connect to your bank account by manually entering
                                account and routing numbers.
                            </p>
                        </div>

                        <div className="btnDiv d-flex align-items-start mt-4">
                            <PrimaryButton
                                label="ADD CREDIT CARD"
                                disabled={cardAdded}
                                onClick={() => toggleFullScreenCardPopup(true, 'new', null)}
                            />
                            <p className="text-left">
                                You can add a credit card for payment. We do add an additional fee
                                of 3.15% for all credit card transactions.
                            </p>
                        </div>
                    </>
                )
            case 1:
                return (
                    <>
                        {bankLinked ? (
                            <>
                                <p className="text-left">
                                    Purchasing SongShare Units include being paid royalties on a
                                    quarterly basis, if royalties are due.{' '}
                                </p>{' '}
                                <p className="text-left">
                                    Payments currently must be made to a US-based bank account via
                                    ACH. Please enter your banking information here so we can
                                    deposit these royalties in the appropriate account.
                                </p>
                                <h4 className="text-left regHdrTxt mt-5 mb-4">
                                    <b>Deposit Information</b>
                                </h4>
                                {!bankLinked && (
                                    <>
                                        <p className="text-left">
                                            You have two options for connecting a bank account to
                                            receive royalty payments on the SongVest platform.
                                        </p>
                                        <p className="text-left">
                                            Automated ACH connections are through Plaid, or you can
                                            manually enter in your ACH information.
                                        </p>
                                    </>
                                )}
                                <div className="btnDiv d-flex align-items-start mt-5 justify-content-start">
                                    {bankLinked && mapData(alreadyCheck)}
                                </div>
                                <p className="text-left">
                                    Your ACH bank account information from Step 1 is successfully
                                    connected.
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="text-left">
                                    Purchasing SongShare Units include being paid royalties on a
                                    quarterly basis, if royalties are due.
                                </p>
                                <p className="text-left">
                                    Payments currently must be made to a US-based bank account via
                                    ACH. Please enter your banking information here so we can
                                    deposit these royalties in the appropriate account.
                                </p>
                                <h4 className="text-left regHdrTxt mt-5 mb-4">
                                    <b>Deposit Information</b>
                                </h4>
                                <p className="text-left">
                                    You have two options for connecting a bank account to receive
                                    royalty payments on the SongVest platform.
                                </p>{' '}
                                <p className="text-left">
                                    Automated ACH connections are through Plaid, or you can manually
                                    enter in your ACH information.
                                </p>
                                {user && user.domicile === 'non-resident' && !bankLinked && (
                                    <p className="text-left text-danger">
                                        International Customers: you can only use ACH if you have US
                                        based bank account.
                                    </p>
                                )}
                                <form onSubmit={stepFormik.handleSubmit}>
                                    {!bankLinked && (
                                        <>
                                            <div className="btnDiv d-flex align-items-start mt-5 justify-content-start">
                                                <PrimaryButton
                                                    label="ADD MANUAL ACH"
                                                    disabled={bankLinked}
                                                    onClick={() =>
                                                        toggleFullScreenExternalBankPopup(
                                                            true,
                                                            'new',
                                                            null,
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="btnDiv d-flex align-items-start mt-5 justify-content-start">
                                                <PrimaryButton
                                                    label="SETUP ACH CONNECTION"
                                                    onClick={() =>
                                                        toggleFullScreenBankPopup(true, 'new', null)
                                                    }
                                                    disabled={bankLinked}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {user && !bankLinked && (
                                        <>
                                            <div className=" d-flex align-items-start mt-5 justify-content-start">
                                                {mapData(depositSkip)}
                                            </div>
                                            {user.domicile === 'non-resident' && (
                                                <div className=" d-flex align-items-start mt-3 justify-content-start">
                                                    {mapData(internationBankAccount)}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </form>
                            </>
                        )}
                    </>
                )
            case 2:
                return (
                    <>
                        <p className="text-left">
                            All VIP Auctions have a default bidding limit of{' '}
                            {currencyFormat(allConfigurationVariables.max_bid_limit)} per auction.
                            This is in place to limit exposure to fake or bad faith bidding on our
                            auction platform.
                        </p>
                        <p className="text-left">
                            If you would like to remove this limit, click below and a message will
                            be sent to our finance department for review.
                        </p>
                        <p className="text-left">
                            You will be notified via email of approvals. If we need additional
                            information, we may reach out to you via email or phone.
                        </p>
                        {/* <p className="text-left">
                            You will be notified via email on approval. If we need additional
                            information our finance department may reach out to you via email or
                            phone
                        </p> */}
                        <div className="btnDiv d-flex align-items-start mt-5 justify-content-start">
                            {/* <PrimaryButton label="Yes, Increase My Limit" onClick={updateRequest} /> */}
                            {mapData(maxBidRequest)}
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        <p>Enable / Disable two factor authentication {mapData(twoFaRequest)}</p>
                        <small>
                            By turning on two factor authentication, you will receive an email with
                            a code each time you login.
                        </small>
                    </>
                )
            default:
                return 'unknown'
        }
    }

    const disableButton = () => {
        let temp = false
        if (stepCount === 0) temp = bankLinked || cardAdded
        else if (stepCount === 1) temp = bankLinked || shipFormik.values.skipDeposit
        return !temp
    }

    return (
        <RegistrationLayout>
            <Loaders name="home" isLoading={isLoading} loop={1} className="defaultLoader" />
            <div className="loginWrapper p-5 h-100">
                {stepCount <= 3 && (
                    <Button className="skipNavBtn" onClick={() => clickSkip()}>
                        Skip
                        <span className="material-icons ml-1">double_arrow</span>
                    </Button>
                )}
                <h3 className="text-center mnTxt stepCounter">Step {stepCount + 1}</h3>
                {stepSwitch(stepCount)}

                <div
                    className={`btmNavigation ${
                        stepCount <= 0 ? 'justify-content-end ' : 'justify-content-between'
                    }`}
                >
                    <Button
                        className={`navBtn ${stepCount <= 0 ? 'd-none' : ''}`}
                        onClick={() => clickPrev()}
                    >
                        <span className="material-icons">chevron_left</span>
                        <span className="material-icons mr-3">chevron_left</span> Previous
                    </Button>

                    {stepCount === 2 ? (
                        <Button
                            className="navBtn"
                            onClick={maxBidFormik.handleSubmit}
                            // disabled={disableButton()}
                        >
                            Next
                            <span className="material-icons ml-1">chevron_right</span>
                            <span className="material-icons">chevron_right</span>
                        </Button>
                    ) : stepCount === 3 ? (
                        <Button className="navBtn ml-auto" onClick={twoFAFormik.handleSubmit}>
                            Finish
                            <span className="material-icons ml-1 finishBtnIcon">task_alt</span>
                        </Button>
                    ) : (
                        <Button
                            className="navBtn"
                            onClick={() => clickNext()}
                            disabled={disableButton()}
                        >
                            Next
                            <span className="material-icons ml-1">chevron_right</span>
                            <span className="material-icons">chevron_right</span>
                        </Button>
                    )}
                </div>
                <p className="disclaimerTxt text-left mt-3">
                    <small>
                        All securities-related activity is conducted through Dlamore Group, LLC
                        (&quot;Dalmore&quot;), a registered broker-dealer and member FINRA/SIPC.
                        Dalmore does not make recommendations and acts only as the broker/dealer of
                        record for the offering listed. You can review the background of our
                        broker-dealer and our investment professionals on FINRA&apos;s BrokerCheck.
                        {mobileQuery && <br />}
                        <a href="https://brokercheck.finra.org/firm/summary/136352">
                            https://brokercheck.finra.org/firm/summary/136352
                        </a>
                    </small>
                </p>
            </div>
            <NorthCapitalPlaidDetails data={manageBank} function={toggleFullScreenBankPopup} />
            <NorthCapitalCardDetails data={manageCard} function={toggleFullScreenCardPopup} />
            <NorthCapitalBankDetails
                data={manageExternalBank}
                function={toggleFullScreenExternalBankPopup}
            />
            {/* <PlaidBankDetails
                data={managePlaidBank}
                function={toggleFullScreenPlaidBankPopup}
                isWallet={(d) => setIsWallet(d)}
            /> */}
        </RegistrationLayout>
    )
}

export default RegSteps
