import { Button } from '@material-ui/core'
import React from 'react'
import './Dashboard.css'
import SideNav from './SideNav'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const DashboardLayout = (props) => {
    const history = useHistory()
    return (
        <div className="dashboard pt-0">
            <div className="">
                <SideNav />
                <div className="dashboardRt customContainer pt-5">{props.children}</div>
            </div>
        </div>
    )
}

export default DashboardLayout
