// for common data
export const COMMON_VALUES = 'COMMON_VALUES'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const GET_SOURCE = 'GET_SOURCE'
export const DELETE_SOURCE = 'DELETE_SOURCE'
export const BUSINESS_CLARIFICATION = 'BUSINESS_CLARIFICATION'
export const CREATE_TRANSFER = 'CREATE_TRANSFER'
export const TRANSFERS_RESPONSE = 'TRANSFERS_RESPONSE'
export const NOTIFY_BUY_ALL_SHARES = 'NOTIFY_BUY_ALL_SHARES'
export const FREE_OFFERS = 'FREE_OFFERS'
export const UPDATE_OFFERS = 'UPDATE_OFFERS'
export const CLEAR_OFFERS = 'CLEAR_OFFERS'
