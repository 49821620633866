import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AuthContext from '../../../../product/context/auth/authContext'
import PayContext from '../../../../product/context/payment/payContext'
import CreditContext from '../../../../product/context/credit/creditContext'

import ProductContext from '../../../../product/context/product/productContext'
import CommonContext from '../../../../product/context/common/commonContext'
import KlaviyoContext from '../../../../custom/context/klaviyo/klaviyoContext'

import CustomDialog from '../../organisms/Dialog'
import {
    handleRedirectInternal,
    currencyFormat,
    currencyFormatInt,
} from '../../../common/components'
import { mapData } from '../../../../product/common/components'
import StripeCardContext from '../../../../product/context/stripe/card/cardContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import CustomContext from '../../../context/common/commonContext'

const Bidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)
    const creditContext = useContext(CreditContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const { searchValue } = commonContext
    const { getAllSearchProducts } = productContext

    const { payCartRosoom } = payContext
    const { addBidDeposit, responseStatus: responseStatusCredit } = creditContext
    const { updateProfile } = useContext(KlaviyoContext)

    let { bidConfirm } = buyerContext
    const { user, isAuthenticated } = authContext
    const [product, setProduct] = React.useState()
    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const [cards, setCards] = useState([])
    const [canadianUser, setCanadianUser] = useState(false)

    const { allConfigurationVariables } = useContext(CustomContext)

    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
    } = useContext(StripeCardContext)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    useEffect(() => {
        if (responseStatusCredit) {
            if (responseStatusCredit.from === 'addBidDeposit') {
                if (responseStatusCredit.status === 'success') {
                    setToggleDialogDeposit(false)
                    getAllSearchProducts(searchValue)
                }
            }
        }
    }, [responseStatusCredit])

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (user && user.country == 'CA') {
            setCanadianUser(true)
        }
    }, [user])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(
                product ? product.next_bid : 0,
                `${'Min bid'} ${
                    product ? currencyFormatInt(product.next_bid) : currencyFormatInt(0)
                }`,
            )

            .when('bidding_type', {
                is: (biddingType) => biddingType === 'hard',
                then: Yup.number().max(
                    product
                        ? product.wprice *
                              (allConfigurationVariables.auction_hard_bid_max_val
                                  ? allConfigurationVariables.auction_hard_bid_max_val
                                  : 2)
                        : 0,
                    'Sorry, but your bid is above the maximum allowed limit.',
                ),
                otherwise: Yup.number().max(
                    product
                        ? product.rprice *
                              (allConfigurationVariables.auction_proxy_bid_max_val
                                  ? allConfigurationVariables.auction_proxy_bid_max_val
                                  : 5)
                        : 0,
                    'Sorry, but your bid is above the maximum allowed limit.',
                ),
            })
            .integer("Can't be a decimal")
            .required('Enter bid amount'),
    })
    const validationPayment = Yup.object({
        payment_method: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: props.data.id,
            bidding_type: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (user.mail_verified) {
                if (product.deposit && !product.bid_deposit_id) {
                    setToggleDialogDeposit(true)
                } else if (toggleDialog) {
                    let item = product
                    var _learnq = window._learnq || []
                    var itemArr = {
                        ProductName: item.title,
                        ProductID: item.id,
                        Categories: 'Auction',
                        URL: window.location.href,
                        Price: item.auctiontype === 'buynow' ? item.bprice : item.wprice,
                    }
                    _learnq.push(['track', 'Auction Bid', itemArr])
                    console.log(_learnq, ['track', 'Auction Bid', itemArr])
                    // updateProfile({
                    //     email: user.email,
                    //     'Last Bid Item': item.title,
                    // })
                    bidConfirm(values)
                    setToggleDialog(false)
                    formik.resetForm()
                } else {
                    setToggleDialog(true)
                }
            } else {
                handleRedirectInternal(history, 'account/profile')
                setAlert('Please verify your email', 'error')
            }
        },
    })

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    const formikPayment = useFormik({
        initialValues: {
            cart_id: props.data.id,
            pay_type: 'bid_deposit',
            payment_method: '',
        },
        validationSchema: validationPayment,
        onSubmit: (values) => {
            formikPayment.setSubmitting(true)
            if (values.payment_method === 'wallet') {
                let formData = {
                    amount: product.deposit_amount,
                    product_id: values.cart_id,
                    credit_notes: 'Bid Deposit',
                    credit_type: 'user',
                    type: 'projects',
                    creditor_id: user.id,
                }
                addBidDeposit(formData)
            } else if (values.payment_method === 'online') {
                let formData = {
                    cart_id: values.cart_id,
                    payment_object: {
                        type: 'projects',
                        amount: 0,
                    },
                    pay_type: values.pay_type,
                }
                payCartRosoom(formData)
            }
        },
    })

    const bidAmount = [
        {
            // label:'Enter bid amount',
            name: 'wsprice',
            type: 'number',
            placeholder: `${'Min'} ${
                product && product.next_bid
                    ? currencyFormatInt(product.next_bid)
                    : currencyFormatInt(0)
            }`,
            class: '',
            // size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]
    const modeOfPayment = [
        {
            label: 'Select mode of payment',
            placeholder: 'Select mode of payment',
            type: 'select',
            class: 'col-12',
            options: [
                { value: 'online', show: 'Online' },
                { value: 'wallet', show: 'Wallet' },
            ],
            name: 'payment_method',
            formik: formikPayment,
        },
    ]

    useEffect(() => {
        if (get_all_card_details) {
            setCards(get_all_card_details.records)
        }
    }, [get_all_card_details])

    useEffect(() => {
        if (isAuthenticated) {
            // getStripeCards()
            // setDis(true)
        }
    }, [isAuthenticated])

    const getStripeCards = () => {
        getAllStripeCards({
            account_id: 'acct_1Iq9B5PG1bvmzFNY',
            customer_id: user.stripe_id,
            object: 'card',
        })
    }

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div
                                className={`biddingCnt d-flex justify-content-between align-items-start ${
                                    props.type === 'proxy' ? 'pxyBdPop' : ''
                                }`}
                            >
                                <div className={`bidInput d-flex align-items-start`}>
                                    {isAuthenticated && Object.values(mapData(bidAmount))}
                                    {props.type == 'hard' && props.suffix && (
                                        <div className="suffixWrapper px-3">Units @ $12.00</div>
                                    )}
                                </div>
                                {
                                    isAuthenticated ? (
                                        product.bidtopstatus === 'winner' ? (
                                            <SecondaryButton
                                                id={
                                                    formik.values.bidding_type === 'hard'
                                                        ? 'increase_hardbid_button'
                                                        : 'increase_proxybid_button'
                                                }
                                                label={
                                                    formik.values.bidding_type === 'hard'
                                                        ? 'Increase hard bid'
                                                        : 'Increase proxy bid'
                                                }
                                                type="submit"
                                                btnSize="small"
                                            />
                                        ) : (
                                            <PrimaryButton
                                                id={
                                                    formik.values.bidding_type === 'hard'
                                                        ? 'place_bid_button'
                                                        : 'place_proxybid_button'
                                                }
                                                label={
                                                    formik.values.bidding_type === 'hard'
                                                        ? 'Place Bid'
                                                        : 'Place proxy bid'
                                                }
                                                type="submit"
                                                btnSize="small"
                                                disabled={
                                                    canadianUser && product.auctiontype != 'live'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )
                                    ) : null
                                    // (
                                    // <PrimaryButton
                                    //     label="Login to bid"
                                    //     btnSize="small"
                                    //     onClick={() => handleRedirectInternal(history, 'login')}
                                    // />
                                    // )
                                }
                            </div>
                        </form>
                    ) : null}

                    <CustomDialog
                        title="Confirm bid"
                        open={toggleDialog}
                        function={changeDialogStatus}
                    >
                        <h5>You are about to place a bid!</h5>
                        <h5>Please confirm if you want to continue with this action</h5>
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>Cancel</Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                label="Submit"
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title="Submit a deposit"
                        open={toggleDialogDeposit}
                        function={changeDialogStatusDeposit}
                    >
                        <h4>Deposit has to be placed before placing bid on the product</h4>
                        <h5>Deposit amount : {currencyFormat(product.deposit_amount)}</h5>

                        <div className="row">{Object.values(mapData(modeOfPayment))}</div>

                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialogDeposit(false)}>Cancel</Button>
                            <PrimaryButton
                                onClick={formikPayment.handleSubmit}
                                type="button"
                                label="Submit"
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
