import React, { useReducer } from 'react'
import ZohoContext from './zohoContext'
import ZohoReducer from './zohoReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { CLEAR_RESPONSE, RESPONSE_STATUS } from './zohoTypes'

const ZohoState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(ZohoReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const createTicket = async (formData) => {
        const from = 'createticket'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'custom/index/campaign/zoho'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <ZohoContext.Provider
            value={{
                responseStatus: state.responseStatus,
                clearResponse,
                createTicket,
            }}
        >
            {props.children}
        </ZohoContext.Provider>
    )
}

export default ZohoState
