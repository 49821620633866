import { Button, Fade, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import './ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import Timer from '../../../../custom/common/gridTimer'
import Bidding from '../../molecules/Bidding/BiddingItem'
import Buynow from '../../molecules/Buynow/BuynowItem'
import {
    currencyFormatInt,
    currencyFormatTwo,
    dateFormatFront,
    numberFormatPositive,
} from '../../../common/components'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../../product/context/common/commonContext'
import KlaviyoContext from '../../../../custom/context/klaviyo/klaviyoContext'
import AuthContext from '../../../../product/context/auth/authContext'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import CustomPopover from '../../atoms/Popover'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const { setBidHistoryValue, setTTWBidHistoryValue } = commonContext
    const { user, isAuthenticated } = authContext
    const { updateProfile } = useContext(KlaviyoContext)

    const [product, setProduct] = useState()
    const history = useHistory()
    const [currentBidPrice, setCurrentBidPrice] = useState(0)
    const [totalBidSongShares, setTotalBidSongShares] = useState(0)

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const favTrigger = () => {
        let item = product
        var _learnq = window._learnq || []
        var itemArr = {
            ProductName: item.title,
            ProductID: item.id,
            Categories:
                item.auctiontype === 'live'
                    ? 'Auction'
                    : item.auctiontype === 'buynow'
                    ? 'SongShares'
                    : 'VIP Auction',
            URL: `${global.site_url}/productview/${item.id}/${item.title}`,
            Price: item.auctiontype === 'buynow' ? item.bprice : item.wprice,
        }
        _learnq.push(['track', 'Added to Favorites', itemArr])
        console.log(_learnq, 'trigger', ['track', 'Added to Favorites', itemArr])
        // updateProfile({
        //     email: user.email,
        //     Favorite: item.title,
        // })
    }

    useEffect(() => {
        if (props.action == 'openBids' && product && product.myBids && product.myBids.length) {
            let totalBidPrice = product.myBids.reduce(
                (total, item) => (total = total + item.init_proposed_amount),
                0,
            )
            setTotalBidSongShares(totalBidPrice)
            setCurrentBidPrice(product.myBids[product.myBids.length - 1]?.currentbid_increment)
        }
    }, [product, props.action])

    return (
        <Fade in={true} timeout={600}>
            <div className={`gridCard ${props.newsGrid && 'newsCard'}`}>
                {product ? (
                    <>
                        <div className="pcgImg">
                            {product.market_status !== 'closed' &&
                            product.auctiontype !== 'live' ? (
                                <FavouriteCheckbox
                                    watchlisted={product.watchlistid}
                                    project_id={product.id}
                                    trigger={() => favTrigger()}
                                />
                            ) : null}
                            <img
                                onClick={props.onclick}
                                className="cursorPointer"
                                src={`${global.site_url}/uploads/product/${product.avatar}`}
                                alt={product.title}
                                id={'productimage_' + product.id}
                            />
                            {product?.itemPropsArray?.length ? (
                                <div className="pcgArtist">
                                    <img
                                        src={product?.itemPropsArray[0].artist_image}
                                        alt="Artist image"
                                    />
                                    <span>{product?.itemPropsArray[0].artist}</span>
                                </div>
                            ) : null}
                            {((product.auctiontype === 'buynow' &&
                                window.location.pathname.includes('/won')) ||
                                window.location.pathname.includes('/sold') ||
                                window.location.pathname.includes('/songshares')) && (
                                <div className="prchasedWrapper">
                                    <p>
                                        <b>
                                            {product.totalQty
                                                ? product.totalQty
                                                : product.buynow_qty}
                                        </b>
                                    </p>
                                    <small style={{ lineHeight: '0px' }}>
                                        Units <br />
                                        Purchased
                                    </small>
                                </div>
                            )}
                            {product.market_status === 'open' && product.auctiontype === 'buynow' && (
                                <>
                                    {product.qty - product.sold == '0' && (
                                        <div
                                            className="prchasedWrapper sldWrpr text-center"
                                            id="mybids_soldout"
                                        >
                                            Sold <br /> Out
                                        </div>
                                    )}
                                </>
                            )}
                            {isAuthenticated && !window.location.pathname.includes('/search') && (
                                <>
                                    {(product.bidtopstatus === 'outbid' ||
                                        product.ttwbidtopstatus === 'outbid') && (
                                        <h4
                                            className="productWinningStatus outbid"
                                            id="mybids_outbids"
                                        >
                                            {'OUTBID'}
                                        </h4>
                                    )}

                                    {props.action == 'openBids' &&
                                        (product.bidtopstatus === 'winner' ||
                                            (product.ttwbidtopstatus === 'winner' &&
                                                product.market_status == 'open')) && (
                                            <h4 className="productWinningStatus winning">
                                                {'WINNING'}
                                            </h4>
                                        )}
                                    {props.action != 'openBids' &&
                                        (product.bidtopstatus === 'winner' ||
                                            product.ttwbidtopstatus === 'winner') && (
                                            <h4
                                                className="productWinningStatus winning"
                                                id="mybids_winning"
                                            >
                                                {'WINNING'}
                                            </h4>
                                        )}
                                    {product.bidtopstatus === 'won' && (
                                        <h4 className="productWinningStatus won" id="mybids_won">
                                            {'WON'}
                                        </h4>
                                    )}
                                    {product.bidtopstatus === 'lost' && (
                                        <h4 className="productWinningStatus lost" id="mybids_lost">
                                            {'LOST'}
                                        </h4>
                                    )}
                                </>
                            )}

                            {window.location.pathname.includes('/search') &&
                                product.market_status === 'open' && (
                                    <>
                                        {product.future_active === 1 &&
                                            product.auctiontype !== 'buynow' && (
                                                <h4 className="productWinningStatus cmngSoon">
                                                    Coming Soon
                                                </h4>
                                            )}
                                        {product.future_active === 0 &&
                                            product.auctiontype !== 'buynow' && (
                                                <h4 className="productWinningStatus lost">Live</h4>
                                            )}
                                    </>
                                )}
                        </div>
                        <div className="pdtDtls">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="tmrContainer">
                                    {product.market_status === 'open' ? (
                                        <>
                                            <div className="gridTimer">
                                                {product.auctiontype === 'buynow' ? (
                                                    <>
                                                        {product.qty - product.sold == '0' ? (
                                                            <>
                                                                <p className="m-0">
                                                                    <span className="closedText">
                                                                        Sold Out
                                                                    </span>
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="m-0">
                                                                    {product.auctiontype ===
                                                                        'buynow' &&
                                                                        !product.isPublicVisible &&
                                                                        !product.isPrivateVisible &&
                                                                        window.location.pathname.includes(
                                                                            '/watchlist',
                                                                        ) && (
                                                                            <Timer
                                                                                date_closed={
                                                                                    product.date_closed
                                                                                }
                                                                                withText={1}
                                                                                endText="Ends in: "
                                                                                startText="Starts in: "
                                                                            ></Timer>
                                                                        )}
                                                                    {product.auctiontype ===
                                                                        'buynow' &&
                                                                        product.isPublicVisible && (
                                                                            <Timer
                                                                                date_closed={
                                                                                    product.date_closed
                                                                                }
                                                                                withText={1}
                                                                                endText="Ends in: "
                                                                                startText="Starts in: "
                                                                            ></Timer>
                                                                        )}
                                                                    {product &&
                                                                        product.isPrivateVisible &&
                                                                        !product.isPublicVisible && (
                                                                            <div>
                                                                                {product.market_status ===
                                                                                'open' ? (
                                                                                    <Timer
                                                                                        date_added={
                                                                                            product.date_added
                                                                                        }
                                                                                        date_closed={
                                                                                            product.public_start_date
                                                                                        }
                                                                                        withText={1}
                                                                                        endText="VIP Sale Ends in: "
                                                                                        startText="Starts in: "
                                                                                    ></Timer>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    {product &&
                                                                    !product.isPublicVisible &&
                                                                    !product.isPrivateVisible &&
                                                                    !window.location.pathname.includes(
                                                                        '/sold',
                                                                    ) &&
                                                                    !window.location.pathname.includes(
                                                                        '/won',
                                                                    ) &&
                                                                    !window.location.pathname.includes(
                                                                        '/watchlist',
                                                                    ) &&
                                                                    !window.location.pathname.includes(
                                                                        '/songshares',
                                                                    ) &&
                                                                    !window.location.pathname.includes(
                                                                        '/freeShares',
                                                                    ) ? (
                                                                        <>
                                                                            Starts in:
                                                                            <Timer
                                                                                date_closed={
                                                                                    product.public_start_date
                                                                                }
                                                                                withText={0}
                                                                                endText="Time Left: "
                                                                                startText="Starts in: "
                                                                            ></Timer>
                                                                        </>
                                                                    ) : null}
                                                                    {product.auctiontype ===
                                                                        'buynow' &&
                                                                        window.location.pathname.includes(
                                                                            '/freeShares',
                                                                        ) && (
                                                                            <Timer
                                                                                date_closed={
                                                                                    product.date_closed
                                                                                }
                                                                                withText={1}
                                                                                endText="Ends in: "
                                                                                startText="Starts in: "
                                                                            ></Timer>
                                                                        )}
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <p className="m-0">
                                                        {product.date_closed ? (
                                                            <Timer
                                                                date_added={
                                                                    product.auctiontype != 'buynow'
                                                                        ? product.date_added
                                                                        : ''
                                                                }
                                                                date_closed={product.date_closed}
                                                                withText={1}
                                                                endText={'Ends in' + ':'}
                                                                startText={'Starts in' + ':'}
                                                                isNoSecond={true}
                                                            ></Timer>
                                                        ) : null}
                                                    </p>
                                                )}
                                            </div>
                                        </>
                                    ) : product.market_status === 'sold' &&
                                      product.auctiontype === 'ttw' &&
                                      (product.is_relist === 0 || product.isCommingSoon) ? (
                                        ''
                                    ) : (
                                        <div className="gridTimer closdDt d-flex align-items-center">
                                            <p className="m-0 mr-1">Closed on:</p>
                                            <p className="d-flex align-items-center m-0">
                                                {dateFormatFront(product.date_closed)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                {product.private_sale === 1 ? (
                                    <>
                                        <span
                                            className={`typeChip ${
                                                (product.market_status === 'sold' ||
                                                    product.market_status === 'unsold' ||
                                                    product.market_status === 'closed') &&
                                                (product.is_relist === 0 ||
                                                    product.isCommingSoon) &&
                                                product.auctiontype === 'ttw' &&
                                                'comingSoon'
                                            }`}
                                        >
                                            Private Sale
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            className={`typeChip ${
                                                (product.market_status === 'sold' ||
                                                    product.market_status === 'unsold' ||
                                                    product.market_status === 'closed') &&
                                                (product.is_relist === 0 ||
                                                    product.isCommingSoon) &&
                                                product.auctiontype === 'ttw' &&
                                                'comingSoon'
                                            }`}
                                        >
                                            {(product.market_status === 'sold' ||
                                                product.market_status === 'unsold' ||
                                                product.market_status === 'closed') &&
                                            (product.is_relist === 0 || product.isCommingSoon) &&
                                            product.auctiontype === 'ttw'
                                                ? 'Coming Soon'
                                                : product.auctiontype === 'ttw'
                                                ? 'VIP Auction'
                                                : product.auctiontype === 'buynow'
                                                ? 'Offering'
                                                : 'Auction'}
                                        </span>
                                    </>
                                )}
                            </div>
                            <h2
                                className="pdtName cursorDecoy"
                                onClick={props.onclick}
                                id={'producttitle'}
                            >
                                {product.title}
                            </h2>
                            {/* <Tooltip title={product.short_desc}>
                                <p className="shrtDesc">{product.short_desc}</p>
                            </Tooltip> */}
                            {product.short_desc ? (
                                <CustomPopover
                                    popOverClass="cstmPopOver"
                                    label={<p className="shrtDesc">{product.short_desc}</p>}
                                    content={product.short_desc}
                                />
                            ) : (
                                <p className="shrtDesc">{product.short_desc}</p>
                            )}
                            <>
                                {product.private_sale !== 1 && (
                                    <div className="cstDtls">
                                        {product.market_status === 'open' ? (
                                            <>
                                                {product.auction == 1 &&
                                                product.auctiontype !== 'buynow' ? (
                                                    <>
                                                        {product.auctiontype !== 'live' ? (
                                                            <div className="">
                                                                <p className="m-0">
                                                                    <b>
                                                                        {props.action == 'openBids'
                                                                            ? totalBidSongShares
                                                                                ? totalBidSongShares
                                                                                : product.qty
                                                                            : product.qty}
                                                                    </b>
                                                                </p>
                                                                <small className="m-0">
                                                                    SongShares
                                                                </small>
                                                            </div>
                                                        ) : null}

                                                        <div className="">
                                                            <p className="m-0">
                                                                <b>
                                                                    {/*currencyFormat(
                                                                    product.ttwBidsObject &&
                                                                        Object.keys(
                                                                            product.ttwBidsObject,
                                                                        ).length > 0
                                                                        ? Object.keys(
                                                                              product.ttwBidsObject,
                                                                          )[0]
                                                                        : product.wprice,
                                                                )*/}
                                                                    {console.log(
                                                                        props.action,
                                                                        'propsactuiuiu',
                                                                    )}
                                                                    {currencyFormatTwo(
                                                                        product.auctiontype == 'ttw'
                                                                            ? props.action ==
                                                                              'openBids'
                                                                                ? currentBidPrice
                                                                                    ? currentBidPrice
                                                                                    : product.currentbid_increment
                                                                                : props.action ==
                                                                                  'watchlist'
                                                                                ? product.current_bid
                                                                                : product.currentbid_increment
                                                                            : product.auctiontype ==
                                                                              'live'
                                                                            ? product.wprice
                                                                            : product.ttwBidsObject &&
                                                                              Object.keys(
                                                                                  product.ttwBidsObject,
                                                                              ).length > 0
                                                                            ? Object.keys(
                                                                                  product.ttwBidsObject,
                                                                              )[0]
                                                                            : product.wprice,
                                                                    )}
                                                                </b>
                                                                {/* <span
                                                                    className="cursorDecoy bdCnt ml-2"
                                                                    onClick={() =>
                                                                        product.auctiontype !==
                                                                        'ttw'
                                                                            ? setBidHistoryValue(
                                                                                  product.id,
                                                                              )
                                                                            : setTTWBidHistoryValue(
                                                                                  product.id,
                                                                              )
                                                                    }
                                                                >
                                                                    {product.bid_count} Bids 
                                                                </span> */}
                                                            </p>
                                                            <small className="m-0">
                                                                Current Bid
                                                            </small>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}

                                        {product.auctiontype === 'buynow' &&
                                        product.market_status === 'open' ? (
                                            <>
                                                <div className="">
                                                    <p className="m-0">
                                                        <b>
                                                            {numberFormatPositive(
                                                                product.qty -
                                                                    product.sold -
                                                                    (product.booked > 0
                                                                        ? product.booked
                                                                        : 0),
                                                            )}
                                                        </b>
                                                    </p>
                                                    <small className="m-0">SongShares</small>
                                                </div>
                                                {product.freeShare > 0 ? (
                                                    <div className="invtOly">Invite Only</div>
                                                ) : (
                                                    <div className="">
                                                        <p className="m-0">
                                                            <b>
                                                                {product.bprice
                                                                    ? currencyFormatInt(
                                                                          product.bprice,
                                                                      )
                                                                    : currencyFormatInt(
                                                                          product.sprice,
                                                                      )}
                                                            </b>
                                                        </p>
                                                        <small className="m-0">Price</small>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                                {props.action == 'won' && (
                                    <>
                                        <div className="actionAfterWin bdWnDetails m-0 mt-2">
                                            {product &&
                                                product.myBids &&
                                                product.myBids.map((bids, index) => (
                                                    <>
                                                        {index === 0 && (
                                                            <div className="row ttlCard mx-0">
                                                                <div className="col-md-4 px-1">
                                                                    <i>Reserved</i>
                                                                </div>
                                                                <div className="col-md-4 px-1">
                                                                    <i>Units</i>
                                                                </div>
                                                                <div className="col-md-4 px-1">
                                                                    <i>Total</i>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {bids.proposed_amount > 0 && (
                                                            <>
                                                                <div
                                                                    className="row mx-0"
                                                                    key={index + 'wonItem'}
                                                                >
                                                                    <div className="col-md-4 px-1">
                                                                        {bids.proposed_amount}
                                                                    </div>
                                                                    <div className="col-md-4 px-1">
                                                                        {currencyFormatInt(
                                                                            bids.currentbid_increment,
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-4 px-1">
                                                                        {currencyFormatInt(
                                                                            bids.currentbid_increment *
                                                                                bids.proposed_amount,
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ))}
                                        </div>
                                    </>
                                )}
                                {/* {props.action == 'won' && (
                                    <>
                                        <div className="actionAfterWin m-0 mt-2">
                                            <PrimaryButton
                                                label="Submit Rating"
                                                onClick={props.ratingClick}
                                            />
                                        </div>
                                    </>
                                )} */}
                            </>
                        </div>
                    </>
                ) : null}
            </div>
        </Fade>
    )
}

export default Grid
