import React, { useReducer } from 'react'
import PlaidContext from './plaidContext'
import PlaidReducer from './plaidReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import { CLEAR_RESPONSE, RESPONSE_STATUS } from './plaidTypes'

const PlaidState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(PlaidReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const plaidLinkTokenCreate = async (formData) => {
        const from = 'plaidLinkTokenCreate'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'linkToTokenCreate', formData, '', 'paymentThird/plaid'),
            ])
            // console.log(res)
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const dwalloConnect = async (formData) => {
        const from = 'dwalloConnect'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'dwollaConnect', formData, '', 'paymentThird/plaid'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <PlaidContext.Provider
            value={{
                responseStatus: state.responseStatus,
                plaidLinkTokenCreate,
                dwalloConnect,
                clearResponse,
            }}
        >
            {props.children}
        </PlaidContext.Provider>
    )
}

export default PlaidState
