import React, { useContext, useState, useEffect } from 'react'
import CustomDialog from '../../custom/components/organisms/Dialog'
import Button from '@material-ui/core/Button'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import UserContext from '../../product/context/user/userContext'
import AuthContext from '../../product/context/auth/authContext'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { mapData, handleRedirectInternal } from '../../product/common/components'

export default function emailValidation(props) {
    const history = useHistory()
    const emailVerify = props.emailVerify
    const changeEmailVerify = props.changeEmailVerify
    const formik = props.formik
    const setEmailVerify = props.setEmailVerify

    const { loadUser } = useContext(AuthContext)
    const userContext = useContext(UserContext)

    const {
        sendEmailVerifyCode,
        verifyEmailVerifyCode,
        responseStatus: responseStatusUser,
    } = userContext

    const sendVerifyCode = () => {
        sendEmailVerifyCode({ email: formik.values.email })
    }

    const sendEmailVerify = () => {
        verifyEmailVerifyCode({
            email: formik.values.email,
            verify_code: Number(formik.values.verify_code),
            temp_user_id: localStorage.temp_user_id,
        })
    }

    const verifyEmail = [
        {
            label: 'Verification Code',
            placeholder: 'Enter Verification Code',
            class: 'col-12',
            type: 'number',
            name: 'verify_code',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'sendEmailVerifyCode' &&
                responseStatusUser.status === 'success'
            ) {
                formik.values.email_verifysent = 1
            }
            if (
                responseStatusUser.from === 'verifyEmailVerifyCode' &&
                responseStatusUser.status === 'success'
            ) {
                formik.values.email_verified = 1
                formik.values.verified_email = formik.values.email
                loadUser()
                if (localStorage.temp_user_id) {
                    handleRedirectInternal(history, 'buynowcart')
                    localStorage.removeItem('temp_user_id')
                }
                setEmailVerify(false)
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        if (props.emailVerify && emailVerify) {
            console.log('code send successfully!!!!!!!!!!')
            sendVerifyCode()
        }
    }, [props.emailVerify])

    return (
        <>
            <RegistrationLayout>
                <div className="loginWrapper p-5 h-100">
                    <div className="rdrLnk text-right">
                        Don&apos;t have an account?
                        <Link className="ml-2" to="/register">
                            Register!
                        </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <form className="loginForm optForm mb-0" onSubmit={formik.handleSubmit}>
                            <h3 className="mb-3">Authenticate Your Account</h3>
                            <p>
                                Protecting your information is our top priority. Please confirm your
                                account by entering the authorization code sent to &nbsp;
                                <b>
                                    {formik.values.email.slice(0, 1) +
                                        '*************@' +
                                        formik.values.email.split('@')[1].slice(0, 1) +
                                        '***.' +
                                        formik.values.email.split('@')[1].split('.')[1]}
                                </b>
                            </p>
                            {formik.values.email_verifysent === 1 && (
                                <>
                                    <div className="row mt-5">
                                        <div className="col-md-12 otpBxwpr">
                                            <span className="inptBxWrpr"></span>
                                            <span className="inptBxWrpr"></span>
                                            <span className="inptBxWrpr"></span>
                                            <span className="inptBxWrpr"></span>
                                            <span className="inptBxWrpr"></span>
                                            <span className="inptBxWrpr"></span>
                                            <span className="inptBxWrpr ltBox"></span>
                                            <input
                                                maxLength={6}
                                                className="otpInpt"
                                                name="verify_code"
                                                onChange={formik.handleChange}
                                                value={formik.values.verify_code}
                                            />
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <small className="text-danger">
                                                * Your Code will expire in 5 minutes
                                            </small>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <PrimaryButton
                                            onClick={sendEmailVerify}
                                            label="Verify"
                                            type="button"
                                        />
                                    </div>
                                </>
                            )}
                            {formik.values.email_verifysent === 1 ? (
                                <p className="rsndTxt mt-2">
                                    It may take a minute to receive your code. Haven’t received yet?{' '}
                                    <span className="cursorDecoy" onClick={() => sendVerifyCode()}>
                                        Resend
                                    </span>
                                </p>
                            ) : (
                                <p className="rsndTxt mt-2">
                                    Verification Code Sent Successfully!{' '}
                                    <span className="cursorDecoy" onClick={() => sendVerifyCode()}>
                                        Send Again
                                    </span>
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </RegistrationLayout>
            {/* <CustomDialog
                className="verifyPhone"
                title={'Verify Email'}
                open={emailVerify}
                onClose={changeEmailVerify}
            >
                Verify Email number : {formik.values.Email}
                <br />
                {formik.values.email_verifysent === 1 && (
                    <>
                        <br />
                        <div className="row phVerInp">{Object.values(mapData(verifyEmail))}</div>
                        <span className="phVer">* Your Code will expire in 5 minutes</span>
                        <br />
                        <br />
                        <PrimaryButton onClick={sendEmailVerify} label="VERIFY CODE" />
                    </>
                )}
                <div className="actionWrapper">
                    <Button onClick={() => changeEmailVerify()}>Cancel</Button>
                    {formik.values.email_verifysent === 1 ? (
                        <Button className="resendSms" onClick={() => sendVerifyCode()}>
                            Resend
                        </Button>
                    ) : (
                        <PrimaryButton label="Send Email" onClick={() => sendVerifyCode()} />
                    )}
                </div>
            </CustomDialog> */}
        </>
    )
}
