import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AuthContext from '../../../../product/context/auth/authContext'
import PayContext from '../../../../product/context/payment/payContext'
import CreditContext from '../../../../product/context/credit/creditContext'
import KlaviyoContext from '../../../../custom/context/klaviyo/klaviyoContext'

import ProductContext from '../../../../product/context/product/productContext'
import CommonContext from '../../../../product/context/common/commonContext'

import CustomDialog from '../../organisms/Dialog'
import {
    handleRedirectInternal,
    currencyFormat,
    currencyFormatInt,
    currencyFormatTwo,
} from '../../../common/components'
import { mapData } from '../../../../product/common/components'
import StripeCardContext from '../../../../product/context/stripe/card/cardContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import UserContext from '../../../../product/context/user/userContext'
import { v4 as uuidv4 } from 'uuid'
import { LensTwoTone } from '@material-ui/icons'
let randomVariable = Math.random()
const TTWBidding = (props) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const payContext = useContext(PayContext)
    const creditContext = useContext(CreditContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { updateProfile, responseStatus: responseStatusUser } = useContext(UserContext)

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const { searchValue } = commonContext
    const { getAllSearchProducts } = productContext

    const { payCartRosoom } = payContext
    const { addBidDeposit, responseStatus: responseStatusCredit } = creditContext

    const { updateProfile: updateProfileKlaviyo } = useContext(KlaviyoContext)

    let {
        ttwBidConfirm,
        ttwBidTemp,
        responseStatus: responseStatusBid,
        clearResponse: clearResponseStatusBid,
    } = buyerContext
    const { user, isAuthenticated, loadUser } = authContext
    const [product, setProduct] = React.useState()
    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const [cards, setCards] = useState([])
    const [isProxyAlive, setProxyLive] = useState(false)
    const [bidTemp, setBidTemp] = useState(false)
    const [totalBidPrice, setTotalBidPrice] = useState(0)
    const [qtyttwAdded, setQtyttwAdded] = useState(false)

    const {
        getExternalAccount,
        north_capital_bank_details,
        northCapitalUser,
        addCampaignContact,
    } = useContext(NorthCapitalContext)

    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
    } = useContext(StripeCardContext)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const changeTempDialogStatus = () => {
        setBidTemp(!bidTemp)
    }

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    const [canadianUser, setCanadianUser] = useState(false)

    useEffect(() => {
        if (responseStatusCredit) {
            if (responseStatusCredit.from === 'addBidDeposit') {
                if (responseStatusCredit.status === 'success') {
                    setToggleDialogDeposit(false)
                    getAllSearchProducts(searchValue)
                }
            }
        }
    }, [responseStatusCredit])

    useEffect(() => {
        if (responseStatusBid) {
            console.log(responseStatusBid, 'responseStatusBid')
            if (responseStatusBid.from === 'bidTemp') {
                if (responseStatusBid.status === 'success') {
                    setAlert(responseStatusBid.message, responseStatusBid.status)
                    setToggleDialog(false)
                    setBidTemp(true)
                } else {
                    setAlert(responseStatusBid.message, 'error')
                    localStorage.removeItem('ttw_temp_user_id')
                    localStorage.removeItem('itemId')
                    localStorage.removeItem('itemTitle')
                    localStorage.removeItem('share')
                }
            }
            clearResponseStatusBid()
        }
    }, [responseStatusBid])

    useEffect(() => {
        console.log(product, props.paramqty, 'product.m1234566')
        if (
            props.paramqty &&
            product?.auctiontype == 'ttw' &&
            props.type == 'hard' &&
            !qtyttwAdded
        ) {
            console.log(product, 'product.m')
            let qtyadded = ''
            let finalTotalQty = product.qty
            if (product && typeof product.qty !== undefined) {
                if (product.qty != product.masterQty) {
                    let totalQty = product.qty - product.masterQty
                    finalTotalQty = totalQty
                    console.log(totalQty, 'what is qty')
                    if (parseInt(props.paramqty) && parseInt(props.paramqty) <= totalQty) {
                        console.log(
                            parseInt(props.paramqty),
                            product.masterQty,
                            'product.masterQty',
                        )
                        formik.values.proposed_amount = parseInt(props.paramqty)
                        qtyadded = parseInt(props.paramqty)
                    }
                } else if (parseInt(props.paramqty) <= product.qty) {
                    console.log(
                        parseInt(props.paramqty),
                        product.masterQty,
                        'product.masterQty23445',
                    )
                    formik.values.proposed_amount = parseInt(props.paramqty)
                    qtyadded = parseInt(props.paramqty)
                } else {
                    qtyadded = null
                }
            }
            if (qtyadded && qtyadded !== '') {
                if (isAuthenticated) {
                    console.log('coming now', formik.values.proposed_amount, qtyadded)
                    formik.setFieldValue('proposed_amount', qtyadded)
                    setQtyttwAdded(true)
                    formik.handleSubmit()
                } else {
                    const id = uuidv4()
                    console.log(
                        {
                            ttw_temp_user_id: id,
                            units: parseInt(props.paramqty),
                            project_id: product.id,
                            ip: localStorage.ipaddress,
                        },
                        'tembid values',
                    )
                    ttwBidTemp({
                        ttw_temp_user_id: id,
                        units: parseInt(props.paramqty),
                        project_id: product.id,
                        ip: localStorage.ipaddress,
                        autopurchase: formik?.values.autopurchase ? 1 : 0,
                        current_price: product.currentbid_increment,
                    })
                    setQtyttwAdded(true)
                    localStorage.setItem('ttw_temp_user_id', id)
                    localStorage.setItem('itemId', product.id)
                    localStorage.setItem('current_bid', product.currentbid_increment)
                    localStorage.setItem('autopurchase', product.autopurchase)
                    localStorage.setItem('itemTitle', props.data.title || '')
                    localStorage.setItem('share', parseInt(props.paramqty))
                }
            } else {
                setAlert(`Max Shares ${finalTotalQty}`, 'error')
                setQtyttwAdded(false)
            }
        }
    }, [props.paramqty, product, qtyttwAdded])

    useEffect(() => {
        setProduct(props.data)
        console.log(props.data, 'propsdata')
        if (props.data?.custom_bid_data?.length) {
            props.data?.custom_bid_data?.map((item) => {
                if (item.user_id === user.id) {
                    formik.values.approved = item.autopurchase
                }
            })
        }
    }, [props.data])

    useEffect(() => {
        let bidPrice = props.totalBidPrice && !isNaN(props.totalBidPrice) ? props.totalBidPrice : 0
        setTotalBidPrice(bidPrice)
    }, [props.totalBidPrice])

    useEffect(() => {
        if (user && user.country == 'CA') {
            setCanadianUser(true)
        }
    }, [user])

    const validationArray = Yup.object({
        proposed_amount: Yup.number().when('bidding_type', {
            is: 'proxy',
            then: Yup.number()
                // .integer("Can't include a decimal point")
                .min(
                    product
                        ? product.proxy_bid_details &&
                          product.proxy_bid_details.length &&
                          product.proxy_bid_details[0].maxamount
                            ? product.proxy_bid_details[0].maxamount + 1
                            : product.next_currentbid_increment
                            ? product.next_currentbid_increment
                            : 0
                        : 0,
                    `Min Price $${
                        product
                            ? product.proxy_bid_details.length &&
                              product.proxy_bid_details[0].maxamount
                                ? product.proxy_bid_details[0].maxamount + 1
                                : product.next_currentbid_increment
                                ? product.next_currentbid_increment
                                : 0
                            : 0
                    }`,
                )
                .max(10000, `Max Price 10000`)
                .required('Enter amount'),
            otherwise: Yup.number()
                .min(1, `Min Share 1`)
                // .integer("Can't include a decimal point")
                .max(
                    product && typeof product.qty !== undefined
                        ? product.qty != product.masterQty
                            ? product.qty - product.masterQty
                            : product.qty
                        : 100,
                    `Max Shares ${
                        product && typeof product.qty !== undefined
                            ? product.qty != product.masterQty
                                ? product.qty - product.masterQty
                                : product.qty
                            : 100
                    }`,
                )
                .required('Enter # of Shares'),
        }),

        bidding_type: Yup.string(),
        proposed_qty: Yup.number()
            .integer("Can't include a decimal point")
            .when('bidding_type', {
                is: 'proxy',
                then: Yup.number()
                    .min(1, `Min Unit 1`)
                    .max(
                        product && typeof product.qty !== undefined ? product.qty : 100,
                        `Max Units ${
                            product && typeof product.qty !== undefined ? product.qty : 0
                        }`,
                    )
                    .required('Enter Unit'),
            }),
    })

    const validationPayment = Yup.object({
        payment_method: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            proposed_amount: '',
            id: props.data.id,
            bidding_type: '',
            proposed_qty: '',
            autopurchase: 1,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (!isAuthenticated && product.auctiontype == 'ttw') {
                // if (toggleDialog) {
                if (localStorage.ttw_temp_user_id) {
                    console.log('coming here only12345')
                    ttwBidTemp({
                        ttw_temp_user_id: localStorage.ttw_temp_user_id,
                        units: values.proposed_amount,
                        project_id: values.id,
                        ip: localStorage.ipaddress,
                        autopurchase: values.autopurchase ? 1 : 0,
                        current_price: product?.currentbid_increment
                            ? product?.currentbid_increment
                            : 0,
                    })

                    localStorage.setItem('ttw_temp_user_id', localStorage.ttw_temp_user_id)
                    localStorage.setItem('itemId', values.id)
                    localStorage.setItem(
                        'current_bid',
                        product?.currentbid_increment ? product?.currentbid_increment : 0,
                    )
                    localStorage.setItem('autopurchase', values.autopurchase ? 1 : 0)
                    localStorage.setItem('itemTitle', props.data.title || '')
                    localStorage.setItem('share', values.proposed_amount)
                } else {
                    let id = uuidv4()
                    console.log('coming here only')
                    console.log(
                        {
                            ttw_temp_user_id: id,
                            units: values.proposed_amount,
                            project_id: values.id,
                            ip: localStorage.ipaddress,
                            autopurchase: values.autopurchase ? 1 : 0,
                            current_price: product?.currentbid_increment
                                ? product?.currentbid_increment
                                : 0,
                        },
                        'tembid values',
                    )
                    ttwBidTemp({
                        ttw_temp_user_id: id,
                        units: values.proposed_amount,
                        project_id: values.id,
                        ip: localStorage.ipaddress,
                        autopurchase: values.autopurchase ? 1 : 0,
                        current_price: product?.currentbid_increment
                            ? product?.currentbid_increment
                            : 0,
                    })
                    localStorage.setItem('ttw_temp_user_id', id)
                    localStorage.setItem('itemId', values.id)
                    localStorage.setItem(
                        'current_bid',
                        product?.currentbid_increment ? product?.currentbid_increment : 0,
                    )
                    localStorage.setItem('autopurchase', values.autopurchase ? 1 : 0)
                    localStorage.setItem('itemTitle', props.data.title || '')
                    localStorage.setItem('share', parseInt(values.proposed_amount))
                }
                // }
                // else {
                //     setToggleDialog(true)
                // }
            } else {
                console.log(values, 'values before popup')
                if (!user.mail_verified) {
                    setAlert('Please verify your email', 'error')
                    handleRedirectInternal(history, 'account/profile')
                }
                // we are not checking this condition any more
                /*  else if (northCapitalUser) {
                if (
                    northCapitalUser.isDocumentUploaded !== 1 &&
                    ((user.domesticYN !== 'domestic_account' && user.user_type == 0) ||
                        user.user_type == 1)
                ) {
                    if (user.is_document_later === 0) {
                        handleRedirectInternal(history, 'registration/uploadDocument/1')
                    } else {
                        setAlert(
                            'Your account has not been verified. Please visit your Settings to upload a passport or photo ID before completing this purchase.',
                            'error',
                        )
                    }
                    return false
                }
            } */

                // if (cards && cards.length) {
                if (user.mail_verified) {
                    if (product.auctiontype == 'ttw') {
                        let proposed_amount_tried =
                            (values.bidding_type == 'proxy'
                                ? values.proposed_qty
                                : product.currentbid_increment) * values.proposed_amount
                        proposed_amount_tried += totalBidPrice

                        if (user.is_maxbid_requested === 1) {
                            setAlert('Your bid limit request is being reviewed.', 'error')
                            return false
                        } else if (proposed_amount_tried > user.max_bid) {
                            setToggleDialogBidLimit(true)
                            return false
                        }
                    }

                    console.log('sdffsdfsdsfdfdsfsdsfd', values)

                    if (product.deposit && !product.bid_deposit_id) {
                        setToggleDialogDeposit(true)
                    } else if (toggleDialog) {
                        console.log(values, 'values after 123 popup')
                        if (values.bidding_type == 'hard') {
                            let item = product
                            var _learnq = window._learnq || []
                            var itemArr = {
                                ProductName: item.title,
                                ProductID: item.id,
                                Categories: 'VIP Auction',
                                URL: window.location.href,
                                Price: product.currentbid_increment * values.proposed_amount,
                            }
                            _learnq.push(['track', 'VIP Auction Bid', itemArr])
                            console.log(_learnq, ['track', 'VIP Auction Bid', itemArr])
                            // updateProfileKlaviyo({
                            //     email: user.email,
                            //     'Last Bid Item': item.title,
                            // })
                        }
                        values.customBidData = {
                            autopurchase: values.autopurchase,
                        }
                        console.log(values, 'formik values')
                        ttwBidConfirm(values)
                        setToggleDialog(false)
                        formik.values.proposed_amount = ''
                        formik.handleReset()
                        if (user && user.isFirstBidComplete !== 1) {
                            addCampaignContact(
                                {
                                    email: user.email,
                                    utm_source: localStorage.getItem('utm_source') || '',
                                    utm_medium: localStorage.getItem('utm_medium') || '',
                                    utm_campaign: localStorage.getItem('utm_campaign') || '',
                                },
                                'Bidding',
                                true,
                                true,
                            )
                            updateProfile(
                                {
                                    isFirstBidComplete: 1,
                                },
                                true,
                            )
                            loadUser()
                        }
                    } else {
                        console.log(values, 'values before 123 popup')
                        setToggleDialog(true)
                    }
                }
                // } else {
                //     if (user.reg_step_count < 3) handleRedirectInternal(history, 'registration/step')
                //     else handleRedirectInternal(history, 'payment/transaction')
                // }
            }
        },
    })

    useEffect(() => {
        if (Object.keys(formik.errors).length && formik.errors['proposed_amount']) {
            setToggleDialog(false)
        }
    }, [formik.errors])

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    const formikPayment = useFormik({
        initialValues: {
            cart_id: props.data.id,
            pay_type: 'bid_deposit',
            payment_method: '',
        },
        validationSchema: validationPayment,
        onSubmit: (values) => {
            formikPayment.setSubmitting(true)
            if (values.payment_method === 'wallet') {
                let formData = {
                    amount: product.deposit_amount,
                    product_id: values.cart_id,
                    credit_notes: 'Bid Deposit',
                    credit_type: 'user',
                    type: 'projects',
                    creditor_id: user.id,
                }
                addBidDeposit(formData)
            } else if (values.payment_method === 'online') {
                let formData = {
                    cart_id: values.cart_id,
                    payment_object: {
                        type: 'projects',
                        amount: 0,
                    },
                    pay_type: values.pay_type,
                }
                payCartRosoom(formData)
            }
        },
    })

    const bidAmount = [
        {
            // label: 'Enter bid amount',
            name: 'proposed_amount',
            type: 'number',
            // placeholder: `Max Units ${
            //     product && typeof product.masterQty !== undefined
            //         ? product.qty - product.masterQty
            //         : 0
            //     }`,
            placeholder: 'Enter # of Shares',
            class: '',
            // size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    const proxyBidAmount = [
        {
            label: 'Quantity of Shares',
            name: 'proposed_qty',
            type: 'number',
            placeholder: `Max Shares ${
                product && typeof product.qty !== undefined ? product.qty : 0
            }`,
            class: 'col-md-8 col-12 pl-0 pr-1',
            autoFocus: false,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Price',
            name: 'proposed_amount',
            type: 'number',
            placeholder: `Min $${
                product
                    ? product.proxy_bid_details.length && product.proxy_bid_details[0].maxamount
                        ? parseFloat(product.proxy_bid_details[0].maxamount + 1).toFixed(2)
                        : product.next_currentbid_increment
                        ? parseFloat(product.next_currentbid_increment).toFixed(2)
                        : 0
                    : 0
            }`,
            class: 'col-md-4 col-12 pl-1 pr-0',
            autoFocus: false,
            formik: formik,
            upperLabel: true,
        },
    ]
    const modeOfPayment = [
        {
            label: 'Select mode of payment',
            placeholder: 'Select mode of payment',
            type: 'select',
            class: 'col-12',
            options: [
                { value: 'online', show: 'Online' },
                { value: 'wallet', show: 'Wallet' },
            ],
            name: 'payment_method',
            formik: formikPayment,
        },
    ]

    const autoPayData = [
        {
            label: 'Auto Purchase is Active',
            name: 'autopurchase',
            type: 'checkbox',
            class: `col-12`,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (get_all_card_details) {
            setCards(get_all_card_details.records)
        }
    }, [get_all_card_details])

    useEffect(() => {
        if (north_capital_bank_details) {
            if (north_capital_bank_details.data.statusDesc) {
                setCards([north_capital_bank_details.data.statusDesc])
            }
        }
    }, [north_capital_bank_details])

    useEffect(() => {
        if (product && user) {
            if (formik.values.bidding_type === 'proxy') {
                if (product.proxy_bid_details.users.indexOf(user.id) > -1) {
                    if (product && product.proxy_bid_details) {
                        let bidder =
                            product.proxy_bid_details.bids[
                                Object.keys(product.proxy_bid_details.bids)[0]
                            ]
                        bidder = bidder.length && bidder.filter((data) => data.user_id === user.id)
                        if (bidder.length && bidder[0].maxamount >= product.currentbid_increment) {
                            formik.setFieldValue('proposed_qty', bidder[0].maxqty)
                            formik.setFieldValue('proposed_amount', bidder[0].maxamount)
                            // formik.resetForm()
                            setProxyLive(true)
                        }
                    }
                } else {
                    formik.setFieldValue('proposed_qty', '')
                    formik.setFieldValue('proposed_amount', '')
                    setProxyLive(false)
                }
            } else {
                formik.setFieldValue('proposed_qty', '')
                if (!props.paramqty) {
                    formik.setFieldValue('proposed_amount', '')
                }
            }
            if (
                product &&
                product.proxy_bid_details &&
                product.proxy_bid_details.users.indexOf(user.id) > -1
            ) {
                let bidder =
                    product.proxy_bid_details.bids[Object.keys(product.proxy_bid_details.bids)[0]]
                bidder = bidder.length && bidder.filter((data) => data.user_id === user.id)
                if (bidder.length && bidder[0].maxamount < product.currentbid_increment) {
                    formik.resetForm()
                }
            }
        }
    }, [product, props.type])

    const getStripeCards = () => {
        getAllStripeCards({
            account_id: 'acct_1Iq9B5PG1bvmzFNY',
            customer_id: user.stripe_id,
            object: 'card',
        })
    }

    const [isMaxBidRequested, setIsMaxBidRequested] = useState(false)
    const [toggleDialogBidLimit, setToggleDialogBidLimit] = useState(false)

    const validationMaxBid = Yup.object({
        limit_range: Yup.number()
            .positive('Should be grater than 1')
            .min(user?.max_bid + 1, 'Must be greater than ' + user?.max_bid)
            .integer("Can't be a decimal")
            .required('Required!'),
    })

    const maxBidFormik = useFormik({
        initialValues: {
            limit_range: '',
        },
        validationSchema: validationMaxBid,
        onSubmit: async (values) => {
            setIsMaxBidRequested(true)
            await updateProfile(
                {
                    is_maxbid_requested: 1,
                    limit_range: values.limit_range,
                },
                true,
            )
        },
    })

    const maxBidRequest = [
        {
            // label: 'Enter limit',
            placeholder: 'Enter max limit',
            type: 'number',
            name: 'limit_range',
            class: 'col-6 mb-3',
            formik: maxBidFormik,
        },
    ]

    const changeDialogStatusBidLimit = () => {
        setToggleDialogBidLimit(!toggleDialogBidLimit)
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                    if (isMaxBidRequested) {
                        setAlert(
                            'Your bid limit request has been sent.  Please wait for admin approval.',
                            'success',
                        )
                        maxBidFormik.values.limit_range = ''
                        setIsMaxBidRequested(false)
                    }
                } else {
                    setIsMaxBidRequested(false)
                }
                setToggleDialogBidLimit(false)
            }
        }
    }, [responseStatusUser])

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex justify-content-between align-items-start">
                                <div className="bidInput d-flex align-items-start flex-wrap">
                                    {props.type === 'proxy'
                                        ? Object.values(mapData(proxyBidAmount))
                                        : Object.values(mapData(bidAmount))}
                                    {props.type == 'hard' && props.suffix && (
                                        <div className="suffixWrapper px-3">
                                            Shares @{' '}
                                            {currencyFormatInt(product.currentbid_increment)}
                                        </div>
                                    )}
                                    {formik.values.proposed_amount &&
                                    formik.values.bidding_type === 'proxy' ? (
                                        <p className="crntBdValue m-0 pt-1 mb-3 col-md-12 text-left">
                                            {formik.values.proposed_qty} Units @{' '}
                                            {currencyFormatTwo(formik.values.proposed_amount)} ={' '}
                                            {currencyFormatTwo(
                                                formik.values.proposed_amount *
                                                    formik.values.proposed_qty,
                                            )}{' '}
                                            Max Bid
                                        </p>
                                    ) : null}
                                </div>

                                {isAuthenticated ? (
                                    product.bidtopstatus === 'winner' ? (
                                        <SecondaryButton
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? 'Increase hard bid'
                                                    : 'Increase proxy bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                        />
                                    ) : formik.values.bidding_type === 'hard' ? (
                                        <PrimaryButton
                                            className="reserve_button"
                                            label={'Reserve Shares'}
                                            type="submit"
                                            btnSize="small"
                                            disabled={canadianUser ? true : false}
                                        />
                                    ) : (
                                        <SecondaryButton
                                            label={
                                                <>
                                                    <span className="iconWrapr">
                                                        <img src="/assets/svg/proxyicon.svg" />
                                                    </span>
                                                    <span className="w-100 text-center">
                                                        {isProxyAlive ? 'Edit' : 'Place'} proxy bid
                                                    </span>
                                                </>
                                            }
                                            type="submit"
                                            btnSize="small"
                                            disabled={canadianUser ? true : false}
                                        />
                                    )
                                ) : // <PrimaryButton
                                //     label="Login to bid"
                                //     btnSize="small"
                                //     onClick={() => handleRedirectInternal(history, 'login')}
                                // />
                                formik.values.bidding_type === 'hard' ? (
                                    <PrimaryButton
                                        className="reserve_button"
                                        label={'Reserve Shares'}
                                        type="submit"
                                        btnSize="small"
                                        // disabled={localStorage.ttw_temp_user_id ? true : false}
                                    />
                                ) : (
                                    <SecondaryButton
                                        label={
                                            <>
                                                <span className="iconWrapr">
                                                    <img src="/assets/svg/proxyicon.svg" />
                                                </span>
                                                <span className="w-100 text-center">
                                                    {isProxyAlive ? 'Edit' : 'Place'} proxy bid
                                                </span>
                                            </>
                                        }
                                        type="submit"
                                        btnSize="small"
                                        // disabled={canadianUser ? true : false}
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}
                    {formik.values.proposed_amount &&
                    formik.values.bidding_type === 'hard' &&
                    props.auctiontype &&
                    props.auctiontype != 'ttw' ? (
                        <>
                            <p className="crntBdValue m-0 pt-1 mt-1">
                                {formik.values.proposed_amount} @{' '}
                                {currencyFormat(product.currentbid_increment)} ={' '}
                                {currencyFormat(
                                    formik.values.proposed_amount * product.currentbid_increment,
                                )}
                            </p>
                            <div className="ryltsDtlsWrpr">
                                <div className="rylInrWrpr">
                                    <p>Amount To Be Paid</p>
                                    <span>
                                        {currencyFormat(
                                            formik.values.proposed_amount *
                                                product.currentbid_increment,
                                        )}
                                    </span>
                                </div>
                                <div className="rylInrWrpr">
                                    <p>Last 12 Months Paid</p>
                                    <span>
                                        {currencyFormat(
                                            formik.values.proposed_amount *
                                                parseFloat(
                                                    product.annual_royalties / product.qty,
                                                ).toFixed(2),
                                        )}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : null}
                    <CustomDialog title="" open={toggleDialog} function={changeDialogStatus}>
                        <div className="bidCnfModalCnt">
                            <h4> Confirm Bid To Reserve Shares </h4>
                            <h5>
                                You are about to place a bid to reserve shares. This is only a
                                reservation, allowing you to purchase shares when they go on sale
                                (subject to SEC qualification). You must continue to complete the
                                reservation process.
                                <br />
                                <br />
                            </h5>
                        </div>
                        <div className="bidCnfModalCnt">
                            <h4> Auto-Purchase Option </h4>
                            <h5>
                                Auto-purchase makes buying your SongShares as easy as possible.
                                Checking this box automatically purchases your reserved shares,
                                using your preferred payment method, when they go on sale. This can
                                be changed in your dashboard at any time.
                                <br />
                                {/* <br />
                                It can always turn this option off in your dashboard or just uncheck
                                the box below */}
                            </h5>
                            <div className="row">{Object.values(mapData(autoPayData))}</div>
                        </div>
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>Cancel</Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                label="Submit"
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title="Submit a deposit"
                        open={toggleDialogDeposit}
                        function={changeDialogStatusDeposit}
                    >
                        <h4>Deposit has to be placed before placing bid on the product</h4>
                        <h5>Deposit amount: {currencyFormat(product.deposit_amount)}</h5>

                        <div className="row">{Object.values(mapData(modeOfPayment))}</div>

                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialogDeposit(false)}>Cancel</Button>
                            <PrimaryButton
                                onClick={formikPayment.handleSubmit}
                                type="button"
                                label="Submit"
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title="Increase Bid Limit!"
                        open={toggleDialogBidLimit}
                        function={changeDialogStatusBidLimit}
                    >
                        {/*<h5>Increase Bid Limit!</h5>*/}
                        <h5>
                            You are attempting to reserve quantities of shares that are valued above
                            the current limit for your account. If you&apos;d like to request an
                            increase to your limit, please enter in the amount you&apos;d like to
                            increase the limit to below
                        </h5>
                        <form
                            onSubmit={maxBidFormik.handleSubmit}
                            autoComplete="nofill"
                            className="row pt-3 setmxBdForm"
                        >
                            {mapData(maxBidRequest)}
                        </form>
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialogBidLimit(false)}>Cancel</Button>
                            <PrimaryButton
                                onClick={maxBidFormik.handleSubmit}
                                type="button"
                                label="Submit"
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title=""
                        open={bidTemp}
                        function={changeTempDialogStatus}
                        disableBackdropClick
                    >
                        <div className="bidCnfModalCnt">
                            <h4> Register or Login to Complete Reservation </h4>
                            <h5>
                                To finalize your bid and reserve shares, register or login.
                                <br />
                            </h5>
                        </div>
                        <div className="actionWrapper">
                            <Button
                                onClick={() => {
                                    handleRedirectInternal(history, 'login')
                                    setBidTemp(false)
                                }}
                            >
                                LOGIN
                            </Button>
                            <PrimaryButton
                                onClick={() => {
                                    handleRedirectInternal(history, 'register')
                                    setBidTemp(false)
                                }}
                                type="button"
                                label="REGISTER"
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default TTWBidding
