import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import RadioBox from '../../product/components/atoms/RadioBox'
import { useFormik, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { mapData, calculateAge, handleRedirectInternal } from '../../product/common/components'
// import PhoneValidation from './phoneValidation'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'
import { FilterFramesOutlined } from '@material-ui/icons'
import { Document } from 'react-pdf'
import { LOGO } from '../../Utils'
import { parse, isDate } from 'date-fns'
import StripeCustomerContext from '../../product/context/stripe/customer/customerContext'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import KlaviyoContext from '../../custom/context/klaviyo/klaviyoContext'
import AlertContext from '../../product/context/alert/alertContext'
import SettingContext from '../../product/context/seller/setting/settingContext'

import Tooltip from '@material-ui/core/Tooltip'
import Loaders from '../../custom/components/molecules/Loaders'
import CustomPopover from '../../custom/components/atoms/Popover'
import { el } from 'date-fns/locale'
import InitialPopup from './InitialPopup'
import Cryptos from '../../product/common/cryptos'

let randomVariable = Math.random()

const Record = (props) => {
    const authContext = useContext(AuthContext)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isSSN, setSSN] = useState(true)
    const [userType, setUserType] = useState(0)
    const [type, setType] = useState('')
    const [registerUser, setRegisteredUser] = useState(null)
    const [businessClassificationsList, setBusinessClassifications] = useState([])
    const [UserId, setUserId] = useState(null)
    const [currentCountry, setCurrentCountry] = useState()
    const [initialPopup, setInititalPopup] = useState(false)
    const { register, responseStatus, clearResponse, loadUser } = authContext

    const commonContext = useContext(CommonContext)
    const settingContext = useContext(SettingContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { updateProfile, responseStatus: responseStatusUser } = useContext(UserContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const {
        configFeatures,
        createCustomer,
        dwollaCustomerResponse,
        businessClassifications,
        dwollabusinessClassifications,
    } = commonContext
    const { searchTable, searchTableAction, table_data } = settingContext
    const { static_page, getStaticPage, allCountries, allStates } = productCommonContext
    const { responseStatus: responseStatusStripeCustomer, createStripeCustomer } = useContext(
        StripeCustomerContext,
    )
    const {
        onBoardEntity,
        onBoardIndividual,
        addCampaignContact,
        responseStatus: responseStatusNorthCapital,
    } = useContext(NorthCapitalContext)
    const { updateProfileAfterRegistration, subscribeProfileToListAfterRegister2 } = useContext(
        KlaviyoContext,
    )
    let [nextClicked, setNextClicked] = useState(false)

    let [termsLink, setTermsLink] = useState([])
    let [submitCount, setSubmitCount] = useState('')
    let [zohoRecord, setZohoRecord] = useState({
        email: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        zoho_id: null,
        created_at: null,
    })
    const [currentRegistrationType, setCurrentRegistrationType] = useState(false)
    const [inType, setInType] = useState('SSN')

    const newRegistration = true

    // const [phoneVerify, setPhoneVerify] = useState(false)
    // const [disable, setDisable] = useState(false)

    // const changePhoneVerify = () => {
    //     setPhoneVerify(!phoneVerify)
    // }

    // YUP Validation array to ensure all required fields are given values
    const validationArray = nextClicked
        ? Yup.object({
              username: Yup.string()
                  .matches(/^(?!.*@).*$/, 'Email not allowed ')
                  .test('noWhiteSpace', 'White space not allowed', (value) => {
                      return !/\s/.test(value)
                  })
                  .required('Enter Record Label Name Or Click Skip This Step')
                  .min(2, 'Mininum 2 characters')
                  .max(20, 'Maximum 20 characters'),
          })
        : Yup.object({
              username: Yup.string()
                  .matches(/^(?!.*@).*$/, 'Email not allowed ')
                  .test('noWhiteSpace', 'White space not allowed', (value) => {
                      return !/\s/.test(value)
                  })
                  .min(2, 'Mininum 2 characters')
                  .max(20, 'Maximum 20 characters'),
          })

    useEffect(() => {
        getStaticPage({ id: 'terms_of_use' })
        businessClassifications()
    }, [])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    const viewTerms = () =>
        window.open(
            termsLink
                ? `${global.site_url}/uploads/static_files/${termsLink}`
                : '/assets/docs/termsOfService.pdf',
        )

    // Set Iniaital values
    const formik = useFormik({
        initialValues: {
            username_msg: 'Registration Failed! Record Label Name Already Exists.',
            username: '',
        },
        validationSchema: validationArray,
        onSubmit: (data) => {
            randomVariable = Math.random()
            searchTable(
                {
                    table: 'users',
                    page: 1,
                    limit: 2,
                    filters: {
                        username: {
                            value: data.username,
                            type: 'in',
                            field: 'u.username',
                        },
                    },
                },
                randomVariable,
            )
        },
    })

    const formNextSubmit = () => {
        if (nextClicked) {
            formik.submitForm()
        } else {
            setNextClicked(true)
        }
    }

    useEffect(() => {
        if (nextClicked) {
            formik.submitForm()
        }
    }, [nextClicked])

    useEffect(() => {
        if (table_data.from === randomVariable) {
            if (table_data.records && table_data.records.length) {
                setAlert('Registration Failed! Record Label Name Already Exists.', 'error')
            } else {
                localStorage.setItem('username', formik.values.username)
                props.handleNext()
            }
        }
    }, [table_data])

    const onHandleUser = (event, value) => {
        if (!/\s/.test(event.target.value)) {
            formik.setFieldValue('username', event.target.value)
        }
    }

    // Mapped over for individual registration fields
    const IndividualRegistration = [
        {
            label: 'Record label',
            name: 'username',
            type: 'text',
            placeholder: 'Enter your record label name',
            class: `col-12 col-sm-12 helper`,
            onChange: onHandleUser,
            formik: formik,
            helperText: (
                <a
                    href={`${process.env.REACT_APP_FRONTEND_URL}recordlabel/${formik.values.username}`}
                    target="_blank"
                    rel="noreferrer"
                    className="ru-con"
                >
                    {' '}
                    {`${process.env.REACT_APP_FRONTEND_URL}recordlabel/${formik.values.username}`}
                </a>
            ),
            upperLabel: true,
        },
    ]

    return (
        <>
            <div className="registerForm mb-0">
                <div className="infoWrapper">
                    <p className="m-0">
                        An optional step in registration is to create and name your Record Label. As
                        you purchase SongShares, they will show up in your Record Label’s dashboard
                        along with your royalty payments. The SongShares you own will be featured on
                        a public SongVest URL you can share so friends can follow along.
                    </p>
                </div>

                <div className="rgLyrWrpr d-flex align-items-center justify-content-center">
                    <div className="row mt-4" style={{ width: '100%' }}>
                        {mapData(IndividualRegistration)}
                    </div>
                    <div className="col-md-12 mt-4">
                        <p className="markOptCheck">
                            <small>
                                <strong>Note:</strong> Your public dashboard and royalty payments
                                can optionally be hidden
                            </small>
                        </p>
                    </div>
                    <div className="regActBox mt-2">
                        <PrimaryButton
                            id="register_button"
                            className="w-100"
                            label="NEXT"
                            type="button"
                            onClick={() => formNextSubmit()}
                        />

                        <Link
                            className="w-100 d-block mt-3 text-center"
                            id="record_label"
                            onClick={() => props.handleNext()}
                        >
                            Skip record label
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Record
