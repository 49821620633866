import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import FullScreenPopup from '../../organisms/FullScreenPopup'

function NorthCapitalPlaidDetails(props) {
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const {
        linkExternalAccount,
        deleteExternalAccount,
        updateLinkExternalAccount,
        responseStatus: responseStatusNorthCapital,
    } = useContext(NorthCapitalContext)
    const [insertContent, setInsertContent] = useState(null)
    const [updateContent, setUpdateContent] = useState(null)

    useEffect(() => {
        if (props.data.status) {
            if (props.data.status === 'delete') {
                deleteExternalAccount()
            }
        }
    }, [props.data.status])

    useEffect(() => {
        if (props.data.status && props.data.popup) {
            if (props.data.status === 'update') {
                updateLinkExternalAccount()
            } else if (props.data.status === 'new') {
                linkExternalAccount({}, true)
            }
        }
    }, [props.data.status, props.data.popup])

    useEffect(() => {
        if (responseStatusNorthCapital) {
            if (responseStatusNorthCapital.from === 'linkExternalAccount') {
                if (responseStatusNorthCapital.status === 'success') {
                    setInsertContent(responseStatusNorthCapital.data.accountDetails)

                    // fetch(responseStatusNorthCapital.data.accountDetails)
                    //     .then((response) => {
                    //         setFormContent(response.url)
                    //     })
                    //     .then((data) => {
                    //     })
                    //     .catch((err) => {
                    //         console.error(err)
                    //     })
                }
            }

            if (responseStatusNorthCapital.from === 'updateLinkExternalAccount') {
                if (responseStatusNorthCapital.status === 'success') {
                    setUpdateContent(responseStatusNorthCapital.data.accountDetails)
                }
            }
        }
    }, [responseStatusNorthCapital])

    return (
        <>
            <FullScreenPopup
                open={props.data.popup && props.data.status === 'new'}
                className="ncModal"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Bank Details"
            >
                <div className="cardDetails">
                    {insertContent && (
                        <iframe
                            src={insertContent}
                            style={{ width: '100%', height: 'calc(100vh - 64px)' }}
                        ></iframe>
                    )}
                </div>
            </FullScreenPopup>

            <FullScreenPopup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                className="ncModal"
                modaltitle="Update Bank Details"
            >
                <div className="cardDetails">
                    {updateContent && (
                        <iframe
                            src={updateContent}
                            style={{ width: '100%', height: 'calc(100vh - 64px)' }}
                        ></iframe>
                    )}
                </div>
            </FullScreenPopup>
        </>
    )
}

export default NorthCapitalPlaidDetails
