import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    ROYALTIES_LIST,
    DASHBOARDDATA_LIST,
    OWNEDSONGSHARES_LIST,
    OPENSONGSHARES_LIST,
    ROYALTY_CHART_DATA,
    SONGSHARES_LIST,
    INVESTMENT_CHART_DATA,
} from './royaltyTypes'
export default (state, action) => {
    switch (action.type) {
        case ROYALTIES_LIST:
            return {
                ...state,
                royalties_list: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case DASHBOARDDATA_LIST:
            return {
                ...state,
                dashboardData_List: action.payload,
            }
        case OWNEDSONGSHARES_LIST:
            return {
                ...state,
                ownedSongShares_List: action.payload,
            }
        case OPENSONGSHARES_LIST:
            return {
                ...state,
                openSongShares_List: action.payload,
            }
        case ROYALTY_CHART_DATA:
            return {
                ...state,
                royalty_chart_data: action.payload,
            }
        case SONGSHARES_LIST:
            return {
                ...state,
                songshares_list: action.payload,
            }
        case INVESTMENT_CHART_DATA:
            return {
                ...state,
                investment_chart_data: action.payload,
            }
        default:
            return state
    }
}
