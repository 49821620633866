import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../product/context/auth/authContext'
import UserContext from '../../product/context/user/userContext'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import { Button } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import CustomAlert from '../../product/components/atoms/CustomAlert'
import CustomPopover from '../../custom/components/atoms/Popover'
import AlertContext from '../../product/context/alert/alertContext'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import { handleRedirectInternal } from '../../custom/common/components'
import Loaders from '../../custom/components/molecules/Loaders'
import { useHistory } from 'react-router-dom'
import SecondaryButton from './../../product/components/atoms/SecondaryButton/index'
import Cryptos from './../../product/common/cryptos'

const CommonProfile = () => {
    const authContext = useContext(AuthContext)

    const userContext = useContext(UserContext)
    const commonContext = useContext(CommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { allNotifications, configFeatures } = commonContext

    const { allCountries, allStates } = productCommonContext

    const { user, loadUser } = authContext
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const {
        updateProfile,
        updatePreference,
        responseStatus: responseStatusUserReg,
        sendEmailVerifyLink,
    } = userContext

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const [phoneVerify, setPhoneVerify] = React.useState(false)
    const [userType, setUserType] = useState(null)
    const [userSsnType, setUserSsnType] = useState(null)
    const [userUpdate, setUserUpdate] = useState(false)
    const [inType, setInType] = useState('SSN')

    const validationArray = [
        Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            address1: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            address2: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters'),
            country: Yup.string().trim().required('Required!'),
            city: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'The characters only')
                .required('Required!'),
            state: Yup.string().trim().required('Required!'),
            zip: Yup.string()
                .trim()
                .min(3, 'Minimum 3 characters Required!')
                .max(7, 'Maximum 7 characters Required!')
                .required('Required!'),
            socialsecurity: Yup.string().when('domicile', {
                is: 'non-resident',
                then: Yup.string(),
                otherwise: Yup.string()
                    .required('Required!')
                    .test(
                        'len',
                        '9 characters required',
                        (value) => value && value.replace(/_/g, '').replace(/-/g, '').length === 9,
                    ),
            }),
            first_name: Yup.string().when('user_type', {
                is: 0,
                then: Yup.string()
                    .trim()
                    .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                    .max(250, 'Maximum 250 characters')
                    .required('Required!'),
            }),
            last_name: Yup.string().when('user_type', {
                is: 0,
                then: Yup.string()
                    .trim()
                    .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                    .max(250, 'Maximum 250 characters')
                    .required('Required!'),
            }),
            dob: Yup.string(),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            email: '',
            socialsecurity: '',
            address1: '',
            address2: '',
            country: 'US',
            city: '',
            state: '',
            zip: '',
            first_name: '',
            last_name: '',
            dob: '',
            kyc_request_count: 2,
            isKycCheck: true,
            reg_steps_skip: '',
            socialSecurityType: 'SSN',
        },
        validationSchema: validationArray[0],
        onSubmit: async (values) => {
            const flag = Object.keys(values).find(
                (e) => e != 'kyc_request_count' && e != 'isKycCheck' && values[e] != user[e],
            )

            if (!flag) {
                setIsLoading(true)
                await updateProfile(
                    {
                        kyc_request_count: 2,
                    },
                    true,
                )
                await loadUser()
                setIsLoading(false)
                handleRedirectInternal(history, 'registration/uploadDocument/1')
            } else {
                let value = values
                if (value.socialsecurity) {
                    value.socialsecurity = Cryptos.encrypt(value.socialsecurity)
                }
                setIsLoading(true)
                setUserUpdate(true)
                await updateProfile(value)
                await loadUser()
            }
        },
    })

    useEffect(() => {
        if (inType) formik.values.socialSecurityType = inType
    }, [inType])

    useEffect(() => {
        formik.values.user_type = userType
    }, [userType])

    useEffect(() => {
        if (formik.values.country && user && user.country !== formik.values.country)
            formik.setFieldValue('state', '')
    }, [formik.values.country])

    // Mapped over for individual registration fields
    const profile = [
        {
            label: 'First Name',
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''}`,
            autoFocus: true,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Last Name',
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your last name',
            class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''}`,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'D.O.B',
            name: 'dob',
            type: 'date',
            placeholder: 'Enter D.O.B.',
            class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''}`,
            disableFuture: true,
            disabled: true,
            formik: formik,
            upperLabel: true,
        },
        {
            label:
                userType === 1 ? (
                    <div className="swtchTabs">
                        <p
                            className={`${inType === 'EIN' ? 'activeTab' : ''}`}
                            onClick={() => setInType('EIN')}
                        >
                            <span className="material-icons mr-1">
                                {inType === 'EIN'
                                    ? 'radio_button_checked'
                                    : 'radio_button_unchecked'}
                            </span>{' '}
                            EIN
                        </p>
                        <p
                            className={`${inType === 'SSN' ? 'activeTab' : ''}`}
                            onClick={() => setInType('SSN')}
                        >
                            <span className="material-icons mr-1">
                                {inType === 'SSN'
                                    ? 'radio_button_checked'
                                    : 'radio_button_unchecked'}
                            </span>{' '}
                            SSN
                        </p>
                    </div>
                ) : (
                    'Social Security Number'
                ),
            name: 'socialsecurity',
            placeholder: inType === 'EIN' ? 'Enter EIN' : 'Enter SSN',
            class: `col-12 col-sm-6 ${formik.values.domicile == 'non-resident' ? 'd-none' : ''}`,
            formik: formik,
            type: 'hiddenMask',
            maskValue: inType === 'EIN' ? '**-*******' : '***-**-****',
            upperLabel: true,
            helperText: (
                <CustomPopover
                    label="Why do we need this?"
                    content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
                />
            ),
        },
        // {
        //     label: 'EIN / SSN',
        //     name: 'socialsecurity',
        //     placeholder: 'Enter EIN / SSN',
        //     class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''} ${
        //         formik.values.domicile == 'non-resident' ? 'd-none' : ''
        //     }`,
        //     formik: formik,
        //     type: 'hiddenMask',
        //     maskValue: '**-*******',
        //     upperLabel: true,
        //     helperText: (
        //         <CustomPopover
        //             label="Why do we need this?"
        //             content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
        //         />
        //     ),
        //     // tooltiptitle:
        //     //     'Since we are registered with an SEC regulated broker-dealer. Rally is required by federal law to ask for your Social Security Number',
        // },
        // {
        //     label: 'Social Security Number',
        //     name: 'socialsecurity',
        //     type: 'hiddenMask',
        //     maskValue: '***-**-****',
        //     placeholder: 'Enter Social Security Number',
        //     class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''} ${
        //         formik.values.domicile == 'non-resident' ? 'd-none' : ''
        //     }`,
        //     formik: formik,
        //     upperLabel: true,
        //     helperText: (
        //         <CustomPopover
        //             label="Why do we need this?"
        //             content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
        //         />
        //     ),
        // },
        {
            label: 'Address Line 1',
            name: 'address1',
            type: 'text',
            placeholder: 'Enter your address',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            placeholder: 'Enter your address',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'City',
            name: 'city',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'State',
            placeholder: 'Select your state',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: allStates
                .filter(
                    (state) =>
                        (formik.values.country
                            ? formik.values.country.indexOf(state.countryCode) !== -1
                            : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                        global.ignoreStates.indexOf(state.isoCode) === -1,
                )
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.isoCode
                    return busCategoryChanged
                }),
            name: 'state',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Country',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: allCountries
                .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                .map((country) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = country.name
                    busCategoryChanged.value = country.isoCode
                    return busCategoryChanged
                }),
            name: 'country',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Zip code',
            placeholder: 'Enter your zip code',
            class: 'col-12 col-sm-6',
            type: 'text',
            name: 'zip',
            formik: formik,
            upperLabel: true,
        },
    ]

    useEffect(() => {
        if (user) {
            setInType(user.socialSecurityType)
            setUserType(user.user_type)
            formik.values.user_type = user.user_type
            formik.values.domicile = user.domicile
            formik.values.domesticYN = user.domesticYN
            formik.values.address1 = user.address1
            formik.values.address2 = user.address2 || ''
            formik.values.phone = user.phone
            formik.values.email = user.email
            formik.values.city = user.city
            formik.values.country = user.country
            formik.values.state = user.state
            formik.values.zip = user.zip
            formik.values.socialSecurityType = user.socialSecurityType
            formik.values.socialsecurity = user.socialsecurity
                ? Cryptos.decrypt(user.socialsecurity)
                : ''
            formik.values.reg_steps_skip = user.reg_steps_skip ? user.reg_steps_skip : ''
            if (user.user_type === 0) {
                formik.values.first_name = user.first_name
                formik.values.last_name = user.last_name
                formik.values.dob = user.dob
            } else {
                formik.values.companyname = user.companyname
                formik.values.entityType = user.entityType
                formik.values.entityDesc = user.entityDesc
            }
        }
    }, [user])
    const skipPreRegistration = () => {
        formik.values.kyc_request_count = 2
        formik.handleSubmit()
    }
    useEffect(() => {
        if (responseStatusUserReg) {
            if (responseStatusUserReg.from === 'updateProfile') {
                if (responseStatusUserReg.status === 'success') {
                    // if (user.kyc_request_count !== 1) {
                    if (userUpdate === true) {
                        setUserUpdate(false)
                        setTimeout(() => {
                            setIsLoading(false)
                            handleRedirectInternal(history, 'registration/uploadDocument/1')
                        }, 5000)
                    }
                    // }
                } else {
                    setUserUpdate(false)
                    setIsLoading(false)
                    setAlert('Something went wrong', 'error')
                }
            }
        }
    }, [responseStatusUserReg])

    useEffect(() => {
        if (user) {
            if (user.kyc_request_count == 2) {
                handleRedirectInternal(history, 'search')
            }
        }
    }, [user])

    return (
        <>
            <RegistrationLayout>
                <Loaders name="home" isLoading={isLoading} loop={1} className="defaultLoader" />
                <div className="loginWrapper p-5 h-100">
                    <form
                        className="registerForm w-100"
                        onSubmit={formik.handleSubmit}
                        autoComplete="nofill"
                    >
                        <>
                            <p className="mb-4 dashSubtitle text-danger">
                                We were unable to verify your identity per SEC guidelines. Please
                                review and correct any wrong information below and click Next. If
                                all details are correct, click Next and we will ask for additional
                                identification. You will not be able to bid or purchase SongShares
                                until your account is verified.
                            </p>
                            <div className="row">{Object.values(mapData(profile))}</div>
                        </>
                        <PrimaryButton type="submit" label={'Next'} />
                    </form>
                    {/* <Button className="skipNavBtn" onClick={() => clickSkip()}>
                        Skip
                        <span className="material-icons ml-1">double_arrow</span>
                    </Button> */}
                </div>
            </RegistrationLayout>
        </>
    )
}

export default CommonProfile
