import React, { useContext, useState, useEffect } from 'react'
import { Chart } from 'react-google-charts'
import moment from 'moment'

const Charts = (props) => {
    const [data, setdata] = useState([
        ['x', 'Shares'],
        [0, 0],
        [1, 10],
        [2, 23],
        [3, 17],
        [4, 18],
        [5, 9],
        [6, 11],
        [7, 27],
        [8, 33],
        [9, 40],
        [10, 32],
        [11, 35],
    ])

    useEffect(() => {
        let dataSet = props.axis ? [props.axis] : []
        props.data.map((obj) => {
            let isTime = props.pointer.indexOf('timestp')
            if (isTime > -1)
                obj[props.pointer[isTime]] = moment(obj[props.pointer[isTime]]).format('YYYY-MM-DD')
            dataSet.push([obj[props.pointer[0]], obj[props.pointer[1]]])
        })
        setdata(dataSet)
    }, [props.data])

    return (
        <>
            <Chart
                width={'100%'}
                height={'400px'}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    hAxis: {
                        title: props.axis.length ? props.axis[0] : '',
                    },
                    vAxis: {
                        title: props.axis.length ? props.axis[1] : '',
                    },
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </>
    )
}

export default Charts
