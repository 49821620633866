import React, { useContext, useState, useEffect } from 'react'
import { Divider, Link, ListItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import './Dashboard.css'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { handleRedirectInternal, mapData } from '../../product/common/components'
import AuthContext from '../../product/context/auth/authContext'
import Popup from '../../custom/components/organisms/Popup'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import UserContext from '../../product/context/user/userContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

const SideNav = () => {
    const [popopen, setPopopen] = useState(false)
    const classes = useStyles()
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)

    const { user } = authContext
    const { updateProfile, responseStatus: responseProfileImageUpdate } = userContext

    const [expanded, setExpanded] = useState('')

    const handleChange = (panel) => {
        if (expanded === panel) {
            setExpanded('')
        } else {
            setExpanded(panel)
        }
    }

    const toggleModal = () => {
        setPopopen(!popopen)
    }

    useEffect(() => {
        if (location.pathname.split('/')[1]) {
            setExpanded(location.pathname.split('/')[1])
        }
    }, [location.pathname])

    const validationArray = Yup.object({
        profilepic: Yup.array().required('required'),
    })

    const formik = useFormik({
        initialValues: {
            profilepic: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    useEffect(() => {
        if (responseProfileImageUpdate) {
            if (responseProfileImageUpdate.from === 'updateProfile') {
                if (responseProfileImageUpdate.status === 'success') {
                    setPopopen(false)
                }
            }
        }
    }, [responseProfileImageUpdate])

    const profilepic = [
        {
            name: 'profilepic',
            type: 'uploadDropZone',
            formik: formik,
            icon: 'account_circle',
            titleText: 'upload profile picture',
            class: 'col-12',
            folder: 'product',
            multiple: false,
            accept: 'image/*',
        },
    ]

    return (
        <div className="dashboardNav w-100 d-flex align-items-end justify-content-between customContainer">
            {/* <div className="usrDetls d-flex align-items-end">
                If no image available then show
                <img
                    className="usrImg"
                    src={
                        user?.profilepic
                            ? global.images_url + user.profilepic
                            : 'https://jgi.doe.gov/wp-content/uploads/2014/04/Steven_Hallam-slide.jpg'
                    }
                /> 
                
                <img src="image_path_here" />
                <div className="usrName text-left ml-3 mb-2">
                    <h4 className="mb-1">{user?.first_name}</h4>
                    <Link onClick={() => toggleModal()} className="cursorPointer">
                        Change Profile Picture
                    </Link>
                    <Popup
                        modaltitle="Upload Profile Picture"
                        open={popopen}
                        size="sm"
                        handleClose={() => toggleModal()}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">{Object.values(mapData(profilepic))}</div>
                            <PrimaryButton label="Change" type="submit" />
                        </form>
                    </Popup>
                </div>
            </div> */}
            <ul className="navTabs mb-0">
                <li>
                    <NavLink activeClassName="active" to="/dashboard">
                        Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={
                            window.location.pathname.includes('/auction') &&
                            window.location.pathname !== '/auctions/watchlist' &&
                            'active'
                        }
                        to="/auctions/all"
                    >
                        My SongShares
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/auctions/watchlist">
                        Favorites
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className={window.location.pathname.includes('/payment') && 'active'}
                        to="/payment/transaction"
                    >
                        Payments
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/invoices">
                        Invoice
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/account/profile">
                        Profile
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/account/notifications">
                        Notifications
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/saved_search">
                        Saved Search
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default SideNav
