import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    CREATE_CUSTOMER,
    GET_SOURCE,
    DELETE_SOURCE,
    BUSINESS_CLARIFICATION,
    CREATE_TRANSFER,
    TRANSFERS_RESPONSE,
    NOTIFY_BUY_ALL_SHARES,
    FREE_OFFERS,
    UPDATE_OFFERS,
    CLEAR_OFFERS,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allCategory: [],
        allBusinessCategory: [],
        allLocations: [],
        allCondition: [],
        allSubCategories: [],
        allNotifications: [],
        allConfigurationVariables: {},
        configFeatures: {},
        allBidIncrements: [],
        responseStatus: null,
        languages: [],
        pharases: {},
        allIncomeType: [],
        allPaidBy: [],
        allAssetType: [],
        allTerm: [],
        allFooter: [],
        dwollaCustomerResponse: {},
        dwollaTranseResponse: {},
        dwollaSourceResponse: {},
        transfersResponse: [],
        dwollaDeleteSourceResponse: {},
        dwollabusinessClassifications: {},
        notifyBuyAllSharesResponse: {},
        freeOffers: {},
        updateOffers: {},
    }

    const { i18n } = useTranslation()

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])
        if (res?.data?.status && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategoryTypes,
                    allSubCategories: res.data.data.responseData.tableValues.allsubCategoryTypes,
                    allBusinessCategory:
                        res.data.data.responseData.tableValues.allbusiness_category,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allCondition: res.data.data.responseData.tableValues.allconditionTypes,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    configFeatures: res.data.data.responseData.features,
                    languages: res.data.data.responseData.languages,
                    phrases: res.data.data.responseData.phrases,
                    allIncomeType: res.data.data.responseData.tableValues.allincome_type,
                    allPaidBy: res.data.data.responseData.tableValues.allpaid_by,
                    allAssetType: res.data.data.responseData.tableValues.allasset_type,
                    allTerm: res.data.data.responseData.tableValues.allterm,
                    allFooter: res.data.data.responseData.tableValues.allfooter,
                },
            })

            //set reload the language phrase
            global.languages = res.data.data.responseData.languages
            global.phrases = res.data.data.responseData.phrases
            for (let i = 0; i < global.languages.length; i++) {
                i18n.addResourceBundle(
                    global.languages[i],
                    'translations',
                    global.phrases[global.languages[i]],
                    true,
                    true,
                )
            }
            i18n.changeLanguage('for_english')
        }
    }

    const searchTableAction = async (formData) => {
        try {
            const from = 'CommunicationSearchTable'
            const [res] = await Promise.all([
                apiCall('post', 'datatable_insert', formData, '', 'admin/setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const searchTableActionupdate = async (formData) => {
        try {
            const from = 'CommunicationSearchTable'
            const [res] = await Promise.all([
                apiCall('post', 'datatable_update', formData, '', 'admin/setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const createCustomer = async (formData) => {
        try {
            const from = 'createCustomerPlaid'
            const [res] = await Promise.all([
                apiCall('post', 'createCustomer', formData, '', 'custom'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: CREATE_CUSTOMER,
                    payload: {
                        dwollaCustomerResponse: res.data.data.responseData
                            ? res.data.data.responseData
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSource = async (formData) => {
        try {
            const from = 'getSourcePlaid'
            const [res] = await Promise.all([apiCall('post', 'getSource', formData, '', 'custom')])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SOURCE,
                    payload: {
                        dwollaSourceResponse: res.data.data.responseData
                            ? res.data.data.responseData
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTransfers = async (formData) => {
        try {
            const from = 'getTransfers'
            const [res] = await Promise.all([
                apiCall('post', 'getTransfers', formData, '', 'custom'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: TRANSFERS_RESPONSE,
                    payload: {
                        transfersResponse: res.data.data.responseData
                            ? res.data.data.responseData
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const createTransfer = async (formData) => {
        try {
            const from = 'createTransfer'
            const [res] = await Promise.all([
                apiCall('post', 'createTransfer', formData, '', 'custom'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: CREATE_TRANSFER,
                    payload: res.data.data.responseData ? res.data.data.responseData : {},
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearTransResponse = () =>
        dispatch({
            type: CREATE_TRANSFER,
        })

    const deleteSource = async (formData) => {
        try {
            const from = 'deleteSourcePlaid'
            const [res] = await Promise.all([
                apiCall('post', 'deleteSource', formData, '', 'custom'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: DELETE_SOURCE,
                    payload: {
                        dwollaDeleteSourceResponse: res.data.data.responseData
                            ? res.data.data.responseData
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const tradeReTry = async (formData) => {
        try {
            const from = 'tradeReTry'
            const [res] = await Promise.all([
                apiCall('post', 'hooks/matchedTradeNotify', formData, '', 'custom'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const businessClassifications = async (formData) => {
        try {
            const from = 'businessClassificationsPlaid'
            const [res] = await Promise.all([
                apiCall('post', 'businessClassifications', formData, '', 'custom'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: BUSINESS_CLARIFICATION,
                    payload: {
                        dwollabusinessClassifications: res.data.data.responseData
                            ? res.data.data.responseData
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const notifyBuyAllNowShareAction = async (formData) => {
        try {
            const from = 'buyAllSharesNow'
            const [res] = await Promise.all([
                apiCall('post', 'index/offer/offer/makeOffer', formData, '', 'custom'),
            ])
            if (res.data.status === 'success') {
                /*dispatch({
                    type: NOTIFY_BUY_ALL_SHARES,
                    payload: {
                        notifyBuyAllSharesResponse: res.data.data ? res.data.data : {},
                    },
                })*/
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const checkBuyAllNowShareRequestSent = async (formData) => {
        try {
            const from = 'buyAllNowShareRequestSent'
            const [res] = await Promise.all([
                apiCall('post', 'index/offer/offer/checkUserMakeOffer', formData, '', 'custom'),
            ])

            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getOffers = async (formData) => {
        console.log('getoffers')
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getFreeShares', formData, '', 'custom'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: FREE_OFFERS,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateOffers = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateFreeShare', formData, '', 'custom'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: UPDATE_OFFERS,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const clearOffers = () =>
        dispatch({
            type: CLEAR_OFFERS,
        })
    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allBusinessCategory: state.allBusinessCategory,
                allNotifications: state.allNotifications,
                allSubCategories: state.allSubCategories,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allBidIncrements: state.allBidIncrements,
                allConfigurationVariables: state.allConfigurationVariables,
                configFeatures: state.configFeatures,
                languages: state.languages,
                responseStatus: state.responseStatus,
                allIncomeType: state.allIncomeType,
                allPaidBy: state.allPaidBy,
                allAssetType: state.allAssetType,
                allTerm: state.allTerm,
                allFooter: state.allFooter,
                dwollaCustomerResponse: state.dwollaCustomerResponse,
                dwollaSourceResponse: state.dwollaSourceResponse,
                dwollaDeleteSourceResponse: state.dwollaDeleteSourceResponse,
                dwollabusinessClassifications: state.dwollabusinessClassifications,
                dwollaTranseResponse: state.dwollaTranseResponse,
                transfersResponse: state.transfersResponse,
                notifyBuyAllSharesResponse: state.notifyBuyAllSharesResponse,
                freeOffers: state.freeOffers,
                getGlobalVariable,
                clearResponse,
                searchTableAction,
                searchTableActionupdate,
                createCustomer,
                getSource,
                deleteSource,
                businessClassifications,
                createTransfer,
                clearTransResponse,
                getTransfers,
                tradeReTry,
                notifyBuyAllNowShareAction,
                checkBuyAllNowShareRequestSent,
                getOffers,
                updateOffers,
                clearOffers,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
