import React, { useState, useContext, useEffect, useRef } from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CartItem from '../../custom/components/molecules/ProductCard/CartItem'
import { useHistory } from 'react-router-dom'
import BuynowContext from '../../product/context/buynow/buynowContext'
import AuthContext from '../../product/context/auth/authContext'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { currencyFormat } from '../../custom/common/components'
import Loaders from '../../custom/components/molecules/Loaders'
import AppLayout from '../../custom/components/templates/AppLayout'
import UserContext from '../../product/context/user/userContext'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import ProductContext from '../../product/context/product/productContext'
import * as Yup from 'yup'
import KlaviyoContext from '../../custom/context/klaviyo/klaviyoContext'
import Popup from '../../custom/components/organisms/Popup'
import Cryptos from '../../product/common/cryptos'
import AlertContext from '../../product/context/alert/alertContext'
import Lottie from 'react-lottie'
import checkoutAnimation from '../../assets/lottie/checkoutAnimation.json'

const PurchaseShare = (props) => {
    let randomVariable = Math.random()
    const buynowContext = useContext(BuynowContext)
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const {
        search_allproducts,
        search_allsimilar,
        getAllSimilarProducts,
        getAllSearchProducts,
        getAllTtwBidHistoryByUser,
        responseStatus: responseStatusProduct,
        ttwBidsByUser,
    } = productContext
    const {
        search_allproducts: searchCartProducts,
        changeOnCart,
        changeOnCartTemp,
        search_tempProducts,
        getAllCartTempProducts,
        addToCart,
        addToCartTemp,
        getAllCartProducts,
        responseStatus,
        clearResponse,
    } = buynowContext
    const { user, loadUser, isAuthenticated } = authContext
    const { setAlert } = alertContext
    const [isLoading, setIsLoading] = useState(true)

    let [cartProducts, setCartProducts] = useState([])
    const [product, setProduct] = useState()
    let [nonCartProducts, setNonCartProducts] = useState([])
    let [cartValues, setCartValues] = useState({})
    const [isSSN, setIsSSN] = useState(false)
    // const { updateProfile, responseStatus: userResponseStatus } = useContext(UserContext)
    const { updateProfile } = useContext(KlaviyoContext)

    const [search, setSearch] = useState({})
    const [popup, setPopup] = useState(false)
    const [tempData, setTempData] = useState({})
    const newRegistration = true

    const validationArray = [
        Yup.object({
            socialsecurity: Yup.string()
                .required('Required!')
                .nullable(true)
                .test(
                    'len',
                    '9 characters required',
                    (value) => value && value.replace(/_/g, '').replace(/-/g, '').length === 9,
                ),
        }),
    ]

    const ssnFormik = useFormik({
        initialValues: {
            socialsecurity: '',
        },
        validationSchema: validationArray[0],
        onSubmit: (values) => {
            let value = values
            console.log('validation success')
            if (value.socialsecurity) {
                value.socialsecurity = Cryptos.encrypt(value.socialsecurity)
            }
            // updateProfile(value)
            getAllCartProducts({})
            setIsLoading(true)
        },
    })

    const ssnField = [
        {
            label: 'Social Security Number',
            name: 'socialsecurity',
            placeholder: 'Enter SSN',
            class: `col-12`,
            formik: ssnFormik,
            type: 'hiddenMask',
            maskValue: '***-**-****',
            upperLabel: true,
            startAdornment: <span className="material-icons mr-3">lock</span>,
            modalPP: true,
        },
    ]

    useEffect(() => {
        if (props.match?.params?.pid && props.match?.params?.qty) {
            randomVariable = Math.random()
            if (props.match.params.qty !== '0') {
                getAllSearchProducts(
                    {
                        limit: 120,
                        page: 1,
                        market_status: ['open'],
                        order: '',
                        filters: {
                            id: {
                                value: props.match?.params?.pid,
                                type: 'in',
                                field: 'p.id',
                            },
                        },
                    },
                    'purchaseview',
                )
            } else {
                setAlert('Quantity is not there', 'error')
                handleRedirectInternal(history, 'search')
            }
        } else {
            handleRedirectInternal(history, 'search')
        }
    }, [props.match?.params?.pid, props.match?.params?.qty])

    useEffect(() => {
        if (search_allproducts.from == 'purchaseview') {
            if (search_allproducts.records.length) {
                setProduct(search_allproducts.records[0])
                if (isAuthenticated) {
                    addToCart({
                        buynow_url: true,
                        cart: [
                            {
                                product_id: parseInt(props.match?.params?.pid),
                                qty: parseInt(props.match?.params?.qty),
                            },
                        ],
                    })
                } else {
                    const id = uuidv4()
                    localStorage.setItem('temp_user_id', id)
                    addToCartTemp({
                        buynow_url: true,
                        temp_user_id: id,
                        cart: [
                            {
                                product_id: parseInt(props.match?.params?.pid),
                                qty: parseInt(props.match?.params?.qty),
                            },
                        ],
                    })
                }
            } else {
                setAlert('Product not found', 'error')
                handleRedirectInternal(history, 'search')
            }
        }
    }, [search_allproducts, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            setCartProducts(
                searchCartProducts.cartrecords.length ? searchCartProducts.cartrecords : [],
            )
            if (searchCartProducts.cartrecords.length) {
                handleRedirectInternal(
                    history,
                    `checkout/buynow/${searchCartProducts.cartrecords[0].cartValues.cart_group}`,
                )
            }
        }
    }, [searchCartProducts])

    useEffect(() => {
        if (!isAuthenticated) {
            setCartProducts(
                search_tempProducts.cartrecords.length ? search_tempProducts.cartrecords : [],
            )
            if (search_tempProducts.cartrecords.length) {
                handleRedirectInternal(
                    history,
                    `checkout/buynow/${search_tempProducts.cartrecords[0].cartValues.cart_group}`,
                )
            }
        }
    }, [search_tempProducts])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    const cartProduct = responseStatus.data[0]
                    var _learnq = window._learnq || []
                    var itemArr = {
                        $value: cartProduct.qty * product.bprice,
                        AddedItemProductName: product.title,
                        AddedItemProductID: product.id,
                        AddedItemCategories: 'SongShares',
                        AddedItemURL: window.location.href,
                        AddedItemPrice: product.bprice,
                        AddedItemQuantity: cartProduct.qty,
                        ItemNames: [product.title],
                        CheckoutURL: `${global.site_url}/checkout/buynow/${cartProduct.cart_group}`,
                        Items: [
                            {
                                ProductID: product.id,
                                ProductName: product.title,
                                Quantity: cartProduct.qty,
                                ItemPrice: product.bprice,
                                RowTotal: cartProduct.qty * product.bprice,
                                ProductURL: window.location.href,
                                ProductCategories: product.auctiontype,
                            },
                        ],
                    }
                    _learnq.push(['track', 'Added to Cart', itemArr])
                    console.log(_learnq, 'trigger', ['track', 'Added to Cart', itemArr])
                    // updateProfile({
                    //     email: user.email,
                    //     'Last Added to Cart': new Date().toLocaleString('en-US', {
                    //         year: 'numeric',
                    //         month: '2-digit',
                    //         day: '2-digit',
                    //     }),
                    // })
                    if (
                        user?.kyc_request_count >=
                        2 /*  ||
            (user.domesticYN == 'international_account' && newRegistration) */
                    ) {
                        getAllCartProducts({})
                    }
                    // else if (
                    //     user &&
                    //     user.user_type === 0 &&
                    //     !user.socialsecurity &&
                    //     user.domesticYN != 'international_account' &&
                    //     newRegistration
                    // ) {
                    //     setIsSSN(true)
                    //     setPopup(true)

                    //     setIsLoading(false)
                    // }
                    else {
                        // updateProfile(
                        //     {
                        //         isKycCheck: true,
                        //         kyc_request_count: user.kyc_request_count + 1,
                        //     },
                        //     true,
                        // )
                        getAllCartProducts({})
                        // handleRedirectInternal(
                        //     history,
                        //     `checkout/buynow/${data.cartValues.cart_group}`,
                        // )
                    }
                } else if (responseStatus.status === 'error') {
                    handleRedirectInternal(history, 'search')
                }
            } else if (responseStatus.from === 'addToCartTemp') {
                if (responseStatus.status === 'success') {
                    if (!isAuthenticated) {
                        getAllCartTempProducts({ temp_user_id: localStorage.temp_user_id })
                        // handleRedirectInternal(
                        //     history,
                        //     `checkout/buynow/${data.cartValues.cart_group}`,
                        // )
                    }
                } else if (responseStatus.status === 'error') {
                    handleRedirectInternal(history, 'search')
                }
            }
            clearResponse()
        }
    }, [responseStatus])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        speed: '0.25',
        animationData: checkoutAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <AppLayout>
            <>
                {/* <Header /> */}
                {/* <Loaders name="home" isLoading={isLoading} /> */}
                {isLoading ? (
                    <div className="svRedirect">
                        <Lottie options={defaultOptions} speed="0.25" height={300} width={400} />
                        <h6>Redirecting to Checkout Page</h6>
                    </div>
                ) : (
                    <>
                        <Popup
                            open={popup}
                            cstmModalClass="kycAmlModal"
                            size="sm"
                            handleClose={() => {
                                setPopup(false)
                            }}
                            modaltitle="Kyc/Aml process"
                        >
                            <>
                                {
                                    isSSN && (
                                        <form
                                            onSubmit={ssnFormik.handleSubmit}
                                            autoComplete="nofill"
                                        >
                                            <div className="row">
                                                {Object.values(mapData(ssnField))}
                                            </div>
                                            <div className="bdyContent">
                                                <p>Why do we need this?</p>
                                                <p className="descTxt">
                                                    We are required by federal law (USA PATRIOT Act)
                                                    to collect your Social Security Number. This is
                                                    only used to verify your identity and to protect
                                                    against fraud and money laundering
                                                </p>
                                                <div className="lckDesc d-flex align-items-center mt-4">
                                                    <span className="material-icons">lock</span>
                                                    <div className="rtCntnr ml-3">
                                                        <p className="crdtTtl">
                                                            Your Credit Score is safe
                                                        </p>
                                                        <p className="cdtlDesc">
                                                            We only use SSN to verify your identity.
                                                            This has no impact on your credit score.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <PrimaryButton
                                                        // disabled={disabled}
                                                        btnSize="large"
                                                        type="submit"
                                                        label={'Save'}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    )
                                    // : (
                                    //     <div>
                                    //         <h5>we are proceeding for KYC process</h5>
                                    //         <PrimaryButton
                                    //             btnSize="large"
                                    //             type="submit"
                                    //             onClick={() => kycInit()}
                                    //             label={'Continue'}
                                    //         />
                                    //     </div>
                                    // )
                                }
                            </>
                        </Popup>
                    </>
                )}
            </>
        </AppLayout>
    )
}

export default PurchaseShare
