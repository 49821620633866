import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { BRAND_LOGO, LOGO } from '../../../../Utils'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'

import { handleRedirectInternal } from '../../../../product/common/components'
import './SideNavBar.css'
import ProductContext from '../../../../product/context/product/productContext'
import BidHistory from '../../../components/organisms/BidHistory'
import TTWBidHistory from '../../../components/organisms/TTWBidHistory'
import KYCUpdate from '../../../components/organisms/KYCUpdate'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import Loaders from '../Loaders'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 9999,
    },
}))

const SideNavBar = (props) => {
    const classes = useStyles()
    const [aucopen, setAucOpen] = React.useState(false)
    const [menu, setMenu] = React.useState('auction')
    const anchorRef = React.useRef(null)
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const buynowContext = useContext(BuynowContext)
    const { setAlert } = alertContext
    const { user, isAuthenticated, loadPendingCount, isAdmin, cartCount, logout } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)

    const {
        getAllSearchProducts,
        getTotalPrivateSaleProducts,
        totalPrivateSaleProducts,
    } = productContext

    const { getAllCartProducts, search_allproducts } = buynowContext
    const { northCapitalUser } = useContext(NorthCapitalContext)

    const { searchValue, setSearchValue, isLoading } = commonContext

    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})
    const [searchBox, setSearchBox] = useState(false)

    const { pathname } = useLocation()

    const mainMenu = useRef()

    const mobile = useMediaQuery('(max-width:1200px)')

    const handleSearchBox = () => {
        setSearchBox(!searchBox)
    }

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)
            getTotalPrivateSaleProducts({
                page: 1,
                orderby: 'p.date_closed, asc',
                market_status: ['open'],
                filters: {},
                isAuctionLive: 1,
                isTTWLive: 1,
                isUpcomming: 1,
                isOfferingLive: 1,
                privateSale: 1,
            })
        }
    }, [isAuthenticated])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    // useEffect(() => {
    //     if (searchValue) {
    //         handleRedirectInternal(history, `search`)
    //     }
    // }, [searchValue])

    const onLogOut = () => {
        logout()
        localStorage.removeItem('temp_user_id')
        localStorage.removeItem('ttw_temp_user_id')
        localStorage.removeItem('bidIncrease')
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const listHandle = (value) => {
        let search = {
            filters: {
                auctiontype: {
                    value: value == 'all_closed' ? '' : value,
                    type: 'in',
                    field: 'p.auctiontype',
                },
            },
            market_status: ['open'],
        }
        if (value == 'all_closed') {
            search.market_status = ['closed', 'sold']
        }
        return search
    }

    const getSearchBarObjet = (value) => {
        // if (searchValue) {
        //     return {
        //         ...searchValue,
        //         filters: {
        //             ...searchValue.filters,
        //             searchbar: {
        //                 ...searchValue.filters.searchbar,
        //                 value: value,
        //             },
        //         },
        //     }
        // } else {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title,p.desc_proc',
                },
            },
        }
        //}
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        setSearchBox(!searchBox)
        if (typeof searchText != 'undefined') {
            setSearchValue(getSearchBarObjet(searchText))
            setState({ ...state, top: false })
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        } else {
            // setSearch({
            //     ...search,
            //     filters: {
            //         ...search.filters,
            //         searchbar: {
            //             ...search.filters.searchbar,
            //             value: searchValue.filters.searchbar.value,
            //         },
            //     },
            // })
        }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
        // if (searchValue && searchValue.market_status) {
        //     setMenu('past_auction')
        // } else {
        //     setMenu('auction')
        // }
    }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: 'live',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const newRegistration = true

    useEffect(() => {
        if (isAuthenticated) {
            if (northCapitalUser) {
                if (!northCapitalUser.kyc_review) {
                    if (history.location.pathname === '/registration/uploadDocument/1') {
                        handleRedirectInternal(history, 'search')
                    }

                    if (!newRegistration) {
                        // we no longer need this.
                        if (
                            user.is_document_later === 0 &&
                            user.domesticYN === 'domestic_account'
                        ) {
                            handleRedirectInternal(history, 'registration/uploadDocument/1')
                        }
                    }

                    /* if (
                        user.is_document_later === 0 &&
                        user.user_type == 0 &&
                        user.domesticYN === 'domestic_account'
                    ) {
                    } else {
                        handleRedirectInternal(history, 'registration/uploadDocument/1')
                    } */
                    /*if (
                        northCapitalUser.kycstatus !== 'Approved' &&
                        northCapitalUser.kycstatus !== 'Auto Approved'
                    ) {
                        if (
                            user.kyc_request_count !== 2 &&
                            user.domesticYN === 'domestic_account'
                        ) {
                            handleRedirectInternal(history, 'registration/reupdate')
                        } else {
                            if (user.is_document_later === 0) {
                                handleRedirectInternal(history, 'registration/uploadDocument/1')
                            }
                        }
                    }*/
                }
            }
        }
    }, [isAuthenticated, northCapitalUser, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    const handleMenuToggle = (activeMenu) => {
        setAucOpen((prevOpen) => !prevOpen)
        setMenu(activeMenu)
    }

    const handleMenuClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setAucOpen(false)
    }

    const setValue = (value) => {
        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
        {
            window.location.pathname == '/search'
                ? (getAllSearchProducts(listHandle(value)),
                  setAucOpen(false),
                  setSearchValue(listHandle(value)))
                : null
        }
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setAucOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(aucopen)
    React.useEffect(() => {
        if (prevOpen.current === true && aucopen === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = aucopen
    }, [aucopen])

    const [manageKYC, setManageKYC] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenKYCPopup = (popup, status, data) => {
        setManageKYC({ popup, status, data })
    }

    return (
        <>
            <Loaders name="home" isLoading={isLoading} />
            <div className={`sideNavMenu ${props.collapsed && 'collapsedSdNav'}`}>
                <div className="logoWrapper">
                    <Link to="/">
                        <img src={LOGO} />
                    </Link>
                </div>
                {/* <Button
                    button
                    className={`text-center d-flex align-items-center collpaseIcon w-100 ${
                        props.collapsed && 'collpased'
                    } my-5`}
                    onClick={() => handleRedirectInternal(history, 'search?auctions=1')}
                    // onClick={props.navbarClick}
                    id="viewliveinvestments_button"
                >
                    <span className="mr-2">
                        <img src="/assets/svg/leftarrow.svg" />
                    </span>
                    View Live Investments
                </Button> */}
                <div className="menuLinks">
                    {isAuthenticated ? (
                        <>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/dashboard"
                                    id="sidebar_dashboard"
                                >
                                    <img src="/assets/svg/dashboard.svg" />
                                    {!props.collapsed && 'Dashboard'}
                                </NavLink>
                            </ListItem>
                            {totalPrivateSaleProducts.records > 0 && (
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/private_sales"
                                        id="sidebar_privatesale"
                                    >
                                        <img src="/assets/svg/myauctions.svg" />
                                        {!props.collapsed && 'Private Sales'}
                                    </NavLink>
                                </ListItem>
                            )}

                            {/* <ListItem button>
                                <NavLink activeClassName="active" to="/search?auctions=1">
                                    <img src="/assets/svg/myauctions.svg" />
                                    {!props.collapsed && 'All Investments'}
                                </NavLink>
                            </ListItem> */}
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    id="sidebar_myinvestments"
                                    isActive={() =>
                                        [
                                            '/auctions/all',
                                            '/auctions/won',
                                            '/auctions/winning',
                                            '/auctions/vip',
                                            '/auctions/auction',
                                            '/auctions/sold',
                                            '/auctions/lost',
                                            '/auctions/outbid',
                                        ].includes(pathname)
                                    }
                                    to="/auctions/sold"
                                >
                                    <img src="/assets/svg/mysongshares.svg" />
                                    {!props.collapsed && 'My Investments'}
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/openBids/auction"
                                    id="sidebar_openbids"
                                >
                                    <img src="/assets/svg/myauctions.svg" />
                                    {!props.collapsed && 'Open Bids/Offers'}
                                </NavLink>
                            </ListItem>
                            {/* <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    isActive={() =>
                                        [
                                            '/secondarytrading/alltradings',
                                            '/secondarytrading/myportfolio',
                                        ].includes(pathname)
                                    }
                                    to="/secondarytrading/alltradings"
                                    className="d-flex align-items-center"
                                >
                                    <img src="/assets/svg/secondaryTrading.svg" />
                                    {!props.collapsed && <p className="m-0">Secondary Trading</p>}
                                </NavLink>
                            </ListItem> */}
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/auctions/watchlist"
                                    id="sidebar_favorites"
                                >
                                    <img src="/assets/svg/favorites.svg" />
                                    {!props.collapsed && 'Favorites'}
                                </NavLink>
                            </ListItem>
                            {/* <ListItem button>
                                <NavLink
                                    id="sidebar_transactions"
                                    activeClassName="active"
                                    isActive={() =>
                                        [
                                            '/payment/transaction',
                                            '/payment/card',
                                            '/payment/bank',
                                        ].includes(pathname)
                                    }
                                    to="/payment/transaction"
                                >
                                    <img src="/assets/svg/payments.svg" />
                                    {!props.collapsed && 'Transactions'}
                                </NavLink>
                            </ListItem> */}
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/invoices"
                                    id="sidebar_invoices"
                                >
                                    <img src="/assets/svg/invoices.svg" />
                                    {!props.collapsed && 'Invoices'}
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/paymentinfo"
                                    id="sidebar_paymentinfo"
                                >
                                    <img src="/assets/svg/invoices.svg" />
                                    {!props.collapsed && 'Payment Details'}
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    isActive={() =>
                                        [
                                            '/account/profile',
                                            '/account/notifications',
                                            '/saved_search',
                                        ].includes(pathname)
                                    }
                                    to="/account/profile"
                                    id="sidebar_settings"
                                >
                                    <img src="/assets/svg/settings.svg" />
                                    {!props.collapsed && 'Settings'}
                                </NavLink>
                            </ListItem>
                            {mobile && (
                                <ListItem button>
                                    <NavLink
                                        activeClassName="inactive"
                                        to="/"
                                        onClick={() => onLogOut()}
                                        className="d-flex align-items-center"
                                    >
                                        <span className="material-icons mr-2">
                                            power_settings_new
                                        </span>
                                        Logout
                                    </NavLink>
                                </ListItem>
                            )}
                        </>
                    ) : (
                        <>
                            <ListItem button>
                                <NavLink activeClassName="active" className="mblLnk" to="/login">
                                    <span className="material-icons">login</span>
                                    Login
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink activeClassName="active" className="mblLnk" to="/register">
                                    <span className="material-icons">account_circle</span>
                                    Register
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <Link className="mblLnk" to="/">
                                    <span className="material-icons">gavel</span>
                                    Live Investments
                                </Link>
                            </ListItem>
                            <ListItem button>
                                <a
                                    className="mblLnk"
                                    href="https://www.songvest.com/aboutsongshares"
                                >
                                    <span className="material-icons">verified</span>
                                    Fan Investor Benefits
                                </a>
                            </ListItem>
                            <ListItem button>
                                <a className="mblLnk" href="https://www.songvest.com/sellroyalties">
                                    <span className="material-icons">sell</span>
                                    Selling Royalties
                                </a>
                            </ListItem>
                            <ListItem button>
                                <a className="mblLnk" href="https://www.songvest.com/about-us">
                                    <span className="material-icons">music_note</span>
                                    About Us
                                </a>
                            </ListItem>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
export default SideNavBar
