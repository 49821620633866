import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { Button, Drawer } from '@material-ui/core'
import './Home.css'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'

import CommonContext from '../../product/context/common/commonContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import AlertContext from '../../product/context/alert/alertContext'
import ProductContext from '../../product/context/product/productContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import { makeStyles } from '@material-ui/core/styles'
import openSocket from 'socket.io-client'
import Slider from 'react-slick'
import { handleRedirectInternal } from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import { Pagination } from '@material-ui/lab'
import AuctionCard from '../../custom/components/molecules/ProductCard/AuctionCard'
import ProductView from '../../custom/components/organisms/ProductView'
import { messageHandler } from '../../product/common/socketHandler'

import moment from 'moment'
import Loaders from '../../custom/components/molecules/Loaders'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

function Home() {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const auctionContext = useContext(AuctionContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const productContext = useContext(ProductContext)
    const { user, isAuthenticated, saveSearch, callEnd } = authContext
    const { search_allauctions, getAllAuctionProducts } = auctionContext
    const { setAlert } = alertContext

    const { search_allproducts, getAllSearchProducts, responseStatus } = productContext
    const { allCategory } = customCommonContext
    const { setSearchValue, searchValue, clearSearchValue } = productCommonContext

    let [viewProduct, setViewProduct] = useState([])
    let [auctions, setAuctions] = useState([])

    const [previousSlideIndex, setPreviousSlideIndex] = useState(0)
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
    const [nextSlideIndex, setNextSlideIndex] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [swiped, setSwiped] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [setSwiped])

    //PRODUCT SLIDER
    const classes = useStyles()

    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })

    const [searchAuction, setSearchAuction] = useState({
        limit: 12,
        page: 1,
        orderby: 1,
    })

    useEffect(() => {
        //Set date 2 days back from current date
        let date = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
        let lastTwoDaysDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
        let searchObjRecentProduct = {
            limit: 12,
            page: 1,
            orderby: 'p.date_added, desc',
            order: '',
            filters: {
                date_added: {
                    value: JSON.stringify(lastTwoDaysDate),
                    type: 'greaterequal',
                    field: 'p.date_added',
                },
            },
        }
        getAllSearchProducts(searchObjRecentProduct)
        getAllAuctionProducts(searchAuction)
        setIsLoading(false)
    }, [callEnd])

    useEffect(() => {
        setIsLoading(true)
    }, [])

    const onHandlePageAuction = (event, value) => {
        setSearchAuction({ ...searchAuction, page: value })
    }

    useEffect(() => {
        setViewProduct(search_allproducts.records.length ? search_allproducts.records : [])
        setIsLoading(false)
    }, [search_allproducts])

    useEffect(() => {
        setAuctions(search_allauctions.records.length ? search_allauctions.records : [])
    }, [search_allauctions])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    // SEARCH
    const getSearchBarObjet = (value) => {
        return {
            filters: {
                searchbar: {
                    value: value,
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter' && typeof searchText != 'undefined') {
            await setSearchValue(getSearchBarObjet(searchText))
            handleRedirectInternal(history, 'search')
            // history.push({
            //     pathname: '/search',
            //     state: searchText,
            // })
        }
    }

    const handleSearch = async () => {
        if (searchText != '') {
            await setSearchValue(getSearchBarObjet(searchText))
            handleRedirectInternal(history, 'search')
            // history.push({
            //     pathname: '/search',
            //     state: searchText,
            // })
        }
    }

    // CATEGORY CAROUSEL
    const NextArrow = (props) => {
        const { className, style, onClick } = props
        return <div className={className} onClick={onClick} />
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props
        return <div className={className} onClick={onClick} />
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        lazyLoad: true,
        swipeToSlide: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => {
            setPreviousSlideIndex(current)
            setCurrentSlideIndex(next)
        },
        afterChange: (current) => setNextSlideIndex(current),
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 7,
                },
            },
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    adaptiveHeight: false,
                },
            },
        ],
    }

    const handleCategoryClick = (catId) => {
        let searchObj = {
            filters: {
                category: {
                    value: [JSON.stringify(catId)],
                },
            },
        }

        setSearchValue(searchObj)
        handleRedirectInternal(history, 'search')
    }

    const handleOnItemClick = useCallback(
        (e) => {
            if (swiped) {
                e.stopPropagation()
                e.preventDefault()
                setSwiped(false)
            }
        },
        [swiped],
    )

    return (
        <div className="home">
            <section className="homeBanner homeMarginBottom">
                <div className="hbLt">
                    <div>
                        <h1>All free-zone auctions in one place</h1>
                        <h5>Description in home page</h5>
                        <div className="input-group mb-3 bannerSearch">
                            <input
                                onKeyDown={handleKeyDown}
                                type="text"
                                name="searchbar"
                                onChange={handleSearchTextChange}
                                className="form-control"
                                placeholder="Search for products here"
                                value={searchText}
                                aria-label="Search"
                                aria-describedby="searchIcon"
                            />
                            <PrimaryButton
                                label="Find product"
                                btnSize="large"
                                onClick={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div className="hbRt">
                    <img src="./assets/images/bannerCatg-1.png" alt="Category-1" />
                    <img src="./assets/images/bannerCatg-2.png" alt="Category-2" />
                    <img src="./assets/images/bannerCatg-3.png" alt="Category-3" />
                    <img src="./assets/images/bannerCatg-4.png" alt="Category-4" />
                </div>
            </section>
            <section className="homeCategorySlider customContainer homeMarginBottom">
                <h2 className="homeTitle">Shop by category</h2>
                <Slider onSwipe={handleSwiped} {...settings}>
                    {allCategory
                        .filter((main) => main.level === 0)
                        .map((data, index) => (
                            <div
                                className="categoryItem"
                                key={index}
                                onClick={() => handleCategoryClick(data.id)}
                                onClickCapture={handleOnItemClick}
                            >
                                <img
                                    src={global.site_url + '/uploads/category/' + data.avatar}
                                    alt={data.description}
                                />
                                <h6>{data.description}</h6>
                            </div>
                        ))}
                </Slider>
            </section>
            <section className="homeAbout homeMarginBottom customContainer d-flex justify-content-between align-items-center">
                <div className="homeAboutLt">
                    <h2>About Songvest</h2>
                    <p>lorum ipsum lorum ipsum lorum ipsum lorum ipsum</p>
                    <PrimaryButton
                        label="Start buying today"
                        onClick={() => handleRedirectInternal(history, 'search')}
                    />
                </div>
                <div className="homeAboutRt">
                    <object
                        type="image/svg+xml"
                        className={document.body.dir === 'rtl' && 'rtlImage'}
                        data="./assets/svg/aboutVector.svg"
                    />
                </div>
            </section>
            <section className="homeRecentlyListed homeMarginBottom customContainer">
                <h2 className="homeTitle">Recently listed</h2>
                {viewProduct.length ? (
                    <>
                        <div className="recentItems">
                            <Drawer
                                className="rightDrawer"
                                anchor={'right'}
                                open={state['right']}
                                onClose={toggleDrawer('right', false)}
                            >
                                <ProductView data={state.data} />
                            </Drawer>

                            {isLoading ? (
                                <Loaders name="product_grid_view" isLoading={isLoading} />
                            ) : (
                                viewProduct.map((data, index) => (
                                    <div key={index}>
                                        <GridView
                                            data={data}
                                            favId={`searchProd_${index}`}
                                            drawerHandler={toggleDrawer('right', true, data)}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </section>
            <section className="homeRecentlyListed homeMarginBottom customContainer">
                <h2 className="homeTitle">Live auctions</h2>
                {auctions.length ? (
                    <div className="homeSpecialItems">
                        {isLoading ? (
                            <Loaders name="product_list_view" isLoading={isLoading} />
                        ) : (
                            auctions.map((data, index) => <AuctionCard data={data} />)
                        )}

                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                            <h6>
                                Showing {search_allauctions.setDisp} of{' '}
                                {search_allauctions.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(
                                    search_allauctions.totalRecords / searchAuction.limit,
                                )}
                                page={searchAuction.page}
                                onChange={onHandlePageAuction}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                            />
                        </div>
                    </div>
                ) : (
                    <NoRecordsFound />
                )}
            </section>
        </div>
    )
}

export default Home
