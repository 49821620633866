import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import './index.css'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CommonContext from '../../../../product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import ProductContext from '../../../../product/context/product/productContext'
import Tooltip from '@material-ui/core/Tooltip'
import {
    currencyFormat,
    dateTimeFormatFunction,
    currencyFormatInt,
} from '../../../../custom/common/components'
import AuthContext from '../../../../product/context/auth/authContext'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const BidHistory = (props) => {
    const classes = useStyles()
    const [bidPopUp, setBidPopUp] = React.useState({
        popup: false,
        id: 0,
    })
    let [viewProduct, setViewProduct] = useState([])

    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext
    const { search_allbidhistory, getAllBidHistory } = productContext
    const authContext = useContext(AuthContext)
    const { user } = authContext

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        id: 0,
        filters: {
            proposal: {
                value: 'tie_bid',
                type: 'notin',
                field: 'b.proposal',
            },
        },
    })

    useEffect(() => {
        if (bidHistoryValue) {
            setSearch({ ...search, id: bidHistoryValue, page: 1 })
            setBidPopUp({ popup: true, id: bidHistoryValue })
            clearBidHistoryValue()
        } else if (props.id) {
            setSearch({ ...search, id: props.id, page: 1 })
        }
    }, [bidHistoryValue, props.id])

    useEffect(() => {
        if (search.id !== 0) {
            getAllBidHistory(search)
        }
    }, [search, props.refresh])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(search_allbidhistory.records.length ? search_allbidhistory.records : [])
    }, [search_allbidhistory])

    return props.modal === false ? (
        <div className="bidHistorySection cm-body table-responsive">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Current bid</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {viewProduct.map((data, index) => (
                        <>
                            <tr>
                                <td>
                                    {user && user.id === data.user_id
                                        ? user.first_name
                                        : data.user_id}
                                </td>
                                <td>
                                    {data.proposal === 'proxy_leads' ? (
                                        <>
                                            {user && user.id === data.user_id ? (
                                                <>{currencyFormatInt(data.proposed_amount)}</>
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    {currencyFormatInt(data.proposed_amount)}{' '}
                                                    {user && user.id === data.tiebid_user_id ? (
                                                        <>
                                                            <Tooltip
                                                                title="This amount was a tie bid. An earlier Proxy bid has won."
                                                                arrow={true}
                                                                placement="top"
                                                            >
                                                                <span className="cursorDecoy lh-sm">
                                                                    <span className="material-icons bidHistoryIcon">
                                                                        info
                                                                    </span>
                                                                </span>
                                                            </Tooltip>
                                                        </>
                                                    ) : null}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>{currencyFormatInt(data.proposed_amount)}</>
                                    )}
                                </td>
                                <td>{dateTimeFormatFunction(data.created_at)}</td>
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                <h6>
                    Showing {search_allbidhistory.setDisp} of {search_allbidhistory.totalRecords}
                </h6>
                <Pagination
                    count={Math.ceil(search_allbidhistory.totalRecords / search.limit)}
                    page={search.page}
                    onChange={onHandlePage}
                    siblingCount={3}
                    showFirstButton
                    showLastButton
                    boundaryCount={2}
                />
            </div>
        </div>
    ) : (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={bidPopUp.popup}
            className={`${classes.modal} customModal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={bidPopUp.popup}>
                <div className="bidHistoryModal">
                    <div className={classes.paper}>
                        <div className="modal fade show custom-modal">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Bid history</h4>
                                        <Button
                                            className="close"
                                            onClick={() => setBidPopUp({ popup: false, id: 0 })}
                                        >
                                            <span className="material-icons">close</span>
                                        </Button>
                                    </div>
                                    <div className="modal-body cm-body table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Current bid</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewProduct.map((data, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {user && user.id === data.user_id
                                                                    ? user.first_name
                                                                    : data.user_id}
                                                            </td>
                                                            <td>
                                                                {data.proposal === 'proxy_leads' ? (
                                                                    <>
                                                                        {user &&
                                                                        user.id === data.user_id ? (
                                                                            <>
                                                                                {currencyFormatInt(
                                                                                    data.proposed_amount,
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <div className="d-flex align-items-center">
                                                                                {currencyFormatInt(
                                                                                    data.proposed_amount,
                                                                                )}{' '}
                                                                                {user &&
                                                                                user.id ===
                                                                                    data.tiebid_user_id ? (
                                                                                    <>
                                                                                        <Tooltip
                                                                                            title="This amount was a tie bid. An earlier Proxy bid has won."
                                                                                            arrow={
                                                                                                true
                                                                                            }
                                                                                            placement="top"
                                                                                        >
                                                                                            <span className="cursorDecoy lh-sm">
                                                                                                <span className="material-icons bidHistoryIcon">
                                                                                                    info
                                                                                                </span>
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    </>
                                                                                ) : null}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {currencyFormatInt(
                                                                            data.proposed_amount,
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {dateTimeFormatFunction(
                                                                    data.created_at,
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                                            <h6>
                                                Showing {search_allbidhistory.setDisp} of{' '}
                                                {search_allbidhistory.totalRecords}
                                            </h6>
                                            <Pagination
                                                count={Math.ceil(
                                                    search_allbidhistory.totalRecords /
                                                        search.limit,
                                                )}
                                                page={search.page}
                                                onChange={onHandlePage}
                                                siblingCount={3}
                                                showFirstButton
                                                showLastButton
                                                boundaryCount={2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default BidHistory
