import React, { useState, useContext, useEffect } from 'react'

import AuthContext from '../../../../product/context/auth/authContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import UserContext from '../../../../product/context/user/userContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import ProductContext from '../../../../product/context/product/productContext'
import InvoiceContext from '../../../../product/context/invoice/invoiceContext'
import AuctionContext from '../../../../product/context/auction/auctionContext'
import CreditContext from '../../../../product/context/credit/creditContext'
import OfflineContext from '../../../../product/context/offline/offlineContext'
import CartContext from '../../../../product/context/cart/cartContext'
import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import PayContext from '../../../../product/context/payment/payContext'
import StripeCardContext from '../../../../product/context/stripe/card/cardContext'
import CustomerContext from '../../../../product/context/stripe/customer/customerContext'
import StripeBankContext from '../../../../product/context/stripe/bank/bankContext'

import BuynowContext from '../../../../product/context/buynow/buynowContext'
import AppointmentContext from '../../../../product/context/appointment/appointmentContext'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'

const HeadAlert = () => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const buynowContext = useContext(BuynowContext)
    const stripeBankContext = useContext(StripeBankContext)
    const stripeCardContext = useContext(StripeCardContext)
    const northCapitalContext = useContext(NorthCapitalContext)

    const { setAlert } = alertContext
    const { responseStatus: responseStatusAuth, clearResponse: clearResponseAuth } = authContext

    const {
        responseStatus: responseStatusStripeBank,
        clearResponse: clearResponseStripeBank,
    } = stripeBankContext

    const {
        responseStatus: responseStatusStripeCard,
        clearResponse: clearResponseStripeCard,
    } = stripeCardContext

    const {
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext

    const {
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const {
        responseStatus: responseStatusCredit,
        clearResponse: clearResponseCredit,
    } = creditContext

    const {
        responseStatus: responseStatusOffline,
        clearResponse: clearResponseOffline,
    } = offlineContext

    const {
        responseStatus: responseStatusAutoPlt,
        clearResponse: clearResponseAutoPlt,
    } = autopltContext

    const {
        responseStatus: responseStatusBuyNow,
        clearResponse: clearResponseBuyNow,
    } = buynowContext

    const {
        responseStatus: responseStatusNorthCapital,
        clearResponse: clearResponseNorthCapital,
    } = northCapitalContext

    useEffect(() => {
        if (responseStatusUser && !responseStatusUser.noAlert) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusProduct) {
            !responseStatusProduct.noAlert &&
                responseStatusProduct.message &&
                setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            clearResponseAutoPlt()
        }
        if (responseStatusAuth) {
            !responseStatusAuth.noAlert &&
                setAlert(responseStatusAuth.message, responseStatusAuth.status)
            clearResponseAuth()
        }
        if (responseStatusBuyNow) {
            setAlert(responseStatusBuyNow.message, responseStatusBuyNow.status)
            clearResponseBuyNow()
        }
        if (responseStatusPayment) {
            setAlert(responseStatusPayment.message, responseStatusPayment.status)
            clearResponsePayment()
        }
        if (responseStatusCart) {
            setAlert(responseStatusCart.message, responseStatusCart.status)
            clearResponseCart()
        }
        if (responseStatusCredit) {
            setAlert(responseStatusCredit.message, responseStatusCredit.status)
            clearResponseCredit()
        }
        if (responseStatusOffline) {
            setAlert(responseStatusOffline.message, responseStatusOffline.status)
            clearResponseOffline()
        }
        if (responseStatusStripeBank) {
            setAlert(responseStatusStripeBank.message, responseStatusStripeBank.status)
            clearResponseStripeBank()
        }
        if (responseStatusStripeCard) {
            setAlert(responseStatusStripeCard.message, responseStatusStripeCard.status)
            clearResponseStripeCard()
        }
        if (responseStatusNorthCapital) {
            !responseStatusNorthCapital.noAlert &&
                setAlert(responseStatusNorthCapital.message, responseStatusNorthCapital.status)
            clearResponseNorthCapital()
        }
    }, [
        responseStatusUser,
        responseStatusProduct,
        responseStatusAutoPlt,
        responseStatusAuth,
        responseStatusBuyNow,
        responseStatusPayment,
        responseStatusCart,
        responseStatusCredit,
        responseStatusOffline,
        responseStatusStripeBank,
        responseStatusStripeCard,
        responseStatusNorthCapital,
    ])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    return <></>
}
export default HeadAlert
