import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import AlertContext from '../../../../product/context/alert/alertContext'

function NorthCapitalCardDetails(props) {
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const {
        addCreditCard,
        getUserDetails,
        deleteCreditCard,
        updateCreditCard,
        responseStatus: responseStatusNorthCapital,
    } = useContext(NorthCapitalContext)
    const [disabled, setDisabled] = useState(false)
    const [reload, setReload] = useState(false)
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    const validationCard = Yup.object({
        creditCardNumber: Yup.string()
            .matches(/^[0-9]+$/, 'Invalid Number')
            /* .min(12, 'Invalid credit card number!')
            .max(18, 'Invalid credit card number!') */
            .min(16, 'Invalid credit card number!')
            .max(16, 'Invalid credit card number!')
            .required('Required!'),
        creditCardName: Yup.string()
            .trim()
            //.matches(/^[a-zA-Z ]*$/g, 'The special characters and numbers are not allowed!')
            .matches(/^[a-zA-Z0-9 ]*$/g, 'The special characters are not allowed!')
            .required('Required!'),
        card_exp_month: Yup.string().required('Required!'),
        card_exp_year: Yup.string().required('Required!'),
        cvvNumber: Yup.string()
            .required('Required!')
            .matches(/^[0-9]+$/, 'Invalid Number')
            .min(3, 'Mininum 3 Numbers required')
            //.max(4, 'Maximum 4 Numbers required'),
            .max(3, 'Maximum 3 Numbers allowed'),
        cardType: Yup.string().required('Required!'),
    })

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const cardFormik = useFormik({
        initialValues: {
            creditCardNumber: '',
            creditCardName: '',
            card_exp_month: '',
            card_exp_year: '',
            cvvNumber: '',
            cardType: '',
        },
        validationSchema: validationCard,
        onSubmit: (values) => {
            let dt1 = new Date()
            let dt2 = new Date()

            dt2.setFullYear(values.card_exp_year, values.card_exp_month, 1)
            if (dt2 < dt1) {
                setAlert('Please check your expiry date!', 'error')
                return false
            }

            values.expirationDate =
                values.card_exp_month + values.card_exp_year.toString().substr(-2)
            addCreditCard(values)
        },
    })

    const validationupdateCard = Yup.object({
        creditCardNumber: Yup.string()
            /* .min(12, 'Invalid credit card number!')
            .max(18, 'Invalid credit card number!') */
            .matches(/^[0-9]+$/, 'Invalid Number')
            .min(16, 'Invalid credit card number!')
            .max(16, 'Invalid credit card number!')
            .required('Required!'),
        creditCardName: Yup.string()
            .trim()
            //.matches(/^[a-zA-Z ]*$/g, 'The special characters and numbers are not allowed!')
            .matches(/^[a-zA-Z0-9 ]*$/g, 'The special characters are not allowed!')
            .required('Required!'),
        card_exp_month: Yup.string().required('Required!'),
        card_exp_year: Yup.string().required('Required!'),
        cvvNumber: Yup.string()
            .required('Required!')
            .min(3, 'Mininum 3 Numbers required')
            .max(3, 'Maximum 3 Numbers allowed'),
        cardType: Yup.string().required('Required!'),
    })

    const updateCardformik = useFormik({
        initialValues: {
            creditCardNumber: '',
            creditCardName: '',
            card_exp_month: '',
            card_exp_year: '',
            cvvNumber: '',
            cardType: '',
        },
        validationSchema: validationupdateCard,
        onSubmit: (values) => {
            let dt1 = new Date()
            let dt2 = new Date()

            dt2.setFullYear(values.card_exp_year, values.card_exp_month, 1)
            if (dt2 < dt1) {
                setAlert('Please check your expiry date!', 'error')
                return false
            }

            values.expirationDate =
                values.card_exp_month + values.card_exp_year.toString().substr(-2)
            updateCreditCard(values)
        },
    })

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                deleteCreditCard()
            } else if (props.data.status === 'update') {
                updateCardformik.resetForm()
                /* updateCardformik.values.creditCardName = props.data.data.creditCardName
                updateCardformik.values.creditCardNumber = props.data.data.creditCardNumber
                updateCardformik.values.card_exp_month = props.data.data.expirationDate.slice(0, 2)
                updateCardformik.values.card_exp_year =
                    currentYear.toString().slice(0, 2) +
                    '' +
                    props.data.data.expirationDate.slice(-2)
                updateCardformik.values.cvvNumber = props.data.data.cvvNumber
                updateCardformik.values.cardType = props.data.data.cardType */

                updateCardformik.setFieldValue('creditCardName', props.data.data.creditCardName)
                updateCardformik.setFieldValue('creditCardNumber', props.data.data.creditCardNumber)
                updateCardformik.setFieldValue(
                    'card_exp_month',
                    props.data.data.expirationDate.slice(0, 2),
                )
                updateCardformik.setFieldValue(
                    'card_exp_year',
                    currentYear.toString().slice(0, 2) +
                        '' +
                        props.data.data.expirationDate.slice(-2),
                )

                updateCardformik.setFieldValue('cvvNumber', props.data.data.cvvNumber)
                updateCardformik.setFieldValue('cardType', props.data.data.cardType)
                setReload(!reload)
            }
        }
    }, [props.data.data])

    const cardUpdate = {
        formik: updateCardformik,
        data: [
            {
                label: 'Card Holder Name',
                type: 'text',
                placeholder: 'Enter your card holder name',
                class: 'col-12',
                name: 'creditCardName',
                upperLabel: true,
            },
            {
                label: 'Card No',
                type: 'text',
                placeholder: 'Enter your card no',
                class: 'col-12',
                name: 'creditCardNumber',
                upperLabel: true,
            },
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
                upperLabel: true,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
                upperLabel: true,
            },
            {
                label: 'CVV',
                type: 'text',
                placeholder: 'Enter your CVV',
                class: 'col-12',
                name: 'cvvNumber',
                upperLabel: true,
            },
            {
                label: 'Card Type',
                placeholder: 'Select Card Type',
                class: 'col-6',
                type: 'select',
                name: 'cardType',
                options: [
                    {
                        value: 'VI',
                        show: 'Visa',
                    },
                    {
                        value: 'MC',
                        show: 'Mastercard',
                    },
                    {
                        value: 'DI',
                        show: 'Discover',
                    },
                    /* {
                        value: 'AMEX',
                        show: 'American Express',
                    }, */
                ],
                upperLabel: true,
            },
        ],
    }

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                label: 'Card Holder Name',
                type: 'text',
                placeholder: 'Enter your card holder name',
                class: 'col-12',
                name: 'creditCardName',
                upperLabel: true,
            },
            {
                label: 'Card No',
                type: 'text',
                placeholder: 'Enter your card no',
                class: 'col-12',
                name: 'creditCardNumber',
                upperLabel: true,
            },
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
                upperLabel: true,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
                upperLabel: true,
            },
            {
                label: 'CVV',
                type: 'text',
                placeholder: 'Enter your CVV',
                class: 'col-12',
                name: 'cvvNumber',
                upperLabel: true,
            },
            {
                label: 'Card Type',
                placeholder: 'Select Card Type',
                class: 'col-12',
                type: 'select',
                name: 'cardType',
                options: [
                    {
                        value: 'VI',
                        show: 'Visa',
                    },
                    {
                        value: 'MC',
                        show: 'Mastercard',
                    },
                    {
                        value: 'DI',
                        show: 'Discover',
                    },
                ],
                upperLabel: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusNorthCapital) {
            if (
                responseStatusNorthCapital.from === 'addCreditCard' ||
                responseStatusNorthCapital.from === 'deleteCreditCard' ||
                responseStatusNorthCapital.from === 'updateCreditCard'
            ) {
                if (responseStatusNorthCapital.status === 'success') {
                    props.function(false, 'new', null)
                    cardFormik.resetForm()
                }
            }
        }
    }, [responseStatusNorthCapital])

    return (
        <>
            <Popup
                open={props.data.popup && props.data.status === 'new'}
                size="sm"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Card Details"
            >
                <div className="cardDetails">
                    <form onSubmit={cardFormik.handleSubmit} autoComplete="nofill">
                        <div className="row">{mapData(cardDetails)}</div>
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    // disabled={disabled}
                                    btnSize="large"
                                    type="submit"
                                    label={'Save'}
                                    id="savecard_submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="sm"
                modaltitle="Update Card Details"
            >
                <form onSubmit={updateCardformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {mapData(cardUpdate)}
                        <div className="col-12">
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                id="updatecard_submit"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default NorthCapitalCardDetails
