import React, { useContext, useEffect, useState } from 'react'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { useFormik, Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import Loaders from '../../custom/components/molecules/Loaders'
import { Link } from 'react-router-dom'
import { apiCall } from '../../product/common/api'
import { mapData, calculateAge, handleRedirectInternal } from '../../product/common/components'
import { FormatLineSpacing } from '@material-ui/icons'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import KlaviyoContext from '../../custom/context/klaviyo/klaviyoContext'

function preStep() {
    const [isLoading, setIsLoading] = useState(false)
    const [type, setType] = useState('')
    const [popup, setPopup] = useState(false)
    const history = useHistory()
    const { addCampaignContact, responseStatus: responseStatusCampaign } = useContext(
        NorthCapitalContext,
    )
    const { subscribeProfileToListAfterRegister } = useContext(KlaviyoContext)
    const validationArray = [
        Yup.object({
            first_name: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            last_name: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            phone: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'The number only')
                .min(2, 'Invalid format')
                .max(20, 'Maximum 20 characters')
                .required('Required!'),
            market_opt: Yup.boolean(),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '+1',
            market_opt: 1,
            utm_source: localStorage.getItem('utm_source') || '',
            utm_medium: localStorage.getItem('utm_medium') || '',
            utm_campaign: localStorage.getItem('utm_campaign') || '',
            Abandons_Registration: true,
            Type: 'Pre Registration',
            Relationship: ['Pre Registration'],
        },
        validationSchema: validationArray[0],
        onSubmit: (values) => {
            values.Accept_Marketing = values.market_opt === 1 ? true : false
            values.Email_Opt_Out = values.market_opt ? false : true
            values.First_Name = values.first_name
            values.Last_Name = values.last_name
            values.Email = values.email
            values.Phone = values.phone
            values.UTM_Source = values.utm_source
            values.UTM_Medium = values.utm_medium
            values.UTM_Campaign = values.utm_campaign

            if (type === 'update') {
                try {
                    addCampaignContact(values, 'Subscriber', true, false)
                } catch (err) {
                    console.log('Zoho Contact Err =>', err)
                }
                // window.location.href = 'https://www.songvest.com/registered'
            } else {
                addCampaignContact(values, 'User', true, true)

                const klaviyoPostData = JSON.parse(JSON.stringify(values))
                console.log('klaviyoPostData', klaviyoPostData)
                // subscribeProfileToListAfterRegister(klaviyoPostData, true)

                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    pageFormSubmission: 'Lead',
                    userEmail: values.email,
                    userFirstName: values.first_name,
                    userLastName: values.last_name,
                })

                console.log(['Lead', window.dataLayer])

                history.push({ pathname: '/registration', state: values })
            }
        },
    })

    useEffect(() => {
        if (localStorage.getItem('utm_source')) {
            formik.setFieldValue('utm_source', localStorage.getItem('utm_source'))
        }
    }, [localStorage.getItem('utm_source')])

    useEffect(() => {
        if (localStorage.getItem('utm_medium')) {
            formik.setFieldValue('utm_medium', localStorage.getItem('utm_medium'))
        }
    }, [localStorage.getItem('utm_medium')])

    useEffect(() => {
        if (localStorage.getItem('utm_campaign')) {
            formik.setFieldValue('utm_campaign', localStorage.getItem('utm_campaign'))
        }
    }, [localStorage.getItem('utm_campaign')])

    useEffect(() => {
        if (responseStatusCampaign) {
            if (responseStatusCampaign.from === 'addCampaignContact') {
                window.location.href = 'https://www.songvest.com/registered'
            }
        }
    }, [responseStatusCampaign])

    const IndividualRegistration = [
        {
            label: 'First Name',
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: `col-12 col-sm-6`,
            autoFocus: true,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Last Name',
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your last name',
            class: `col-12 col-sm-6`,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Email Address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Mobile Number *Optional',
            name: 'phone',
            type: 'phone',
            placeholder: 'Mobile number',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
    ]

    const marketCheck = [
        {
            label: '',
            name: 'market_opt',
            type: 'checkbox',
            class: 'd-inline-block',
            formik: formik,
            upperLabel: true,
        },
    ]

    const cancelTemp = () => {
        setPopup(true)
    }

    return (
        <RegistrationLayout cancelTemp={cancelTemp}>
            <Loaders name="home" isLoading={isLoading} loop={1} className="defaultLoader" />
            <div className="loginWrapper p-5 h-100">
                <div className="rdrLnk text-right">
                    Already have an account?
                    <Link className="ml-2" id="signin_link" to="/login">
                        Login!
                    </Link>
                </div>
                <div className="rgLyrWrpr p-5 d-flex align-items-center justify-content-center">
                    <form className="registerForm mb-0" onSubmit={formik.handleSubmit}>
                        <h3 className="mb-4">Sign Up</h3>
                        <div className="row mt-4">{mapData(IndividualRegistration)}</div>
                        <div className="row">
                            {/* <div className="col-md-6 col-12 regActBox mt-2 preReg pr-3">
                                <div>Just sign me up to keep me updated, I might bid later</div>
                                <PrimaryButton
                                    label="Just Keep Me Updated"
                                    type="submit"
                                    onClick={() => {
                                        setType('update')
                                    }}
                                />
                            </div> */}

                            <div className="col-md-12 regActBox mt-2 preReg text-center">
                                {/* <div>I am ready to finish my registration so that I can bid</div> */}
                                <PrimaryButton
                                    id="registerpage_summit_button"
                                    label="Submit"
                                    type="submit"
                                    onClick={() => {
                                        setType('register')
                                    }}
                                />
                            </div>
                            <div className="col-md-12 mt-5">
                                <p className="markOptCheck">
                                    {mapData(marketCheck)}
                                    <small>
                                        {/* By signing up at SongVest, you agree to receive marketing
                                        emails from us. If you do not want to be contacted for new
                                        offers or sales, please check here to opt out.{' '} */}
                                        By clicking here, you agree to receive marketing emails from
                                        us.{' '}
                                    </small>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </RegistrationLayout>
    )
}

export default preStep
