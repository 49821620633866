import debounce from 'lodash.debounce'
import React, { useState, useContext, useEffect, useRef } from 'react'
import InvoiceCard from '../../custom/components/molecules/InvoiceCard'
import DashboardLayout from './DashboardLayout'
import { Button, ListItem } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import AuthContext from '../../product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import {
    dateTimeFormatFunction,
    capitalize,
    currencyFormat,
    handleRedirectInternal,
    mapData,
} from '../../product/common/components'

import { useTranslation } from 'react-i18next'
import Popup from '../../custom/components/organisms/Popup'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import BuyerContext from '../../product/context/buyer/buyerContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import commonContext from '../../custom/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'
import NorthCapitalCardDetails from '../../custom/components/molecules/NorthCapitalCardDetails'
import NorthCapitalStripeCardDetails from '../../custom/components/molecules/NorthCapitalCardDetails/stripeIndex'
import NorthCapitalPlaidDetails from '../../custom/components/molecules/NorthCapitalPlaidDetails'

import SSN from '../../custom/components/molecules/SSN'
import AddMoney from '../../custom/components/molecules/AddMoney'
import SendMoney from '../../custom/components/molecules/SendMoney'

import StripeCardContext from '../../product/context/stripe/card/cardContext'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import NorthCapitalBankDetails from '../../custom/components/molecules/NorthCapitalBankDetails'
import AppLayout from '../../custom/components/templates/AppLayout'

import PlaidBankDetails from '../../product/components/molecules/Plaid'
import publicIp from 'public-ip'
import AlertContext from '../../product/context/alert/alertContext'

function PaymentInfo(props) {
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const [display, setDis] = useState(false)
    const [isExternalAccount, setExternalAccount] = useState(0)
    const customcommonContext = useContext(commonContext)
    let [isWallet, setIsWallet] = useState(false)

    const [manageBank, setManageBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenBankPopup = (popup, status, data) => {
        setExternalAccount(0)
        setManageBank({ popup, status, data })
    }

    const [manageExternalBank, setManageExternalBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenExternalBankPopup = (popup, status, data) => {
        setExternalAccount(1)
        setManageExternalBank({ popup, status, data })
    }

    const {
        north_capital_bank_details,
        getExternalAccount,
        north_capital_card_details,
        getLinkedCreditCard,
        getCreditCard,
        addExternalBank,
        responseStatus: responseStatusNorthCapital,
    } = useContext(NorthCapitalContext)

    const {
        createCustomer,
        dwollaCustomerResponse: dwollaCustomerResponseTrans,
        responseStatus: responseStatusCommonTrans,
        deleteSource,
        getSource,
        getTransfers,
        dwollaSourceResponse,
        dwollaDeleteSourceResponse,
        transfersResponse,
    } = customcommonContext

    const authContext = useContext(AuthContext)
    // const userContext = useContext(UserContext)
    const invoiceContext = useContext(InvoiceContext)
    // const customcommonContext = useContext(commonContext)
    const { t } = useTranslation()
    const { search_allusertransactions, getAllUserTransactions } = invoiceContext
    const { isAuthenticated } = authContext

    let [viewProduct, setViewProduct] = useState([])

    const [trnsTab, setTransTab] = useState('card')
    const [addCard, setCard] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [cards, setCards] = useState([])
    const [banks, setBanks] = useState([])
    const [walletBanks, setWalletBanks] = useState([])
    const [isWalletAvailable, setWalletAvailable] = useState(false)
    const [isWalletBankAvailable, setWalletBankAvailable] = useState(false)

    const [pendingTransCount, setPendingTransCount] = useState(0)
    const [pendingTrans, setPendingTrans] = useState(0)
    const [pendingAmount, setTotalPendingAmount] = useState(0)
    const [reloadTransactions, setReloadTransactions] = useState(false)

    const { user, loadUser } = authContext
    // const [cards, setCards] = useState([])

    const { updateProfile } = userContext

    const history = useHistory()
    const buyerContext = useContext(BuyerContext)
    let {
        ttwBidConfirm,
        ttwBidTemp,
        responseStatus: responseStatusBid,
        clearResponse: clearResponseStatusBid,
    } = buyerContext

    // const {
    //     USStates,
    //     searchTableAction,
    //     searchTableActionupdate,
    //     responseStatus: responseStatusCommon,
    // } = customcommonContext

    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
    } = useContext(StripeCardContext)

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        orderby: 1,
    })

    // useEffect(() => {
    //     setTransTab(props.match.params.type)
    // }, [props.match.params.type])

    // useEffect(() => {
    //     setSearch({ ...search, action: props.match.params.action })
    // }, [props.match.params.action])
    const handleMessage = (event) => {
        if (event.data) {
            let parseData
            if (typeof event.data === 'string') {
                parseData = JSON.parse(event.data)
            }
            if (parseData) {
                if (parseData.errorCode)
                    setManageBank({ ...manageBank, popup: false, data: event.data })
            }
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [manageBank])

    useEffect(() => {
        if (isAuthenticated) {
            getAllUserTransactions(search)
        }
    }, [search, isAuthenticated])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(
            search_allusertransactions.records.length ? search_allusertransactions.records : [],
        )
    }, [search_allusertransactions])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const getNorthBanks = () => {
        getExternalAccount()
        toggleFullScreenBankPopup(false, 'new', null)
    }

    const getNorthCards = () => {
        getLinkedCreditCard()
        // getCreditCard()
        toggleFullScreenCardPopup(false, 'new', null)
    }

    useEffect(() => {
        if (isAuthenticated) {
            getNorthCards()
            getNorthBanks()
            getSource()
            setDis(true)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (dwollaCustomerResponseTrans && Object.keys(dwollaCustomerResponseTrans).length > 0) {
            loadUser()
            getSource()
        }
    }, [dwollaCustomerResponseTrans])

    useEffect(() => {
        if (dwollaDeleteSourceResponse) getSource()
    }, [dwollaDeleteSourceResponse])

    useEffect(() => {
        if (isWallet) {
            getSource()
            setIsWallet(true)
        }
    }, [isWallet])

    useEffect(() => {
        if (reloadTransactions) {
            getAllUserTransactions(search)
        }
    }, [reloadTransactions])

    const callGetSource = () => {
        getSource()
        setReloadTransactions(true)
    }

    const createDwollaCustomer = () => {
        createCustomer({
            userType: 0,
            user_id: user.id,
            isUpgrade: true,
        })
    }

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const [managePlaidBank, setManagePlaidBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenPlaidBankPopup = (popup, status, data) => {
        setManagePlaidBank({ popup, status, data })
    }

    const [manageSSNPopup, setManageSSNPopup] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenManageSSNPopup = (popup, status, data) => {
        setManageSSNPopup({ popup, status, data })
    }

    const [manageAddMoney, setManageAddMoney] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const [manageSendMoney, setManageSendMoney] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenManageAddMoneyPopup = (popup, status, data) => {
        setManageAddMoney({ popup, status, data })
    }

    const toggleFullScreenManageSendMoneyPopup = (popup, status, data) => {
        setManageSendMoney({ popup, status, data })
    }

    useEffect(() => {
        if (north_capital_bank_details) {
            if (north_capital_bank_details.data.statusDesc) {
                setBanks([north_capital_bank_details.data.statusDesc])
                redirectToCheckoutPage()
            } else {
                setBanks([])
            }
        }
    }, [north_capital_bank_details])

    useEffect(() => {
        if (dwollaSourceResponse) {
            // console.log(dwollaSourceResponse)
            if (dwollaSourceResponse.records && dwollaSourceResponse.records.length > 0) {
                setWalletBanks(dwollaSourceResponse.records)
                setWalletAvailable(
                    dwollaSourceResponse.records.findIndex((data) => data.type === 'balance') > -1,
                )
                setWalletBankAvailable(
                    dwollaSourceResponse.records.findIndex((data) => data.type !== 'balance') > -1,
                )
            } else {
                setWalletBanks([])
            }
            getTransfers({ status: 'pending' })
        }
    }, [dwollaSourceResponse])

    useEffect(() => {
        if (transfersResponse) {
            if (
                transfersResponse.status &&
                transfersResponse.body &&
                transfersResponse.body._embedded &&
                transfersResponse.body._embedded.transfers.length
            ) {
                let total = 0
                let totalPendingAmount = 0
                let pendingTransactionsCount = 0

                transfersResponse.body._embedded.transfers.forEach((e) => {
                    total += parseFloat(e.amount.value)
                    if (e.status == 'pending') {
                        totalPendingAmount += parseFloat(e.amount.value)

                        pendingTransactionsCount++
                    }
                })

                //console.log(['pendingTransactionsCount', pendingTransactionsCount])
                setPendingTrans(total)
                //setPendingTransCount(transfersResponse.body.total)
                setPendingTransCount(pendingTransactionsCount)
                setTotalPendingAmount(totalPendingAmount)
            }
        }
    }, [transfersResponse])

    useEffect(() => {
        if (north_capital_card_details) {
            if (north_capital_card_details.data.creditcardDetails) {
                setCards([north_capital_card_details.data.creditcardDetails])
            } else {
                setCards([])
            }
        }
    }, [north_capital_card_details])

    // useEffect(() => {
    //     if (responseStatusBid) {
    //         console.log(responseStatusBid, 'responseStatusBid')
    //         if (responseStatusBid.from === 'bidConfirm') {
    //             console.log(responseStatusBid, 'responseStatusBid')
    //             // if (responseStatusBid.status === 'success') {
    //             //     setAlert(responseStatusBid.message, responseStatusBid.status)
    //             //     handleRedirectInternal(
    //             //         history,
    //             //         `productview/${localStorage.itemId}/${localStorage.itemTitle}`,
    //             //     )
    //             //     localStorage.removeItem('ttw_temp_user_id')
    //             //     localStorage.removeItem('itemId')
    //             //     localStorage.removeItem('itemTitle')
    //             //     localStorage.removeItem('share')
    //             //     localStorage.removeItem('autopurchase')
    //             //     localStorage.removeItem('current_bid')
    //             // } else {
    //             //     setAlert(responseStatusBid.message, 'error')
    //             //     localStorage.removeItem('ttw_temp_user_id')
    //             //     localStorage.removeItem('itemId')
    //             //     localStorage.removeItem('itemTitle')
    //             //     localStorage.removeItem('share')
    //             //     localStorage.removeItem('autopurchase')
    //             //     localStorage.removeItem('current_bid')
    //             // }
    //         }
    //         clearResponseStatusBid()
    //     }
    // }, [responseStatusBid])

    useEffect(() => {
        if (responseStatusNorthCapital) {
            if (
                responseStatusNorthCapital.from === 'addCreditCard' ||
                responseStatusNorthCapital.from === 'deleteCreditCard' ||
                responseStatusNorthCapital.from === 'updateCreditCard'
            ) {
                if (responseStatusNorthCapital.status === 'success') {
                    getNorthCards()
                }
            }
            if (responseStatusNorthCapital.from === 'deleteExternalAccount') {
                if (responseStatusNorthCapital.status === 'success') {
                    updateProfile(
                        {
                            bank_account_type: 'none',
                        },
                        true,
                    )
                    getNorthBanks()
                }
            }
            if (
                responseStatusNorthCapital.from === 'addExternalBank' ||
                responseStatusNorthCapital.from === 'updateExternalBank'
            ) {
                if (responseStatusNorthCapital.status === 'success') {
                    updateProfile(
                        {
                            bank_account_type: 'external',
                        },
                        true,
                    )
                    getExternalAccount()
                    loadUser()
                }
            }
        }
    }, [responseStatusNorthCapital])

    const callApi = (a) => {
        getNorthBanks()
    }

    let ip = ''
    useEffect(async () => {
        ip = await publicIp.v4()
    }, [])

    const queryParam = useLocation().search
    let backTo = new URLSearchParams(queryParam).get('backTo')

    /* useEffect(() => {
        const delayedQuery = debounce((q) => callApi(q), 1000)
        window.addEventListener('message', function (event) {
            if (
                event.origin === 'https://api-sandboxdash.norcapsecurities.com' ||
                event.origin === 'https://api.norcapsecurities.com'
            ) {
                let i = 0
                toggleFullScreenBankPopup(false, 'new', null)
                delayedQuery(i + 1)
                updateProfile(
                    {
                        bank_account_type: 'link',
                    },
                    true,
                )

                // this functionality is added since the bank account was not added to north capital.
                // disabled it since now the bank account is directly added to the NC
                 if (event.data && event.data.accountId && event.data.account_number) {
                    
                    addExternalBank({
                        types: 'Account',
                        ExtAccountfullname: '',
                        Extnickname: '',
                        ExtBankname: event.data.institution_name ? event.data.institution_name : '',
                        ExtRoutingnumber: event.data.account_number
                            ? event.data.routing_number
                            : '',
                        ExtAccountnumber: event.data.account_number
                            ? event.data.account_number
                            : '',
                        updatedIpAddress: ip,
                    })
                } 
                //loadUser()
                // debounce((q) => callApi(q), 500)
                // debounce(callApi, 150)
                // debounce(function () {
                //     console.log('Debounced Event:')
                // }, 1000)
            }
        })
    }, []) */

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (
                event.origin === 'https://api-sandboxdash.norcapsecurities.com' ||
                event.origin === 'https://api.norcapsecurities.com'
            ) {
                let eventJSON = typeof event.data === 'string' ? JSON.parse(event.data) : ''
                if (eventJSON && (eventJSON.statusCode === 101 || eventJSON.statusCode === '101')) {
                    if (eventJSON.accountDetails === 'Card added successfully.') {
                        setTimeout(() => {
                            toggleFullScreenCardPopup(false, 'new', null)
                            console.log('coming this response')
                            getLinkedCreditCard()
                            if (localStorage.temp_user_id) {
                                handleRedirectInternal(history, 'buynowcart')
                                localStorage.removeItem('temp_user_id')
                            }
                            if (localStorage.ttw_temp_user_id) {
                                console.log(
                                    'coming this response',
                                    localStorage.itemId,
                                    localStorage.itemTitle,
                                )
                                if (localStorage.getItem('bidIncrease')) {
                                    handleRedirectInternal(
                                        history,
                                        `productview/${localStorage.itemId}/${localStorage.itemTitle}`,
                                    )
                                } else {
                                    localStorage.setItem(
                                        'paymentInfo',
                                        JSON.stringify({
                                            proposed_amount: localStorage.share,
                                            id: localStorage.itemId,
                                            bidding_type: 'hard',
                                            proposed_qty: '',
                                            customBidData: {
                                                autopurchase: localStorage.autopurchase,
                                            },
                                            autopurchase: localStorage.autopurchase,
                                            title: localStorage.itemTitle,
                                        }),
                                    )
                                    handleRedirectInternal(history, `dashboard`)
                                    // handleRedirectInternal(
                                    //     history,
                                    //     `productview/${localStorage.itemId}/${localStorage.itemTitle}`,
                                    // )
                                }
                                localStorage.removeItem('ttw_temp_user_id')
                                localStorage.removeItem('itemId')
                                localStorage.removeItem('itemTitle')
                                localStorage.removeItem('share')
                                localStorage.removeItem('autopurchase')
                                localStorage.removeItem('current_bid')
                            }
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            toggleFullScreenBankPopup(false, 'new', null)
                            updateProfile(
                                {
                                    bank_account_type: 'link',
                                },
                                true,
                            )

                            getExternalAccount()
                            if (localStorage?.temp_user_id) {
                                handleRedirectInternal(history, 'buynowcart')
                                localStorage.removeItem('temp_user_id')
                            }
                            if (localStorage.ttw_temp_user_id) {
                                console.log(
                                    'coming this response',
                                    localStorage.itemId,
                                    localStorage.itemTitle,
                                )
                                if (localStorage.getItem('bidIncrease')) {
                                    handleRedirectInternal(
                                        history,
                                        `productview/${localStorage.itemId}/${localStorage.itemTitle}`,
                                    )
                                } else {
                                    localStorage.setItem(
                                        'paymentInfo',
                                        JSON.stringify({
                                            proposed_amount: localStorage.share,
                                            id: localStorage.itemId,
                                            bidding_type: 'hard',
                                            proposed_qty: '',
                                            customBidData: {
                                                autopurchase: localStorage.autopurchase,
                                            },
                                            autopurchase: localStorage.autopurchase,
                                        }),
                                    )
                                    // handleRedirectInternal(
                                    //     history,
                                    //     `productview/${localStorage.itemId}/${localStorage.itemTitle}`,
                                    // )
                                    handleRedirectInternal(history, `dashboard`)
                                }
                                localStorage.removeItem('ttw_temp_user_id')
                                localStorage.removeItem('itemId')
                                localStorage.removeItem('itemTitle')
                                localStorage.removeItem('share')
                                localStorage.removeItem('autopurchase')
                                localStorage.removeItem('current_bid')
                            }
                            loadUser()
                        }, 2000)
                    }
                }
            }
        })
    }, [])

    const redirectToCheckoutPage = () => {
        if (history.location.search.includes('addBank=1')) {
            if (backTo != '' && backTo !== null && backTo !== undefined) {
                handleRedirectInternal(history, backTo)
            }
        }
    }

    return (
        <AppLayout>
            <div className="dashboardInner transctnTab payInfoTab">
                <div className="srchTopContainer d-flex align-items-end justify-content-center w-100"></div>
                <div className="dashViewWrapper pyGridWrpr">
                    <div className="otrPyWrpr">
                        <div className="inPyWrpr">
                            <div className="hdrPyWrpr">
                                <h4>Credit Card</h4>
                            </div>
                            <div className="savedCardsCnt">
                                <div className="savedCards mx-0">
                                    {cards && cards.length ? (
                                        cards.map((data, index) => (
                                            <div className="savedCardsCard" key={index}>
                                                <h4>
                                                    {data.cardType} ending in{' '}
                                                    {data.creditCardNumber}
                                                    {/* {(data.creditCardNumber + '').replace(
                                                        /\d(?=\d{4})/g,
                                                        '*',
                                                    )} */}
                                                </h4>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    {/* <p className="m-0">
                                                        EXP.{' '}
                                                        {data.expirationDate.slice(0, 2) +
                                                            '/' +
                                                            data.expirationDate.slice(-2)}
                                                    </p> */}
                                                    <div className="d-flex align-items-center">
                                                        {/* <Button
                                                            className="edtBtn"
                                                            onClick={() =>
                                                                toggleFullScreenCardPopup(
                                                                    true,
                                                                    'update',
                                                                    data,
                                                                )
                                                            }
                                                        >
                                                            Edit
                                                        </Button> */}
                                                        <Button
                                                            className="dltBtn removeCard"
                                                            onClick={() =>
                                                                toggleFullScreenCardPopup(
                                                                    false,
                                                                    'delete',
                                                                    data,
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                    {/* )} */}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <Link
                                            className="text-left"
                                            id="add_newcard"
                                            onClick={() =>
                                                toggleFullScreenCardPopup(true, 'new', null)
                                            }
                                        >
                                            {/* <span className="material-icons">add</span>Add new Credit Card */}
                                            <img src="/assets/images/addCard.png" />
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="otrPyWrpr">
                        <div className="inPyWrpr">
                            <div className="hdrPyWrpr">
                                <h4>ACH / Bank Details</h4>
                            </div>
                            <div className="savedBankCnt">
                                <div className="mx-0 text-left">
                                    {banks && banks.length ? (
                                        banks.map((data, index) => (
                                            <div className="savedBank" key={index}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="material-icons">
                                                        account_balance
                                                    </span>
                                                    <div className="d-flex align-items-center">
                                                        {user &&
                                                        user.bank_account_type === 'link' ? (
                                                            <Button
                                                                id="update_bank_link"
                                                                className="edtBtn"
                                                                onClick={() =>
                                                                    toggleFullScreenBankPopup(
                                                                        true,
                                                                        'update',
                                                                        data,
                                                                    )
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="edtBtn"
                                                                id="update_bank_details"
                                                                onClick={() =>
                                                                    toggleFullScreenExternalBankPopup(
                                                                        true,
                                                                        'update',
                                                                        data,
                                                                    )
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                        )}
                                                        <Button
                                                            className="dltBtn"
                                                            id="delete_bank_details"
                                                            onClick={() =>
                                                                toggleFullScreenBankPopup(
                                                                    false,
                                                                    'delete',
                                                                    data,
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </div>
                                                <h4>
                                                    <span>Account nickname</span>
                                                    {data.AccountNickName}
                                                </h4>
                                                <h5>
                                                    <span>Account number</span>
                                                    {(data.AccountNumber + '').replace(
                                                        /\d(?=\d{4})/g,
                                                        '*',
                                                    )}
                                                </h5>
                                            </div>
                                        ))
                                    ) : (
                                        <Link
                                            className="text-left"
                                            id="add_newcard"
                                            onClick={() =>
                                                toggleFullScreenBankPopup(true, 'new', null)
                                            }
                                        >
                                            {/* <span className="material-icons">add</span>Add new Credit Card */}
                                            <img src="/assets/images/addBank.png" />
                                        </Link>
                                    )}
                                    {/* <div className="col-4">
                                        <Link
                                            className="text-left"
                                            onClick={() =>
                                                toggleFullScreenBankPopup(true, 'new', null)
                                            }
                                        >
                                            <span className="material-icons">add</span>Add new
                                            Credit Card
                                            <img src="/assets/images/addBank.png" />
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="otrPyWrpr">
                        <div className="inPyWrpr"></div>
                    </div> */}
                </div>
            </div>
            <NorthCapitalPlaidDetails data={manageBank} function={toggleFullScreenBankPopup} />
            <NorthCapitalStripeCardDetails data={manageCard} function={toggleFullScreenCardPopup} />
            <NorthCapitalBankDetails
                data={manageExternalBank}
                function={toggleFullScreenExternalBankPopup}
            />
            <PlaidBankDetails
                data={managePlaidBank}
                function={toggleFullScreenPlaidBankPopup}
                isWallet={(d) => setIsWallet(d)}
                deleteSource={deleteSource}
            />
            <SSN
                data={manageSSNPopup}
                function={toggleFullScreenManageSSNPopup}
                handleResponse={() => createDwollaCustomer()}
            />
            <AddMoney
                data={manageAddMoney}
                function={toggleFullScreenManageAddMoneyPopup}
                handleResponse={() => callGetSource()}
                fromSource={
                    walletBanks &&
                    walletBanks.length > 0 &&
                    walletBanks.find((data) => data.type !== 'balance')
                }
                toSource={
                    walletBanks &&
                    walletBanks.length > 0 &&
                    walletBanks.find((data) => data.type === 'balance')
                }
            />

            <SendMoney
                data={manageSendMoney}
                function={toggleFullScreenManageSendMoneyPopup}
                handleResponse={() => callGetSource()}
                fromSource={
                    walletBanks &&
                    walletBanks.length > 0 &&
                    walletBanks.find((data) => data.type === 'balance')
                }
                toSource={
                    walletBanks &&
                    walletBanks.length > 0 &&
                    walletBanks.find((data) => data.type !== 'balance')
                }
            />
        </AppLayout>
    )
}

export default PaymentInfo
