import React, { useContext, useEffect, useState } from 'react'
import { mapData } from '../../../../product/common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import UserContext from '../../../../product/context/user/userContext'
import Cryptos from '../../../../product/common/cryptos'

function UpdateSSN(props) {
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const { updateProfile, responseStatus: userResponseStatusSsn } = useContext(UserContext)

    const validationArray = [
        Yup.object({
            socialsecurity: Yup.string()
                .required('Required!')
                .nullable(true)
                .test(
                    'len',
                    '9 characters required',
                    (value) => value && value.replace(/_/g, '').replace(/-/g, '').length === 9,
                ),
        }),

        /*Yup.object({
            socialsecurity: Yup.string()
                .nullable(true)
                .when('checkval', {
                    is: '0',
                    then: Yup.string(),
                    otherwise: Yup.string()
                        .required('Required!')
                        .nullable(true)
                        .test(
                            'len',
                            '9 characters required',
                            (value) =>
                                value && value.replace(/_/g, '').replace(/-/g, '').length === 9,
                        ),
                }),
        }),*/
    ]
    const ssnFormik = useFormik({
        initialValues: {
            socialsecurity: '',
            //checkval: '1',
        },
        validationSchema: validationArray[0],
        onSubmit: (values) => {
            console.log('validation success')
            let value = values
            if (value.socialsecurity) {
                value.socialsecurity = Cryptos.encrypt(value.socialsecurity)
            }
            updateProfile(value, 0, 'updateProfileSSN')
        },
    })

    useEffect(() => {
        if (
            userResponseStatusSsn &&
            userResponseStatusSsn.status === 'success' &&
            userResponseStatusSsn.from === 'updateProfileSSN'
        ) {
            props.function(false, 'update', null)
            props.handleResponse(false, 'update', null)
        }
    }, [userResponseStatusSsn])

    const ssnField = [
        {
            label: 'Social Security Number',
            name: 'socialsecurity',
            placeholder: 'Enter SSN',
            class: `col-12`,
            formik: ssnFormik,
            type: 'hiddenMask',
            //type: 'mask',
            maskValue: '***-**-****',
            upperLabel: true,
            startAdornment: <span className="material-icons mr-3">lock</span>,
            modalPP: true,
        },
    ]

    /*useEffect(() => {
        ssnFormik.values.socialsecurity = user.socialsecurity ? user.socialsecurity : ''
    }, [user])*/

    return (
        <>
            {props.data.popup && (
                <Popup
                    open={props.data.popup && props.data.status === 'new'}
                    cstmModalClass="kycAmlModal"
                    size="sm"
                    handleClose={() => props.function(false, 'update', null)}
                    modaltitle="Kyc/Aml process"
                >
                    <>
                        <form onSubmit={ssnFormik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(ssnField))}</div>
                            <div className="bdyContent">
                                <p>Why do we need this?</p>
                                <p className="descTxt">
                                    We are required by federal law (USA PATRIOT Act) to collect your
                                    Social Security Number. This is only used to verify your
                                    identity and to protect against fraud and money laundering
                                </p>
                                <div className="lckDesc d-flex align-items-center mt-4">
                                    <span className="material-icons">lock</span>
                                    <div className="rtCntnr ml-3">
                                        <p className="crdtTtl">Your Credit Score is safe</p>
                                        <p className="cdtlDesc">
                                            We only use SSN to verify your identity. This has no
                                            impact on your credit score.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <PrimaryButton
                                        // disabled={disabled}
                                        btnSize="large"
                                        type="submit"
                                        label={'Save'}
                                    />
                                </div>
                            </div>
                        </form>
                    </>
                </Popup>
            )}
        </>
    )
}

export default UpdateSSN
