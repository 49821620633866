import React, { useContext, useState, useEffect } from 'react'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Popup from '../../organisms/Popup'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../../product/common/components'

const KYCUpdate = (props) => {
    const folderName = 'northcapital'
    const authContext = useContext(AuthContext)
    const {
        uploadPartyDocument,
        getUserDetails,
        responseStatus: responseStatusNorthCapital,
    } = useContext(NorthCapitalContext)

    const { user } = authContext

    const validationCard = Yup.object({
        documentTitle: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'The special characters and numbers are not allowed!')
            .required('Required!'),
        userfile: Yup.array().required('Required!'),
    })

    const cardFormik = useFormik({
        initialValues: {
            documentTitle: '',
            userfile: [],
            attachFolder: folderName,
        },
        validationSchema: validationCard,
        onSubmit: (values) => {
            uploadPartyDocument(values)
        },
    })

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                type: 'uploadDropZone',
                name: 'userfile',
                titleText: 'Upload valid U.S. photo ID or Passport',
                innerText: '',
                class: 'col-12',
                folder: folderName,
                multiple: false,
                accept: 'image/*, application/*',
            },
        ],
    }

    useEffect(() => {
        if (user) {
            cardFormik.setFieldValue(
                'documentTitle',
                user.first_name
                    ? user.first_name
                    : '' + ' ' + user.last_name
                    ? user.last_name
                    : '' + ' Documents',
            )
        }
    }, [user])

    useEffect(() => {
        if (responseStatusNorthCapital) {
            if (responseStatusNorthCapital.from === 'uploadPartyDocument') {
                if (responseStatusNorthCapital.status === 'success') {
                    getUserDetails()
                    props.function(false, 'new', null)
                }
            }
        }
    }, [responseStatusNorthCapital])

    return (
        <Popup
            open={props.data.popup && props.data.status === 'new'}
            size="sm"
            handleClose={() => props.function(false, 'new', null)}
            modaltitle="Add Document"
        >
            <div className="cardDetails">
                <form onSubmit={cardFormik.handleSubmit} autoComplete="nofill">
                    <div className="row">{mapData(cardDetails)}</div>
                    <div className="row">
                        <div className="col-12">
                            <PrimaryButton
                                // disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Popup>
    )
}

export default KYCUpdate
