import React, { useState, useEffect, useContext } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import PlaidContext from '../../product/context/payment/plaid/plaidContext'
import AuthContext from '../../product/context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const index = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [plaidToken, setPlaidToken] = useState('')
    const plaidContext = useContext(PlaidContext)
    const authContext = useContext(AuthContext)

    const { user, isAuthenticated, loadUser } = authContext
    const {
        plaidLinkTokenCreate,
        dwalloConnect,
        responseStatus: responseStatusPlaid,
        clearResponse: clearResponsePlaid,
    } = plaidContext

    // const validationPlaid = Yup.object({
    //     customer_id: Yup.string().required,
    //     name: Yup.string().required,
    //     token: Yup.string().required,
    // })

    // const pliadFormik = useFormik({
    //     initialValues: {
    //         customer_id: '',
    //         name: '',
    //         token: '',
    //     },
    //     validationSchema: validationPlaid,
    //     onSubmit: (values) => {
    //         setIsLoading(true)
    //         dwalloConnect(values)
    //     },
    // })

    useEffect(() => {
        plaidLinkTokenCreate({})
    }, [])

    useEffect(() => {
        if (responseStatusPlaid && responseStatusPlaid.from === 'plaidLinkTokenCreate') {
            if (responseStatusPlaid.token) {
                setPlaidToken(responseStatusPlaid.token)
            }
        }
    }, [responseStatusPlaid])

    useEffect(() => {
        if (plaidToken) {
            dwalloConnect({
                customer_id: user.stripe_id,
                token: plaidToken,
                name: '',
            })
        }
    }, [plaidToken])

    const { open, ready } = usePlaidLink({ token: '', onSuccess: (public_token, metadata) => {} })

    return (
        <button onClick={() => open()} disabled={false}>
            {' '}
            Connect a bank account{' '}
        </button>
    )
}

export default index
