import React, { useEffect, useState, useContext } from 'react'
import './AppLayout.css'
import Header from '../../molecules/Header'
import SideNavBar from '../../molecules/SideNavBar'
import AuthContext from '../../../../product/context/auth/authContext'
import { Link } from 'react-router-dom'
import { LOGO } from '../../../../Utils'
import { useMediaQuery } from '@material-ui/core'
import CustomCommonContext from '../../../../custom/context/common/commonContext'

const AppLayout = (props) => {
    const [back_url, setBackUrl] = useState('/assets/images/bannerFallback.png')
    const [hdrIcon, setHeaderIcon] = useState('')
    const [pageTitle, setPageTitle] = useState('')
    const [collapsedNav, setCollapsedNav] = useState(false)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const mobile = useMediaQuery('(max-width:1200px)')
    const { user, isAuthenticated, loadPendingCount, isAdmin, cartCount, logout } = authContext
    const { allConfigurationVariables } = customCommonContext

    useEffect(() => {
        let condition = window.location.pathname.includes('/watchlist')
            ? '/favorites'
            : window.location.pathname.includes('/auctions')
            ? '/auctions'
            : window.location.pathname.includes('/payment/transaction')
            ? '/transaction'
            : window.location.pathname.includes('/payment')
            ? '/payment'
            : window.location.pathname.includes('/account')
            ? '/account'
            : window.location.pathname.includes('/secondarytrading')
            ? '/secondarytradings'
            : window.location.pathname.includes('/openBids')
            ? '/openBids'
            : window.location.pathname.includes('/productview')
            ? '/productview'
            : window.location.pathname.includes('/myArtists')
            ? '/myArtists'
            : window.location.pathname

        switch (condition) {
            case '/productview':
                return setBackUrl('/assets/images/bannerFallback.png')
            case '/search':
                return (
                    setBackUrl('/assets/images/searchback.png'), setHeaderIcon(''), setPageTitle('')
                    // setBackUrl('/assets/images/searchback.png'),
                    // setHeaderIcon('/assets/svg/myauctions.svg'),
                    // setPageTitle('Auction List')
                )
            case '/auctions':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/mysongshares.svg'),
                    setPageTitle('My Investments')
                )
            case '/favorites':
                return (
                    setBackUrl('/assets/images/favback.png'),
                    setHeaderIcon('/assets/svg/favorites.svg'),
                    setPageTitle('Favorites')
                )
            case '/payment':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/invoices.svg'),
                    setPageTitle('Payment Details')
                )
            case '/transaction':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/invoices.svg'),
                    setPageTitle('Transactions')
                )
            case '/invoices':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/invoices.svg'),
                    setPageTitle('Invoice')
                )
            case '/paymentinfo':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/invoices.svg'),
                    setPageTitle('Payment Details')
                )
            case '/account':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/settings.svg'),
                    setPageTitle('Settings')
                )
            case '/saved_search':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/settings.svg'),
                    setPageTitle('Settings')
                )
            case '/dashboard':
                return (
                    setBackUrl('/assets/images/dashboardback.png'),
                    setHeaderIcon('/assets/svg/dashboard.svg'),
                    setPageTitle('Dashboard')
                )
            case '/buynowcart':
                return (
                    setBackUrl('/assets/images/dashboardback.png'),
                    setHeaderIcon('/assets/svg/shopping-cart.svg'),
                    setPageTitle('Cart')
                )
            case '/secondarytradings':
                return (
                    setBackUrl('/assets/images/dashboardback.png'),
                    setHeaderIcon('/assets/svg/secondaryTrading.svg'),
                    setPageTitle('Secondary Trading')
                )
            case '/openBids':
                return (
                    setBackUrl('/assets/images/songsharesback.png'),
                    setHeaderIcon('/assets/svg/mysongshares.svg'),
                    setPageTitle('Open Bids/Offers')
                )
            case '/myArtists':
                return setBackUrl('/assets/images/header-myartists.png')
            // case '/checkout/buynow':
            //     return setBackUrl('/assets/images/searchback.png')
            default:
                // return setBackUrl('/assets/images/productback.png')
                return setBackUrl('/assets/images/searchback.png')
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (
            window.location.pathname.includes('/productview') ||
            window.location.pathname.includes('/checkout/buynow')
        ) {
            if (props.isLoading) {
                setBackUrl(`/assets/images/bannerFallback.png`)
            } else {
                if (props?.productBanner) {
                    setBackUrl(
                        `${process.env.REACT_APP_DOMAIN}/uploads/config/${props?.productBanner}`,
                    )
                } else if (
                    (props?.productType === 'buynow' || props?.productType === 'ttw') &&
                    props?.bannerimg
                ) {
                    setBackUrl(`${process.env.REACT_APP_DOMAIN}/uploads/config/${props.bannerimg}`)
                } else {
                    setBackUrl(`/assets/images/productback.png`)
                }
            }
        }
    }, [
        window.location.pathname,
        allConfigurationVariables?.auction_banner,
        props?.productBanner,
        props?.productType,
    ])

    console.log(props?.productBanner, 'checkHeader')

    return (
        <div className="appLayoutWrapper d-flex align-items-start justify-content-between w-100">
            {isAuthenticated && !mobile && (
                <div className="sideNav">
                    <SideNavBar
                        navbarClick={() => setCollapsedNav(!collapsedNav)}
                        collapsed={collapsedNav}
                    />
                </div>
            )}
            <div
                className={`rtWrapper ${collapsedNav && 'navCollapsed'} ${
                    !isAuthenticated || mobile ? 'w-100' : ''
                }`}
            >
                <img
                    className={`backGrndImage ${
                        window.location.pathname.includes('purchaseshare') ? 'd-none' : ''
                    } ${window.location.pathname.includes('/productview') ? 'largeImage' : ''} ${
                        !isAuthenticated || mobile ? 'w-100' : ''
                    } ${window.location.pathname.split('/')[1]} ${
                        window.location.pathname.split('/')[2]
                    } ${props.header === false ? 'noHeader' : ''} ${
                        window.location.pathname.includes('/myArtists') ? 'singleArtistBg' : ''
                    }${window.location.pathname.includes('/soldItem') ? 'displaySold' : ''}`}
                    src={back_url}
                />
                <div className={`topContainer w-100 ${props.header === false ? 'd-none' : 's'}`}>
                    <Header />
                </div>
                <div
                    className={`vwPortContainer customContainer ${
                        !isAuthenticated ? 'unAuthCustomContainer' : ''
                    }`}
                >
                    {hdrIcon && pageTitle && (
                        <div className="pgHdrContent">
                            <img src={hdrIcon} />
                            <h4>{pageTitle}</h4>
                        </div>
                    )}
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default AppLayout
